import React from "react";
import { Col, Row, Table } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const StudentLoading = () => {
  const n = 5;
  return (
    <Row>
      <Col sm={12} className="mb-8">
        <Table responsive className="schooltable">
          <thead className="text-tangaroa">
            <tr>
              <th className="text-base font-800">
                <Skeleton width={50} />
              </th>
              <th className="text-base font-800 text-capitalize">
                <Skeleton width={50} />
              </th>
              <th className="text-base font-800 text-capitalize">
                <Skeleton width={50} />
              </th>
              <th className="text-base font-800 text-capitalize">
                <Skeleton width={50} />
              </th>
              <th className="text-base font-800 text-capitalize">
                <Skeleton width={50} />
              </th>
              <th className="text-base font-800 text-capitalize"></th>
            </tr>
          </thead>
          {[...Array(n)].map((val, i) => {
            return (
              <tbody className="text-tangaroa" key={i}>
                <tr>
                  <td className="text-base font-800">
                    <Skeleton width={40} />
                  </td>
                  <td className="font-regular text-base">
                    <Skeleton width={40} />
                  </td>
                  <td className="font-regular text-base">
                    <Skeleton width={100} />
                  </td>
                  <td className="font-regular text-base">
                    <Skeleton width={100} />
                  </td>
                  <td className="text-end pe-0">
                    <a className="btn btn-transparent" href={() => false}>
                      <Skeleton width={100} height={30} />
                    </a>
                  </td>
                </tr>
              </tbody>
            );
          })}
        </Table>
      </Col>
    </Row>
  );
};

export default StudentLoading;
