import React from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";

class Subheader2 extends React.Component {
  render() {
    return (
      <div>
        <section className="bg-blue py-10">
          <Container>
            <Row>
              <Col xl={9}>
                <h1 className="text-light">Students</h1>
              </Col>
              <Col xl={3} className="d-flex">
                <Form.Control
                  type="search"
                  placeholder="Search"
                  className="me-2"
                  aria-label="Search"
                />
                <Button variant="outline-light">Search</Button>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    );
  }
}
export default Subheader2;
