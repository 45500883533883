import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { Consts } from "../utils/Constant";
import {
  addStudentClass,
  addStudentService,
  studentAlreadyExist,
} from "../firebase/service";
import { useSelector } from "react-redux";
import { addStudents, levelOptions } from "../utils";
import { OptionsOutsideSelect, Toasts } from "../components";
import { ToastContainer } from "react-toastify";

function AddStudent({
  hide,
  show,
  addRecord,
  classdata,
  allStudent,
  schoolRecord,
  schoolDetails,
  screenType,
}) {
  const { currentUserData } = useSelector((state) => state.AuthReducer);
  const [form, setForm] = useState({
    level: "ADA",
    school_name: schoolDetails?.name,
  });
  const [classes, setClass] = useState([]);
  const [errors, setErrors] = useState({});
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [schoolWiseClass, setschoolWiseClass] = useState([]);

  useEffect(() => {
    if (schoolDetails) {
      let allClass = classdata.filter(
        (val) => val?.school_id === schoolDetails?.uid
      );
      setschoolWiseClass(allClass);
    }
  }, []);
  let allStud = false;
  const setFeild = (field, value) => {
    if (field === "school_name") {
      let schoolId = schoolRecord.filter((item) => item?.name === value);
      let allClass = classdata.filter(
        (val) => val?.school_id === schoolId[0]?.uid
      );
      setschoolWiseClass(allClass);
    }
    setForm({
      ...form,
      [field]: value,
    });
    if (!!errors[field])
      setErrors({
        ...errors,
        [field]: null,
      });
  };
  const validateForm = () => {
    const { fname, lname, individualCode, level, school_name } = form;
    const newErrors = {};
    if (!fname || fname === "")
      newErrors.fname = "Please enter student's first name";
    if (!lname || lname === "")
      newErrors.lname = "Please enter student's last name";
    if (!individualCode || individualCode === "")
      newErrors.individualCode = "Please enter student's individual code";
    else if (!allStud) newErrors.individualCode = "student already used!";
    if (!classes || classes === "")
      newErrors.classes = " please Select Your Class";
    if (!level || level === "")
      newErrors.level = "Please enter student's level";
    if (!school_name || school_name === "")
      newErrors.school_name = "Please select the school";
    return newErrors;
  };
  const handleClassChange = (selected) => {
    setClass(selected);
  };

  const addStudent = async () => {
    setLoadingBtn(true);
    const { fname, lname, individualCode, level, school_name } = form;
    if (individualCode && school_name) {
      let uniqeId = schoolRecord?.filter((s) => s?.name === school_name);
      allStud = await studentAlreadyExist(individualCode, uniqeId[0]?.code);
    }
    if (
      fname &&
      lname &&
      individualCode &&
      level &&
      school_name &&
      classes?.length > 0
    ) {
      if (allStud) {
        let classRecord = [];

        for (let i = 0; i < classes.length; i++) {
          let obj = {
            classId: classes[i].value,
            // class_name: classes[i].label,
          };
          classRecord.push(obj);
        }
        let uniqeId = schoolRecord?.filter((s) => s?.name === school_name);
        let studentRecord = {
          email: "",
          name: fname,
          last_name: lname,
          level: level,
          role: Consts.Role,
          individual_code: individualCode,
          admin_id: currentUserData?.uid,
          class: classRecord,
          school_name: school_name,
          school_id: uniqeId[0]?.uid,
          school_code: uniqeId[0]?.code,
        };
        let resId = await addStudentService(studentRecord);

        for (let i = 0; i < classes.length; i++) {
          let obj = {
            classId: classes[i].value,
            student_id: resId,
            school_id: uniqeId[0]?.uid,
            showInDashboard: true,
            admin_id: currentUserData?.uid,
          };
          await addStudentClass(obj);
        }
        Toasts("New student added successfully", "success");

        addRecord();
      } else {
        const formErrors = validateForm();
        setErrors(formErrors);
        validateForm();
        setLoadingBtn(false);
      }
    } else {
      const formErrors = validateForm();
      setErrors(formErrors);
      validateForm();
      setLoadingBtn(false);
    }
  };
  return (
    <Modal
      size="lg"
      setModalShow="true"
      show={show}
      onHide={hide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modalschool modalstudent"
    >
      <Modal.Header closeButton className="border-none pb-0">
        <Modal.Title id="contained-modal-title-vcenter" className="text-xl">
          Add Student
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="py-7">
        <Row>
          {addStudents.map((val, index) => {
            return (
              <>
                <Col lg={val?.col} key={index}>
                  {val?.label === "Class" ? (
                    <Form.Group controlId="classes" className="mt-3">
                      <Form.Label className="text-tangaroa font-bold text-base ">
                        Class
                      </Form.Label>
                      <OptionsOutsideSelect
                        onChange={handleClassChange}
                        isMulti
                        // options={classdata}
                        options={schoolWiseClass}
                        value={classes}
                      />
                    </Form.Group>
                  ) : val?.label === "Level" ? (
                    <Form.Group controlId="type" className="mt-3">
                      <Form.Label className="text-tangaroa font-bold text-base">
                        {val?.label}
                      </Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        required="1"
                        className="control mb-2"
                        value={form.val?.name}
                        onChange={(e) => setFeild(val?.name, e.target.value)}
                        isInvalid={!!errors[val?.name]}
                      >
                        {/* <option key="blankChoice" hidden value>
                          - Select level -
                        </option> */}
                        {levelOptions.map((val) => {
                          return <option value={val?.name}>{val?.name}</option>;
                        })}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        {errors[val?.name]}
                      </Form.Control.Feedback>
                    </Form.Group>
                  ) : val?.label === "School" ? (
                    <>
                      {schoolDetails ? (
                        ""
                      ) : (
                        <Form.Group controlId="type" className="mt-3">
                          <Form.Label className="text-tangaroa font-bold text-base">
                            {val?.label}
                          </Form.Label>
                          <Form.Select
                            aria-label="Default select example "
                            required="1"
                            className="control mb-2"
                            value={
                              schoolDetails
                                ? schoolDetails?.name
                                : form.val?.name
                            }
                            onChange={(e) =>
                              setFeild(val?.name, e.target.value)
                            }
                            isInvalid={!!errors[val?.name]}
                          >
                            {/* <option selected value="Secondary 1">
                          Secondary 1
                        </option> */}
                            <option key="blankChoice" hidden value>
                              - Select school -
                            </option>
                            {schoolRecord.map((val) => {
                              return (
                                <option value={val?.name}>{val?.name}</option>
                              );
                            })}
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">
                            {errors[val?.name]}
                          </Form.Control.Feedback>
                        </Form.Group>
                      )}
                    </>
                  ) : (
                    <Form.Group
                      controlId={val?.name}
                      className="mt-3"
                      key={index}
                    >
                      <Form.Label className="text-tangaroa font-bold text-base">
                        {val?.label}
                      </Form.Label>
                      <Form.Control
                        type={val?.type}
                        placeholder={val?.placeholder}
                        className="control"
                        value={form.val?.name}
                        onChange={(e) => setFeild(val?.name, e.target.value)}
                        isInvalid={!!errors[val?.name]}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors[val?.name]}
                      </Form.Control.Feedback>
                    </Form.Group>
                  )}
                </Col>
              </>
            );
          })}
        </Row>
      </Modal.Body>
      <Modal.Footer className="border-none pt-0 justify-content-start pb-8">
        <Button
          variant={loadingBtn ? "disabled" : "jade"}
          className="me-2 my-0 "
          onClick={addStudent}
          disabled={loadingBtn ? true : false}
        >
          Save
        </Button>
        <Button variant="outline-jade" className="my-0 ms-0" onClick={hide}>
          Cancel
        </Button>
      </Modal.Footer>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </Modal>
  );
}

export default AddStudent;
