import { React, useState, useEffect } from "react";
import { Button, Form, Modal, Container, Row, Col } from "react-bootstrap";
import {
  addClassOfTeacher,
  deleteClassOfTeacher,
  updateStaffSerive,
} from "../firebase/service.js";
import { addStff } from "../utils";
import { ToastContainer } from "react-toastify";
import { forgotPasswordApi } from "../firebase";
import { OptionsOutsideSelect, Toasts } from "../components";

function StaffModal({
  hide,
  show,
  classdata,
  selectRecord,
  getStaffData,
  schoolDetails,
}) {
  const [form, setForm] = useState(selectRecord);
  const [errors, setErrors] = useState({});
  const [classes, setClass] = useState([]);
  const [classesDb, setClassDb] = useState([]);
  const [allClasses, setAllClasses] = useState([]);
  const [change, setChange] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(false);

  useEffect(() => {
    getMyData();
    setData();
  }, []);
  const getMyData = async () => {
    if (classdata && classdata.length > 0) {
      let tmpClass = [];
      for (let i = 0; i < classdata.length; i++) {
        let obj = {
          value: classdata[i].uid,
          label: classdata[i].group_code
            ? classdata[i].class_name + `(${classdata[i].group_code})`
            : classdata[i].class_name,
          classdata: classdata[i],
        };
        tmpClass.push(obj);
      }
      setAllClasses(tmpClass);
    }
  };

  const setData = async () => {
    if (selectRecord?.class_data && selectRecord?.class_data.length > 0) {
      let tmpClass = [];
      for (let i = 0; i < selectRecord?.class_data.length; i++) {
        let obj = {
          value: selectRecord?.class_data[i].uid,
          label:
            selectRecord?.class_data[i].class_name +
            `(${selectRecord?.class_data[i].group_code})`,
        };
        tmpClass.push(obj);
      }
      setClass(tmpClass);
      setClassDb(tmpClass);
    }
  };
  const handleSubmit = async (e) => {
    setLoadingBtn(true);

    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      setLoadingBtn(false);
    } else {
      let classRecord = [];
      for (let i = 0; i < classes.length; i++) {
        let obj = {
          classId: classes[i].value,
        };
        classRecord.push(obj);
      }
      let staffRecord = {
        name: form.name,
        last_name: form.last_name,
        class: classRecord,
      };

      let resId = await updateStaffSerive(selectRecord?.uid, staffRecord);
      if (!resId) {
        Toasts(
          "We couldn't update this member's data. Please, try again.",
          "error"
        );
      }

      for (let i = 0; i < classesDb.length; i++) {
        const filteredArray = classes.findIndex(
          (e) => e.value === classesDb[i].value
        );
        if (filteredArray === -1) {
          deleteClassOfTeacher(
            true,
            "classId",
            classesDb[i].value,
            "teacher_id",
            selectRecord?.uid
          );
        } else {
        }
      }
      for (let j = 0; j < classes.length; j++) {
        const filteredArray2 = classesDb.findIndex(
          (e) => e.value === classes[j].value
        );
        if (filteredArray2 === -1) {
          let teacherParam = {
            school_id: schoolDetails?.uid,
            teacher_id: selectRecord?.uid,
            showInDashboard: true,
            classId: classes[j].value,
          };
          addClassOfTeacher(teacherParam);
        }
      }

      if (form.password && form.confirmpass) {
        const newUserInfo = {
          email: form.email,
          password: form.password,
          newPassword: form.confirmpass,
          uid: form.uid,
        };
        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ newUserInfo }),
        };
        const updatePassword = await fetch(
          `${process.env.REACT_APP_PUBLIC_API_URL}/update-password`,
          requestOptions
        );
        if (updatePassword?.status === 402) {
          Toasts(
            "We couldn't update this member's data. Please, try again.",
            "error"
          );
        }
      }
      Toasts("Member's data updated successfully", "success");
      setTimeout(() => {
        getStaffData();
        hide();
      }, 5000);
    }
  };
  const setFeild = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });
    if (!!errors[field])
      setErrors({
        ...errors,
        [field]: null,
      });
  };

  const validateForm = () => {
    const { name, last_name, password, confirmpass } = form;
    const newErrors = {};
    if (!name || name === "") newErrors.name = "Please enter the first name";
    if (!last_name || last_name === "")
      newErrors.last_name = "Please enter the last name";
    if (change) {
      if (!password || password === "")
        newErrors.password = "Please enter your password";
      if (!confirmpass || confirmpass === "")
        newErrors.confirmpass = "Please confirm your password";
      else if (password !== confirmpass) {
        newErrors.confirmpass = "Uh-oh, passwords did not match";
      }
    }

    return newErrors;
  };
  const handleClassChange = (selected) => {
    setClass(selected);
  };
  const handleshowpass = () => {
    setChange(!change);
    const newErrors = {};
    newErrors.password = "";
    newErrors.confirmpass = "";
    setErrors(newErrors);
    setForm({
      ...form,
      ["password"]: "",
      ["confirmpass"]: "",
    });
  };
  const stffResetPassword = async () => {
    let res = await forgotPasswordApi(selectRecord?.email);
    if (res) {
      Toasts("Look for our message in your inbox.", "success");
    }
  };
  return (
    <Modal
      size="lg"
      setModalShow="true"
      show={show}
      onHide={hide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modalschool modalstudent"
    >
      <Modal.Header closeButton className="border-none pb-0">
        <Modal.Title id="contained-modal-title-vcenter" className="text-xl">
          Edit “Staff”
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="py-7 px-0">
        <Container>
          <Row>
            {addStff.slice(0, 4).map((val, index) => {
              return (
                <>
                  <Col lg={val?.col} key={index}>
                    {val?.label === "Class" ? (
                      <Form.Group controlId="classes" className="mt-7">
                        <Form.Label className="text-tangaroa font-bold text-base ">
                          Class
                        </Form.Label>
                        <OptionsOutsideSelect
                          onChange={handleClassChange}
                          isMulti
                          options={allClasses}
                          value={classes}
                        />
                      </Form.Group>
                    ) : (
                      <Form.Group
                        controlId={val?.name}
                        className="mt-5"
                        key={index}
                      >
                        <Form.Label className="text-tangaroa font-bold text-base">
                          {val?.label}
                        </Form.Label>
                        <Form.Control
                          type={val?.type}
                          placeholder="- Type here -"
                          className="control"
                          value={form[val?.editName]}
                          onChange={(e) =>
                            setFeild(val?.editName, e.target.value)
                          }
                          isInvalid={!!errors[val?.editName]}
                          disabled={val?.label === "Email" ? true : false}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors[val?.editName]}
                        </Form.Control.Feedback>
                      </Form.Group>
                    )}
                  </Col>
                </>
              );
            })}
          </Row>
          <Form.Group controlId="password" className="mt-5">
            <div className="hstack flex-wrap">
              <Form.Label className="text-tangaroa font-bold text-base mt-3 me-4 flex-1">
                Password
              </Form.Label>
              <a className="anch" href={() => false} onClick={handleshowpass}>
                {change ? "Cancel" : "Change?"}
              </a>
            </div>
            <Form.Control
              type="password"
              placeholder="- Type here -"
              className="control"
              value={form.password}
              isInvalid={!!errors.password}
              disabled={!change}
              onChange={(e) => setFeild("password", e.target.value)}
            />
            <Form.Control.Feedback type="invalid">
              {errors.password}
            </Form.Control.Feedback>
          </Form.Group>
          {change && (
            <Form.Group controlId="confirmpass" className="mt-5">
              <Form.Label className="text-tangaroa font-bold text-base mt-3">
                Confirm Password
              </Form.Label>
              <Form.Control
                type="password"
                placeholder="- Type here -"
                className="control"
                value={form.confirmpass}
                isInvalid={!!errors.confirmpass}
                onChange={(e) => setFeild("confirmpass", e.target.value)}
              />
              <Form.Control.Feedback type="invalid">
                {errors.confirmpass}
              </Form.Control.Feedback>
            </Form.Group>
          )}
          <div className="mt-7 mb-2">
            <p className="font-bold text-base mb-0">Reset password</p>
          </div>
          <Row>
            <Col lg={6} className="text-secondary">
              <p>This user can reset his/her password through email</p>
            </Col>
            <Col lg={6} className="">
              <Button
                variant="outline-dark"
                className="font-bold text-base align-item-center"
                onClick={stffResetPassword}
              >
                Send password reset
              </Button>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer className="border-none pt-0 justify-content-start pb-8">
        <Button
          variant={loadingBtn ? "disabled" : "jade"}
          onClick={handleSubmit}
          className="me-2 my-0"
          disabled={loadingBtn ? true : false}
        >
          Edit
        </Button>
        <Button variant="outline-jade" className=" my-0 ms-0" onClick={hide}>
          Cancel
        </Button>
      </Modal.Footer>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </Modal>
  );
}

export default StaffModal;
