import Select, { components } from "react-select";
import styled from "styled-components";

const ValuesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

const Value = styled.div`
  margin-bottom: 4px;
  background-color: #006bff;
  border-radius: 4px;
  font-size: 16px;
  margin-right: 15px;
  padding: 5px 7px;
  border-radius: 4px;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
`;

const XButton = styled.button`
  margin-left: 0.3rem;
  background-color: #006bff;
  color: #fff;
  font-weight: 600;
`;

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        {/* <label>{props.data.class_name}</label> */}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

const OptionsOutsideSelect = (props) => {
  const { isMulti, value, onChange } = props;

  const handleRemoveValue = (e) => {
    if (!onChange) return;
    const { name: buttonName } = e.currentTarget;
    const removedValue = value.find((val) => val.value === buttonName);
    if (!removedValue) return;
    onChange(
      value.filter((val) => val.value !== buttonName),
      { action: "remove-value", removedValue }
    );
  };

  return (
    <div>
      <Select
        {...props}
        controlShouldRenderValue={!isMulti}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        components={{
          Option,
        }}
      />
      <div className="overflow-y-auto mt-5" style={{ maxHeight: "100px" }}>
        <ValuesContainer>
          {isMulti
            ? value.map((val) => (
                <Value key={val.value}>
                  {val.label}
                  <XButton name={val.value} onClick={handleRemoveValue}>
                    ✕
                  </XButton>
                </Value>
              ))
            : null}
        </ValuesContainer>
      </div>
    </div>
  );
};

export default OptionsOutsideSelect;
