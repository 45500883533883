import { Row, Col, Container } from "react-bootstrap";
function Splash() {
  return (
    <section className="bg-blue h-screen hstack">
      <Container>
        <Row>
          <Col className="my-auto">
            <h1 className="text-center text-5xl text-white ff-circular-bold">
              Tangibles
            </h1>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
export default Splash;
