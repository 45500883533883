import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { importFileTypes } from "../utils/index";
function ImportModal({
  hide,
  show,
  submit,
  type,
  importMessgesType,
  schoolRecord,
}) {
  const [selectedFile, setSelectedFile] = useState();
  const [messsage, setMessage] = useState(false);
  const [importFileType, setImportFileType] = useState("regular");
  const [importFileSchool, setImportFileSchool] = useState("");
  const [disableBtn, setDisableBtn] = useState(false);
  const [importPoint, setImportPoint] = useState();
  const changeHandal = (e) => {
    setMessage(true);
    setSelectedFile(e.target.files[0]);
    setMessage(false);
  };
  const handalSubmit = () => {
    setDisableBtn(true);
    submit(selectedFile, importFileType, importFileSchool, importPoint ? importPoint : 0);
  };
  return (
    <Modal
      size="lg"
      setModalShow="true"
      show={show}
      onHide={hide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modalschool">
      <Modal.Header closeButton className="border-none pb-0">
        <Modal.Title id="contained-modal-title-vcenter" className="text-xl">
          Import .XLSX
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="py-7">
        <Form.Group controlId="formFile" className="mb-7">
          <Form.Control
            type="file"
            className="control"
            accept=".xlsx"
            onChange={(e) => changeHandal(e)}
          />
          {messsage ? (
            <div className="errorup"> UPLOADING...</div>
          ) : selectedFile ? (
            <div className="errorusuc">
              SUCCESS <br />
              {`"${selectedFile?.name}" was uploaded with success. It's ready to import!`}
            </div>
          ) : importMessgesType === "failed" ? (
            <div className="errorufail">
              UPLOAD FAILED
              <br />
              Please check the format of the file and your network, and try
              again.
            </div>
          ) : (
            ""
          )}
        </Form.Group>

        {type === "student" || type === "class" ? (
          <>
            {type === "student" && (
              <>
                <Form.Group controlId="Select School">
                  <Form.Label className="text-tangaroa font-bold text-base">
                    Select School
                  </Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    required="1"
                    className="control mb-4"
                    value={importFileSchool}
                    onChange={(e) => setImportFileSchool(e.target.value)}>
                    <option key="blankChoice" hidden value>
                      - Select school -
                    </option>
                    {schoolRecord.map((val) => {
                      return <option value={val?.name}>{val?.name}</option>;
                    })}
                  </Form.Select>
                </Form.Group>
              </>
            )}
            <Form.Group controlId="type">
              <Form.Label className="text-tangaroa font-bold text-base">
                File type
              </Form.Label>
              <Form.Select
                aria-label="Default select example"
                required="1"
                className="control mb-2"
                value={importFileType}
                onChange={(e) => setImportFileType(e.target.value)}>
                <option selected value="regular">
                  Regular
                </option>
                {importFileTypes.map((val) => {
                  return <option value={val?.name}>{val?.name}</option>;
                })}
              </Form.Select>
            </Form.Group>
            {type === "student" ||
              (type === "class" && (
                <>
                  <Form.Group controlId="" className="mt-3">
                    <Form.Label className="text-tangaroa font-bold text-base">
                      Starting balance
                    </Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Optional"
                      className="control"
                      value={importPoint}
                      onChange={(e) =>
                        setImportPoint(
                          e.target.value < 0
                            ? (e.target.value = 0)
                            : e.target.value
                        )
                      }
                    />
                  </Form.Group>
                </>
              ))}
          </>
        ) : (
          ""
        )}
      </Modal.Body>
      <Modal.Footer className="border-none pt-0 justify-content-start pb-8">
        <Button
          variant={disableBtn ? "disabled" : "jade"}
          className="me-2 my-0 "
          onClick={() => (selectedFile ? handalSubmit() : "")}
          // disabled={disableBtn ? true : false}
        >
          Import
        </Button>
        <Button variant="outline-jade" className="m-0 ms-sm-auto">
          Download template
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ImportModal;
