import React, { useState } from "react";
import { Button, Image, Modal } from "react-bootstrap";
import QRCode from "qrcode";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { storage } from "../firebase";

function QrModal({ hide, show, data, allQrData, allQrDatatype }) {
  const [qrCode, setQrCode] = useState();
  if (data) {
    QRCode.toDataURL(
      `tangibles://s/${
        data?.school_code === undefined ? "" : data?.school_code
      }/${data?.individual_code}`,
      {
        width: 500,
      }
    )
      .then((url) => {
        setQrCode(url);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const uploads = (file) => {
    const ext = file?.type.split("/").pop();
    const fileName = `/${new Date().getUTCMilliseconds()}.${ext}`;
    const storageRef = ref(storage, `files/${fileName}`);
    const uploadTask = uploadBytesResumable(storageRef, file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
      },
      (error) => {
        alert(error);
      },
      async () => {
        await getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          let downloadLink = downloadURL.replace("&", "%26");
          downloadLink = downloadLink.replace("%2F", "%252F");
          const email = "";
          const subject = "Student Qrcodes";
          window.open(
            `mailto:${email}?subject=${encodeURIComponent(
              subject
            )}&body=Qrcode Link is ::- ${downloadLink}`
          );
        });
        hide();
      }
    );
  };
  const sendMail = async () => {
    const downloadedFile = await fetch(qrCode);
    const blobFile = await downloadedFile.blob();
    await uploads(blobFile);
  };
  const allSendMail = async () => {
    var zip = new JSZip();
    var count = 0;
    var zipFilename = "allstudentqrcodes.zip";

    allQrData.forEach(function (url, i) {
      var filename = `${url?.name}.png`;
      let blob = fetch(url?.qrCode).then((r) => r.blob());
      zip.file(filename, blob, { binary: true });
      count++;
      if (count === allQrData.length) {
        zip.generateAsync({ type: "blob" }).then(function (content) {
          uploads(content, zipFilename);
        });
      }
    });
    hide();
  };
  const saveImages = () => {
    saveAs(qrCode, "image.jpg");
    hide();
  };

  const exportAllCodes = () => {
    var zip = new JSZip();
    var count = 0;
    var zipFilename = "all student qrcodes.zip";

    allQrData.forEach(function (url, i) {
      var filename = `${url?.name}.png`;
      let blob = fetch(url?.qrCode).then((r) => r.blob());
      zip.file(filename, blob, { binary: true });
      count++;
      if (count === allQrData.length) {
        zip
          .generateAsync({ type: "blob", width: "500px" })
          .then(function (content) {
            saveAs(content, zipFilename);
          });
      }
    });
    hide();
  };

  return (
    <>
      <Modal
        setModalShow="true"
        show={show}
        onHide={hide}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="qrmodal modalschool">
        <Modal.Header closeButton className="border-none pb-4 px-6">
          <Modal.Title id="contained-modal-title-vcenter" className="text-lg">
            {allQrDatatype}
          </Modal.Title>
        </Modal.Header>
        {allQrDatatype === "QR Code" && (
          <Modal.Body className="py-0">
            <div className="pt-2 pb-7">
              <p className="font-bold ">{data?.name}</p>
              <Image style={{ maxWidth: "300px" }} src={qrCode}></Image>
            </div>
          </Modal.Body>
        )}

        <Modal.Footer className="border-none pt-0 justify-content-start pb-8">
          <Button
            variant="jade"
            className="me-2 my-0"
            onClick={
              allQrDatatype === "Exporting all QR Codes"
                ? () => exportAllCodes()
                : () => saveImages()
            }>
            Export
          </Button>
          <Button
            variant="outline-jade"
            className="me-0 px-4 my-0"
            onClick={
              allQrDatatype === "Exporting all QR Codes"
                ? () => allSendMail()
                : () => sendMail()
            }>
            Send by email
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default QrModal;
