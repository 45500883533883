import React, { useState } from "react";
import { Button, Form, Modal, Container, Row, Col } from "react-bootstrap";
import { updateSchoolService } from "../firebase/service";
import { Check2, X } from "react-bootstrap-icons";
import { addSchoolCol1, provinceOptions } from "../utils";
import { Toasts } from "../components";
import { ToastContainer } from "react-toastify";
import MaskedFormControl from "react-bootstrap-maskedinput";
import { emailVlidation, phoneNumberVlidation } from "../utils/validation";

function EditModal({ hide, show, item, submit }) {
  let giveArray = [];
  let tradeArray = [];
  item?.give.map((val) => {
    return giveArray.push({
      key: val?.key,
      name: val?.name,
      amount: val?.amount,
      isSelect: false,
      error: "",
    });
  });
  item?.trade.map((val) => {
    return tradeArray.push({
      key: val?.key,
      name: val?.name,
      amount: val?.amount,
      isSelect: false,
      error: "",
    });
  });
  const [addCategoryGive, setAddCategoryGive] = useState(false);
  const [addCategoryTrade, setAddCategoryTrade] = useState(false);
  const [addFileds, setAddFileds] = useState();
  const [give, setGive] = useState(giveArray);
  const [trade, setTrade] = useState(tradeArray);
  const [errors, setErrors] = useState({});
  const [errorsCheck, setErrorsCheck] = useState(true);
  const [form, setForm] = useState({
    address: item.address,
    city: item.city,
    code: item.code,
    email: item.email,
    level: item.level,
    type: item?.schoolType,
    name: item.name,
    postalCode: item.postalCode,
    phoneNumber: item.phoneNumber,
    totalPoint: item.totalPoint,
    province: item.province,
  });
  const [loadingBtn, setLoadingBtn] = useState(false);

  const validateForm = () => {
    const {
      address,
      city,
      code,
      email,
      name,
      phoneNumber,
      totalPoint,
      province,
    } = form;
    const newErrors = {};
    if (!code || code === "") newErrors.code = " please Enter Your Code";
    if (!name || name === "") newErrors.name = " Please enter your name";
    if (!email) {
      newErrors.email = "Please enter your email";
    } else if (!emailVlidation(email)) {
      newErrors.email = "Please enter your email";
    }
    if (!phoneNumber) {
      newErrors.phoneNumber = "Please enter your phone number";
    } else if (!phoneNumberVlidation(phoneNumber)) {
      newErrors.phoneNumber = "Please enter a valid phone number";
    }
    if (!address || address === "")
      newErrors.address = "Please enter your address";
    if (!city || city === "") newErrors.city = "Please enter your city";
    // if (!postalCode || postalCode === "")
    //   newErrors.postalCode = "Please enter your postal code";
    if (!province || province === "")
      newErrors.province = "Please enter your province";
    if (!totalPoint || totalPoint === "")
      newErrors.totalPoint = "Please enter total point";
    return newErrors;
  };

  const setFeild = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });
    if (!!errors[field])
      setErrors({
        ...errors,
        [field]: null,
      });
  };
  const setFeildCategory = (e, idx, val) => {
    let clone = [...val];
    let obj = clone[idx];
    obj.amount = Number(e.target.value);
    obj.error = "";
    clone[idx] = obj;
    if (val === give) {
      setGive([...clone]);
    } else {
      setTrade([...clone]);
    }
  };
  const giveError = () => {
    for (let index = 0; index < give.length; index++) {
      let obj = give[index];
      if (!obj?.amount && obj?.amount === 0) {
        setErrorsCheck(false);
        let clone = [...give];
        let temp = clone[index];
        temp.error = `Please enter ${obj.label.toLocaleLowerCase()}`;
        setGive([...clone]);
      }
    }
  };
  const tradeError = () => {
    for (let index = 0; index < trade.length; index++) {
      let obj = trade[index];
      if (!obj?.amount && obj?.amount === 0) {
        setErrorsCheck(false);
        let clone = [...trade];
        let temp = clone[index];
        temp.error = `Please enter ${obj.label?.toLocaleLowerCase()}`;
        setTrade([...clone]);
      }
    }
  };
  const editSchool = async (e) => {
    e.preventDefault();
    await giveError();
    await tradeError();
    const formErrors = validateForm();
    if (Object.keys(formErrors).length === 0 && errorsCheck) {
      setLoadingBtn(true);
      let params = {
        address: form?.address,
        city: form?.city,
        code: form?.code,
        email: form?.email,
        schoolType: form?.type,
        name: form?.name,
        postalCode: form?.postalCode,
        phoneNumber: form?.phoneNumber,
        province: form?.province,
        give: give,
        trade: trade,
        totalPoint: Number(form?.totalPoint),
      };
      let resId = await updateSchoolService(item.uid, params);
      if (resId) {
        Toasts("School's data updated successfully", "success");
        setTimeout(() => {
          submit();
        }, 4000);
      } else {
        Toasts(
          "We couldn't update this member's data. Please, try again.",
          "error"
        );
      }
    } else {
      setErrors(formErrors);
      setLoadingBtn(false);
    }
  };
  const addCategoryShow = (val) => {
    if (val === "give") {
      setAddCategoryGive(true);
    } else {
      setAddCategoryTrade(true);
    }
  };
  const addCategoryHandal = (val) => {
    if (val === "give") {
      let obj = {
        name: addFileds.charAt(0).toUpperCase() + addFileds.slice(1),
        key: addFileds.toLowerCase(),
        amount: null,
        isSelect: false,
      };
      setAddFileds();
      setGive([...give, obj]);
      setAddCategoryGive(false);
    } else {
      let obj = {
        name: addFileds.charAt(0).toUpperCase() + addFileds.slice(1),
        key: addFileds.toLowerCase(),
        amount: null,
        isSelect: false,
      };
      setAddFileds();
      setTrade([...trade, obj]);
      setAddCategoryTrade(false);
    }
  };
  const removeCategory = (val, type) => {
    let data = [];
    if (type === "give") {
      data = give.filter((a) => a.name !== val.name);
      setGive(data);
      data = [];
    } else {
      data = trade.filter((a) => a.name !== val.name);
      setTrade(data);
      data = [];
    }
  };
  return (
    <Modal
      size="lg"
      setModalShow="true"
      show={show}
      onHide={hide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modalschool modal2"
    >
      <Modal.Header closeButton className="border-none pb-0 hstack flex-wrap">
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="text-xl flex-1"
        >
          Edit “School”
        </Modal.Title>
        <div class="hstack">
          <Form.Label className="text-tangaroa font-bold text-base me-3 mb-0 pt-0">
            Code
          </Form.Label>
          <Form.Control
            type="number"
            id="code"
            value={form.code}
            placeholder="-0000-"
            aria-describedby="passwordHelpBlock"
            className="control py-2 w-28"
            isInvalid={!!errors.code}
            min={0}
            disabled
          />
        </div>
      </Modal.Header>
      <Modal.Body className="py-7 px-0">
        <Container>
          <Row>
            {addSchoolCol1.map((val, index) => {
              return (
                <>
                  <Col lg={val?.col} key={index}>
                    {val?.label === "Type" ? (
                      <Form.Group controlId="type">
                        <Form.Label className="text-tangaroa font-bold text-base">
                          {val?.label}
                        </Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          required="1"
                          className="control mb-2"
                          value={form[val?.name]}
                          onChange={(e) => setFeild(val?.name, e.target.value)}
                        >
                          <option selected value="Elementary school">
                            Elementary school
                          </option>
                          <option value="High school">High school</option>
                        </Form.Select>
                      </Form.Group>
                    ) : val?.label === "Province" ? (
                      <Form.Group controlId="type">
                        <Form.Label className="text-tangaroa font-bold text-base">
                          {val?.label}
                        </Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          required="1"
                          className="control mb-2"
                          value={form[val?.name]}
                          onChange={(e) => setFeild(val?.name, e.target.value)}
                        >
                          <option selected value="Alberta">
                            Alberta
                          </option>
                          {provinceOptions.map((val) => {
                            return (
                              <option value={val?.name}>{val?.name}</option>
                            );
                          })}
                        </Form.Select>
                      </Form.Group>
                    ) : val?.label === "Phone Number" ? (
                      <Form.Group controlId={val?.name}>
                        <Form.Label className="text-tangaroa font-bold text-base">
                          {val?.label}
                        </Form.Label>
                        <MaskedFormControl
                          type={val?.type}
                          placeholder={val?.placeholder}
                          value={form[val?.name]}
                          onChange={(e) => setFeild(val?.name, e.target.value)}
                          isInvalid={!!errors[val?.name]}
                          className="control mb-2"
                          mask={
                            val?.label === "Phone Number"
                              ? "(111) 111-1111"
                              : "*** ***"
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors[val?.name]}
                        </Form.Control.Feedback>
                      </Form.Group>
                    ) : val?.label === "Postal Code" ? (
                      <Form.Group controlId={val?.name}>
                        <Form.Label className="text-tangaroa font-bold text-base">
                          {val?.label}
                        </Form.Label>
                        <MaskedFormControl
                          type={val?.type}
                          placeholder={val?.placeholder}
                          value={form[val?.name]}
                          onChange={(e) => setFeild(val?.name, e.target.value)}
                          isInvalid={!!errors[val?.name]}
                          className="control mb-2"
                          mask="*** ***"
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors[val?.name]}
                        </Form.Control.Feedback>
                      </Form.Group>
                    ) : (
                      <Form.Group controlId={val?.name}>
                        <Form.Label className="text-tangaroa font-bold text-base">
                          {val?.label}
                        </Form.Label>
                        <Form.Control
                          type={val?.type}
                          placeholder={val?.placeholder}
                          value={form[val?.name]}
                          onChange={(e) => setFeild(val?.name, e.target.value)}
                          isInvalid={!!errors[val?.name]}
                          className="control mb-2"
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors[val?.name]}
                        </Form.Control.Feedback>
                      </Form.Group>
                    )}
                  </Col>
                </>
              );
            })}
          </Row>
        </Container>
        <div className="bg-whiteshade px-0 py-5 mt-10">
          <Container>
            <Row>
              <Col lg={6}>
                <div className="hstack mb-7 flex-wrap d-flex justify-content-between me-2">
                  <p className="font-bold text-tangaroa me-2">Give</p>
                  <Button
                    variant="jade"
                    onClick={() => addCategoryShow("give")}
                    className="py-2"
                  >
                    Add
                  </Button>
                </div>
                {addCategoryGive && (
                  <div className="position-relative">
                    <Form.Control
                      type="text"
                      placeholder="- Type here -"
                      value={addFileds}
                      onChange={(e) => setAddFileds(e.target.value)}
                      className="add-control mb-2 rounded-0 pe-10 shadow-none ps-0"
                    />
                    <Check2
                      className="text-xl font-bold position-absolute end-10 bottom-2 text-green"
                      onClick={() => addCategoryHandal("give")}
                    ></Check2>
                    <X
                      className="text-xl font-bold position-absolute end-2 bottom-2 text-danger"
                      onClick={() => setAddCategoryGive(false)}
                    ></X>
                  </div>
                )}
                <div className="catagory-scroll">
                  {give.map((val, index) => {
                    return (
                      <>
                        <Col lg={12}>
                          <div className="max-h-88 overflow-y-auto">
                            <div className="border-top hstack py-5 flex-wrap">
                              <p className="text-sm text-tangaroa flex-1 me-2 mb-0">
                                {val?.name}
                              </p>
                              <p className="text-sm text-tangaroa me-3 mb-0">
                                Value
                              </p>
                              <Form.Control
                                type="number"
                                placeholder="XX"
                                value={val?.amount && Math.max(0, val?.amount)}
                                onChange={(e) =>
                                  setFeildCategory(e, index, give)
                                }
                                isInvalid={!!val?.error}
                                className="control w-16 px-3 me-2 text-center"
                                min={1}
                              />
                              <Form.Control.Feedback type="invalid">
                                {val?.error}
                              </Form.Control.Feedback>
                              <X
                                className="text-xl font-bold"
                                onClick={() => removeCategory(val, "give")}
                              ></X>
                            </div>
                          </div>
                        </Col>
                      </>
                    );
                  })}
                </div>
              </Col>
              <Col lg={6}>
                <div className="hstack mb-7 flex-wrap d-flex justify-content-between me-2">
                  <p className="font-bold text-tangaroa me-2">Trade</p>
                  <Button
                    variant="jade"
                    onClick={() => addCategoryShow("trade")}
                    className="py-2"
                  >
                    Add
                  </Button>
                </div>
                {addCategoryTrade && (
                  <div className="position-relative">
                    <Form.Control
                      type="text"
                      placeholder="- Type here -"
                      value={addFileds}
                      onChange={(e) => setAddFileds(e.target.value)}
                      className="add-control mb-2 rounded-0 pe-10 shadow-none ps-0"
                    />
                    <Check2
                      className="text-xl font-bold position-absolute end-10 bottom-2 text-green"
                      onClick={() => addCategoryHandal("trade")}
                    ></Check2>
                    <X
                      className="text-xl font-bold position-absolute end-2 bottom-2 text-danger"
                      onClick={() => setAddCategoryTrade(false)}
                    ></X>
                  </div>
                )}
                <div className="catagory-scroll">
                  {trade.map((val, index) => {
                    return (
                      <>
                        <Col lg={12}>
                          <div className="max-h-88 overflow-y-auto">
                            <div className="border-top hstack py-5 flex-wrap">
                              <p className="text-sm text-tangaroa flex-1 me-2 mb-0">
                                {val?.name}
                              </p>
                              <p className="text-sm text-tangaroa me-3 mb-0">
                                Value
                              </p>
                              <Form.Control
                                type="number"
                                placeholder="XX"
                                value={val?.amount}
                                onChange={(e) =>
                                  setFeildCategory(e, index, trade)
                                }
                                isInvalid={!!val?.error}
                                className="control w-16 px-3 me-2 text-center"
                                max={0}
                              />
                              <Form.Control.Feedback type="invalid">
                                {val?.error}
                              </Form.Control.Feedback>
                              <X
                                className="text-xl font-bold"
                                onClick={() => removeCategory(val, "trade")}
                              ></X>
                            </div>
                          </div>
                        </Col>
                      </>
                    );
                  })}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="hstack flex-wrap mt-8 px-5">
          <p className="font-bold text-tangaroa me-4 mb-0">
            Distribution pts goal / Month
          </p>
          <Form.Control
            type="number"
            placeholder="XX"
            value={form.totalPoint}
            onChange={(e) => setFeild("totalPoint", e.target.value)}
            isInvalid={!!errors.totalPoint}
            className="control w-16 px-3 me-2 text-center"
          />
        </div>
      </Modal.Body>
      <Modal.Footer className="border-none pt-0 justify-content-start pb-8">
        <Button
          variant={loadingBtn ? "disabled" : "jade"}
          onClick={editSchool}
          className=" me-2 my-0 d-flex"
          disabled={loadingBtn ? true : false}
        >
          Edit
        </Button>
        <Button variant="outline-jade" className=" my-0 ms-0" onClick={hide}>
          Cancel
        </Button>
      </Modal.Footer>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </Modal>
  );
}

export default EditModal;
