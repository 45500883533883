import * as types from "../actionTypes";
const initialState = {
  isAuthenticated: localStorage.getItem("authApp") || false,
  // user: { email: "user@gmail.com", pass: "123" },
  email: "",
  loginUserData: localStorage.getItem("loginData") || null,
  currentUserData: JSON.parse(localStorage.getItem("userData")) || null,
};
// Reducers
const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOGIN_SUCCESS:
      localStorage.setItem("authApp", true);
      return {
        ...state,
        isAuthenticated: true,
        email: "user@gmail.com",
      };
    case types.LOGOUT_SUCCESS:
      localStorage.setItem("authApp", false);
      return {
        ...state,
        isAuthenticated: false,
      };
    case types.LOGIN_DATA:
      localStorage.setItem("loginData", action.data);

      return {
        ...state,
        loginUserData: action.data,
      };

    case types.CURRENT_USER:
      localStorage.setItem("userData", JSON.stringify(action.currentUser));

      return {
        ...state,
        currentUserData: action.currentUser,
      };

    default:
      return state;
  }
};
export default AuthReducer;
