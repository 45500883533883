import { React } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { ExclamationTriangle } from "react-bootstrap-icons";
function ClassificationAlertModal({ hide, show, studentData, type }) {
  let message =
    type === "regular"
      ? "already exist on the database (or did not follow correct format) and were not imported"
      : "have no classification and were not imported";

  return (
    <Modal
      size="lg"
      setModalShow="true"
      show={show}
      onHide={hide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="classification-modal modalschool">
      <Modal.Header closeButton className="border-none pb-0 p-8">
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="text-xl d-flex">
          <ExclamationTriangle className="text-sm me-2 mt-2" size={20} />
          <p> Alert</p>
        </Modal.Title>
      </Modal.Header>
      <p className="font-regular text-sm ps-8 mb-0">
        {`The following students ${message}.`}
      </p>
      <Modal.Body className="py-7">
        <Table className="schooltable">
          <thead className="text-tangaroa">
            <tr>
              <th className="text-base font-800 text-capitalize">#</th>
              <th className="text-base font-800 text-capitalize">Name</th>
              <th className="text-base font-800 text-capitalize">
                {type === "regular" ? "" : "Classification"}
              </th>
            </tr>
          </thead>
          <tbody className="text-tangaroa">
            {studentData?.map((val, index) => {
              return (
                <tr key={index}>
                  <td className="text-base font-800">{index + 1}</td>
                  <td className="font-regular text-base">
                    {val?.fName} {val?.lName}
                  </td>
                  <td className="font-regular text-base">-</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer className="border-none pt-0 justify-content-start pb-8">
        <Button variant="jade" className=" my-0 ms-0" onClick={hide}>
          Thank you
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
export default ClassificationAlertModal;
