import React from "react";
import { useState } from "react";
import { Navbar, Container, Nav } from "react-bootstrap";
import LogoutModal from "./logoutModal";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

function Header() {
  const { currentUserData } = useSelector((state) => state.AuthReducer);
  const [logoutModel, setLogoutModel] = useState(false);
  const navigate = useNavigate();

  const logoutModelShow = () => {
    setLogoutModel(!logoutModel);
  };
  let url = window.location.href;
  let PageUrl = url.substring(url.lastIndexOf("/") + 1);
  return (
    <div>
      <Navbar bg="light" expand="lg">
        <Container>
          <Navbar.Brand className="text-center text-crayolablue text-lg text-xl ff-circular-bold me-3">
            Tangibles
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link
                onClick={() => navigate("/schools")}
                className={
                  PageUrl === "schools"
                    ? "text-sm font-bolder text-tangaroa"
                    : "text-sm text-lightgrayishBlue"
                }
              >
                Schools
              </Nav.Link>
              <Nav.Link
                onClick={() => navigate("/students")}
                className={
                  PageUrl === "students"
                    ? "text-sm font-bolder text-tangaroa"
                    : "text-sm text-lightgrayishBlue"
                }
              >
                Students
              </Nav.Link>
            </Nav>
            <Nav className="ms-auto">
              <Nav.Link
                onClick={() => navigate("/info")}
                className="text-sm font-bolder text-tangaroa"
              >
                Hi, {currentUserData?.name}
              </Nav.Link>
              <Nav.Link
                onClick={logoutModelShow}
                className="text-sm text-lightgrayishBlue pe-0"
              >
                Logout
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      {logoutModel && (
        <LogoutModal show={logoutModel} hide={() => logoutModelShow()} />
      )}
    </div>
  );
}

export default Header;
