export const users = [
    {
      id: 1,
      code: 'Mark',
      name: 'Otto'
    },
    {
      id: 2,
      code: 'Jacob',
      name: 'Thornton'
    },
    {
      id: 3,
      code: 'larry',
      name: 'Otto'
    },
    {
      id: 4,
      code: 'larry',
      name: 'Thornton'
    },
    {
      id: 5,
      code: 'larry',
      name: 'Thornton'
    },
   
  ];

  export const student = [
    {
      id: 1,
      code: '@mdo',
      fname: 'Mark',
      lname: 'Otto',
      classes: 'Otto'
    },
    {
      id: 2,
      code: '@fat',
      fname: 'Jacob',
      lname: 'Thornton',
      classes: 'Thornton'
    },
    {
      id: 3,
      code: '@twitter',
      fname: 'Larry',
      lname: 'the Bird',
      classes: 'the Bird'
    },
    {
      id: 4,
      code: '@twitter',
      fname: 'Larry',
      lname: 'the Bird',
      classes: 'the Bird'
    },
    {
      id: 5,
      code: '@twitter',
      fname: 'Larry',
      lname: 'the Bird',
      classes: 'the Bird'
    },
    {
      id: 6,
      code: '@twitter',
      fname: 'Larry',
      lname: 'the Bird',
      classes: 'the Bird'
    },
    {
      id: 7,
      code: '@twitter',
      fname: 'Larry',
      lname: 'the Bird',
      classes: 'the Bird'
    },
    {
      id: 8,
      code: '@twitter',
      fname: 'Larry',
      lname: 'the Bird',
      classes: 'the Bird'
    },
    {
      id: 9,
      code: '@twitter',
      fname: 'Larry',
      lname: 'the Bird',
      classes: 'the Bird'
    },
    {
      id: 10,
      code: '@twitter',
      fname: 'Larry',
      lname: 'the Bird',
      classes: 'the Bird'
    },
   
  ];
  
  