import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

const AuthenticatedRoute = (props) => {
  const { isAuthenticated } = useSelector((state) => state.AuthReducer);
  const { children } = props;
  const token = localStorage.getItem("token");
  if (token) {
    const decodedJwt = parseJwt(token);
    if (decodedJwt.exp * 1000 < Date.now()) {
      localStorage.clear();
      return <Navigate to="/" />;
    }
  }
  return isAuthenticated ? <>{children}</> : <Navigate to="/" />;
};
export default AuthenticatedRoute;
