import { React, useEffect, useState } from "react";
import { Edit } from "../assets/images";
import { Delete } from "../assets/images";
import { Row, Col, Container, Table, Pagination, Form } from "react-bootstrap";
import {
  Header,
  Subheader,
  Footer,
  EditStudent,
  DeleteModal,
  QrModal,
} from "../components";
import { QrCodeScan } from "react-bootstrap-icons";
import {
  getAllClass,
  removeStudentRecord,
  deleteClassOfStudent,
  getAllSchool,
  getAllStudentsShow,
  getClassByStudentNew,
  getAllStudentClassess,
  getAllStudentCount,
  getAllStudentNext,
  getAllStudentPrev,
  getAllSchoolByStudentSearch,
  getAllSchoolByStudentCount,
  getAllStudentNextSchool,
  getAllStudentNextSearch,
  getAllStudentPrevSearch,
  getAllStudentSearch,
  getAllStudentPrevSchool,
} from "../firebase/service";
import Skeleton from "react-loading-skeleton";
import { StudentLoading } from "../components";
import { ToastContainer } from "react-toastify";
import { Consts } from "../utils/Constant";

function Student() {
  const [editStudentmodal, setEditstudent] = useState(false);
  const [deleteModal, setDeletemodal] = useState(false);
  const [qrModal, setQrmodal] = useState(false);
  const [studentRecord, setStudentRec] = useState([]);
  const [selectedStudent, setSelectStudent] = useState([]);
  const [qrData, setQrData] = useState();
  const [allQrDatatype, setAllQrDataType] = useState("qrCode");
  const [allClasses, setAllClasses] = useState([]);
  const [searchTemp, setSearchTemp] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchMsg, setSearchMsg] = useState(false);
  const [serchSchoolWise, setSerchSchoolWise] = useState("");
  const [searchMsgVal, setSearchMsgVal] = useState("");
  const [schoolRecord, setSchool] = useState([]);
  const [page, setPage] = useState(1);
  const perPage = 10;
  const [itemsPerPage] = useState(10);
  const [studentSnap, setStudentSnap] = useState();
  const [studentRecordLength, setStudentRecordLength] = useState();

  //pagination
  let totalPage = Math.ceil(studentRecordLength / itemsPerPage);
  let pagination = [],
    i = 1;
  while (i <= totalPage) {
    if (i <= 1 || i >= totalPage - 2 || (i >= page - 1 && i <= page + 1)) {
      pagination.push(i);
      i++;
    } else {
      pagination.push("...");
      //jump to the next page to be linked in the navigation
      i = i < page ? page - 1 : totalPage - 0;
    }
  }
  useEffect(() => {
    allStudentCounts();
    getStudentData();
    getClassData();
    getSchoolData();
  }, []);
  // get all student count
  const allStudentCounts = async () => {
    setStudentRecordLength(await getAllStudentCount());
  };
  // get all student
  const getStudentData = async () => {
    // const allStudents = await getAllStudentsShow();
    const documentSnapshots = await getAllStudentsShow(itemsPerPage);
    setStudentSnap(documentSnapshots);
    // setFirstVisible(documentSnapshots.docs[0]);
    allStudentCounts();
    setupStudentRecord(documentSnapshots);
  };
  const setupStudentRecord = async (documentSnapshots) => {
    const record = [];
    var rowsData = [];
    documentSnapshots.forEach((document) => {
      record.push({
        uid: document.id,
        ...document.data(),
      });
    });
    let allClassess = await getAllClass();
    let allClassesByStudent = await getAllStudentClassess();
    for (let index = 0; index < record?.length; index++) {
      const doc = record[index];
      var classData = await getClassByStudentNew(
        doc?.uid,
        allClassesByStudent,
        allClassess
      );
      var obj = {
        ...doc,
        uid: doc?.uid,
        class_data: classData ? classData : [],
        // class_id: classData?.uid,
      };
      rowsData.push(obj);
    }
    if (rowsData) {
      setLoading(true);
      setStudentRec(rowsData);
      setSearchTemp(rowsData);
    } else {
      // setLoading(true);
    }
  };
  // get all class
  const getClassData = async () => {
    const myClasses = await getAllClass();
    if (myClasses && myClasses.length > 0) {
      let tmpClass = [];
      for (let i = 0; i < myClasses.length; i++) {
        let obj = {
          value: myClasses[i].uid,
          label: myClasses[i].group_code
            ? myClasses[i].class_name + `(${myClasses[i].group_code})`
            : myClasses[i].class_name,
          school_id: myClasses[i].school_id,
        };
        tmpClass.push(obj);
      }
      setAllClasses(tmpClass);
    }
  };
  // get all school
  const getSchoolData = async () => {
    const record = await getAllSchool();
    if (record && record.length > 0) {
      setSchool(record);
    }
  };
  // edit student
  const editRecord = () => {
    setEditstudent(!editStudentmodal);
    getStudentData();
  };
  // delete student
  const deleteRecord = async () => {
    deleteClassOfStudent(false, "student_id", selectedStudent?.uid);
    getClassData();

    const response = await removeStudentRecord(
      selectedStudent?.uid,
      selectedStudent?.class_id
    );
    if (response) {
      var newAllClassStudents = studentRecord.filter(
        (item) => item.uid !== selectedStudent.uid
      );
      getStudentData();
      setStudentRec(newAllClassStudents);
      setSearchTemp(newAllClassStudents);
    }

    setDeletemodal(!deleteModal);
  };
  // edit student show modal
  const editStudentshow = (items) => {
    setSelectStudent(items);
    setEditstudent(!editStudentmodal);
  };
  // delete student show modal
  const deleteModalshow = (items) => {
    setSelectStudent(items);
    setDeletemodal(!deleteModal);
  };
  // qr code show modal
  const qrModalshow = (value) => {
    setAllQrDataType("QR Code");
    setQrData(value);
    setQrmodal(!qrModal);
  };
  // serch student
  const serchingHandal = async (val) => {
    setSearchMsg(true);
    setSearchMsgVal(val);
    if (val !== "") {
      const record = [];
      const documentSnapshots = await getAllStudentSearch(itemsPerPage, val);
      setStudentSnap(documentSnapshots);
      documentSnapshots.forEach((document) => {
        record.push({
          uid: document.id,
          ...document.data(),
        });
      });
      setStudentRecordLength(record?.length);
      setupStudentRecord(documentSnapshots);
    } else {
      setSearchMsgVal("");
      allStudentCounts();
      getStudentData();
    }
  };
  // pagination next handal
  const nextHandal = async () => {
    let documentSnapshots = [];
    if (searchMsgVal !== "") {
      documentSnapshots = await getAllStudentNextSearch(
        studentSnap,
        itemsPerPage,
        searchMsgVal
      );
    } else if (serchSchoolWise) {
      let schoolId = schoolRecord.filter((nm) => nm?.name === serchSchoolWise);
      documentSnapshots = await getAllStudentNextSchool(
        studentSnap,
        itemsPerPage,
        schoolId[0]?.uid
      );
    } else {
      documentSnapshots = await getAllStudentNext(studentSnap, itemsPerPage);
    }
    if (documentSnapshots?.docs[documentSnapshots.docs.length - 1]) {
      setStudentSnap(documentSnapshots);
    }
    setupStudentRecord(documentSnapshots);
    setPage(page + 1);
  };
  // pagination prev handal
  const prevHandal = async () => {
    let documentSnapshots = [];
    if (searchMsgVal !== "") {
      documentSnapshots = await getAllStudentPrevSearch(
        studentSnap,
        itemsPerPage,
        searchMsgVal
      );
    } else if (serchSchoolWise) {
      let schoolId = schoolRecord.filter((nm) => nm?.name === serchSchoolWise);
      documentSnapshots = await getAllStudentPrevSchool(
        studentSnap,
        itemsPerPage,
        schoolId[0]?.uid
      );
    } else {
      documentSnapshots = await getAllStudentPrev(studentSnap, itemsPerPage);
    }
    if (documentSnapshots?.docs[documentSnapshots.docs.length - 1]) {
      setStudentSnap(documentSnapshots);
    }
    setupStudentRecord(documentSnapshots);
    setPage(page - 1);
  };
  // search by school wise student
  const serchSchoolWiseStudent = async (val) => {
    try {
      let schoolId = schoolRecord.filter((nm) => nm?.name === val);
      const myClasses = searchTemp;
      if (val) {
        setSerchSchoolWise(val);
        setPage(1);
        if (val === "allSchool") {
          getStudentData();
          setSerchSchoolWise("");
        } else {
          setStudentRecordLength(
            await getAllSchoolByStudentCount(schoolId[0]?.uid)
          );
          let documentSnapshots = await getAllSchoolByStudentSearch(
            schoolId[0]?.uid,
            itemsPerPage
          );
          const record = [];
          documentSnapshots.forEach((document) => {
            record.push({
              uid: document.id,
              ...document.data(),
            });
          });
          setStudentSnap(documentSnapshots);
          setupStudentRecord(documentSnapshots);
          // myClasses
          //   .filter((s) => s?.school_id === schoolId[0]?.uid)
          //   // .filter((s) => s?.schoolName === val)
          //   .map((val) => {
          //     return searchArr.push(val);
          //   });
          // setStudentRec(searchArr);
        }
      } else {
        setStudentRec(myClasses);
        setSearchMsg(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <section className="min-h-lg-screen vstack student">
        <Header />
        <Subheader serchingHandal={(e) => serchingHandal(e)} />
        <Container>
          <Row className="mt-10">
            <Col lg={3} className="mb-7 mt-3">
              <p className="font-bold d-flex">
                {!loading ? (
                  <Skeleton width={100} height={20} />
                ) : (
                  `All students(${studentRecordLength})`
                )}
              </p>
            </Col>
            <Col lg={3} className="mb-7 mt-3 d-flex justify-content-center">
              <p className="mb-0">Showing:</p>
              <Form.Group controlId="type" className="">
                <Form.Select
                  aria-label="Default select example"
                  required="1"
                  className="search-school mb-2"
                  size="sm"
                  value={serchSchoolWise}
                  onChange={(e) => serchSchoolWiseStudent(e.target.value)}
                >
                  <option selected value={"allSchool"}>
                    All Schools
                  </option>
                  {schoolRecord.map((val, index) => {
                    return (
                      <option value={val?.name} key={index}>
                        {val?.name}
                      </option>
                    );
                  })}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col
              lg={6}
              className="hstack gap-3 justify-content-lg-end flex-wrap mb-7"
            ></Col>
            {loading ? (
              <>
                {studentRecord?.length > 0 ? (
                  <>
                    <Col sm={12} className="mb-8">
                      <Table responsive className="schooltable">
                        <thead className="text-tangaroa">
                          <tr>
                            <th className="text-base font-800">#</th>
                            <th className="text-base font-800 text-capitalize">
                              Code
                            </th>
                            <th className="text-base font-800 text-capitalize">
                              First
                            </th>
                            <th className="text-base font-800 text-capitalize">
                              Last
                            </th>
                            <th className="text-base font-800 text-capitalize">
                              Classes
                            </th>
                          </tr>
                        </thead>
                        <tbody className="text-tangaroa">
                          {studentRecord
                            // .slice(firstPageIndex, lastPageIndex)
                            ?.map((student, index) => {
                              return (
                                <tr key={index}>
                                  <td className="text-base font-800">
                                    {(page - 1) * perPage + index + 1}
                                  </td>
                                  <td className="font-regular text-base">
                                    {`${student?.school_code}/${student?.individual_code}`}
                                  </td>
                                  <td className="font-regular text-base">
                                    {student.name}
                                  </td>
                                  <td className="font-regular text-base">
                                    {student.last_name}
                                  </td>
                                  <td className="font-regular text-base">
                                    {student?.class_data
                                      .slice(0, 3)
                                      .map((val, index) => {
                                        return (
                                          <>
                                            {index ? ", " : ""}
                                            {val?.classDetails?.class_name +
                                              " " +
                                              `(${val?.classDetails?.group_code})`}
                                            {index < 3 && index === 2
                                              ? "...."
                                              : ""}
                                          </>
                                        );
                                      })}
                                  </td>
                                  <td className="text-end pe-0">
                                    <a
                                      href={() => false}
                                      className="btn btn-transparent"
                                      onClick={() => qrModalshow(student)}
                                    >
                                      <QrCodeScan
                                        className="text-sm"
                                        size={20}
                                      />
                                    </a>
                                    <a
                                      href={() => false}
                                      className="btn btn-transparent"
                                      onClick={() => editStudentshow(student)}
                                    >
                                      <img
                                        src={Edit}
                                        className="text-sm"
                                        size={20}
                                        alt="Edit"
                                      />
                                    </a>
                                    <a
                                      href={() => false}
                                      className="btn btn-transparent pe-0"
                                      onClick={() => deleteModalshow(student)}
                                    >
                                      <img
                                        src={Delete}
                                        className="text-sm text-danger"
                                        size={20}
                                        alt="Delete"
                                      />
                                    </a>
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </Table>
                    </Col>
                    <Col className="d-flex justify-content-end mb-10 table-pagination">
                      <Pagination>
                        {page === 1 ? (
                          <Pagination.Item className="me-1 rounded-4" disabled>
                            Prev
                          </Pagination.Item>
                        ) : (
                          <Pagination.Item
                            className="me-1 rounded-4"
                            onClick={() => prevHandal()}
                          >
                            Prev
                          </Pagination.Item>
                        )}
                        {
                          // [...Array(totalPage + 1).keys()]
                          //   .slice(1)
                          pagination?.map((val) => {
                            return (
                              <>
                                {val === page ? (
                                  <Pagination.Item
                                    key={val}
                                    active={val === page}
                                    // onClick={() =>
                                    //   val === "..." ? "" : nextNumberHandal(val)
                                    // }
                                  >
                                    {val}
                                  </Pagination.Item>
                                ) : (
                                  ""
                                )}
                              </>
                            );
                          })
                        }
                        {totalPage === page ? (
                          <Pagination.Item disabled>Next</Pagination.Item>
                        ) : (
                          <Pagination.Item onClick={() => nextHandal()}>
                            Next
                          </Pagination.Item>
                        )}
                      </Pagination>
                    </Col>
                  </>
                ) : (
                  <>
                    {searchMsg ? (
                      <p className="d-flex justify-content-center">
                        Hmm... We couldn't find any matches for "{searchMsgVal}"
                      </p>
                    ) : (
                      <p className="d-flex justify-content-center">
                        Nothing to show here yet
                      </p>
                    )}
                  </>
                )}
              </>
            ) : (
              <StudentLoading />
            )}
          </Row>
        </Container>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
        <Footer />
      </section>

      {qrModal && (
        <QrModal
          show={qrModal}
          hide={() => qrModalshow()}
          data={qrData}
          // allQrData={allQrCodes}
          allQrDatatype={allQrDatatype}
        />
      )}
      {editStudentmodal && (
        <EditStudent
          show={editStudentmodal}
          hide={() => editStudentshow({})}
          addRecord={() => {
            editRecord();
          }}
          classdata={allClasses}
          item={selectedStudent}
          schoolRecord={schoolRecord}
        />
      )}
      {deleteModal && (
        <DeleteModal
          show={deleteModal}
          hide={() => deleteModalshow()}
          screenName={Consts.student1}
          submit={() => {
            deleteRecord();
          }}
        />
      )}
    </>
  );
}

export default Student;
