import React, { Component } from "react";
import { Button } from 'react-bootstrap';
import Slider from "react-slick";
import {buttonSlider} from "../utils"

export default class ButtonSlider extends Component {
  render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 3,
      variableWidth: true
    };
    return (
      <div className="my-5">
        <Slider {...settings} >
            {buttonSlider.map((val,index)=>{
              return(
                <div>
                <Button variant="outline-blue me-4" className={val?.class}>{val?.name}</Button>
              </div>
              )
            })}
        </Slider>
      </div>
    );
  }
}

// import { React, useState } from 'react';
// import { Button } from 'react-bootstrap';

// function ButtonSlider() {
//   return (
//           <div className='my-2'>
//             <div className='button-slider scroll-x py-3 d-flex align-items-center gap-5 overflow-x-auto'>
                  
//                   <Button variant="outline-blue">All</Button>
//                   <Button variant="outline-blue" className='flex-none'>Teachers</Button>
//                   <Button variant="outline-blue" className='flex-none'>Managers</Button>
//                   <Button variant="outline-blue" className='flex-none'>Class</Button>
//                   <Button variant="outline-blue" className='flex-none'>Teachers</Button>
//                   <Button variant="outline-blue" className='flex-none'>Class</Button>
//             </div>
//           </div>
//       )
//     }
// export default ButtonSlider;