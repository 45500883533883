import { React, useEffect, useState } from "react";
import { Edit, Delete } from "../assets/images";
import {
  Row,
  Col,
  Container,
  Table,
  Button,
  Pagination,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
  Header,
  Subheader,
  Footer,
  AddModal,
  EditModal,
  DeleteModal,
} from "../components";
import { BsArrowRight } from "react-icons/bs";
import {
  deleteClassOfStudent,
  deleteClassOfTeacher,
  getAllClass,
  getAllSchoolCount,
  getAllSchoolNext,
  getAllSchoolNextSearch,
  getAllSchoolPrev,
  getAllSchoolPrevSearch,
  getAllSchoolSearch,
  getAllSchoolShow,
  getAllStaff,
  getAllStudentsBySchool,
  removeClasses,
  removeSchool,
  removeStaffRecord,
  removeStudentRecord,
} from "../firebase/service";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { TableLoadingPage } from "../components";
import { ToastContainer } from "react-toastify";

function School({ school }) {
  const navigate = useNavigate();

  const [show, setShow] = useState(false);
  const [editModal, setEditmodal] = useState(false);
  const [deleteModal, setDeletemodal] = useState(false);
  const [schoolRecord, setSchool] = useState([]);
  const [selectedSchool, setSelectedSchool] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchMsg, setSearchMsg] = useState(false);
  const [searchMsgVal, setSearchMsgVal] = useState("");
  const [allClasses, setAllClasses] = useState([]);
  const [staffRecord, setStaffRecord] = useState([]);
  const [page, setPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [schoolSnap, setSchoolSnap] = useState();
  const [schoolRecordLength, setSchoolRecordLength] = useState();
  const perPage = 10;

  //pagination
  let totalPage = Math.ceil(schoolRecordLength / itemsPerPage);
  let pagination = [],
    i = 1;
  while (i <= totalPage) {
    if (i <= 1 || i >= totalPage - 2 || (i >= page - 1 && i <= page + 1)) {
      pagination.push(i);
      i++;
    } else {
      pagination.push("...");
      //jump to the next page to be linked in the navigation
      i = i < page ? page - 1 : totalPage - 1;
    }
  }

  useEffect(() => {
    allSchoolCounts();
    getSchoolData();
    getClass();
    getStaffData();
  }, []);
  // all school count handal
  const allSchoolCounts = async () => {
    setSchoolRecordLength(await getAllSchoolCount());
  };
  // get all school
  const getSchoolData = async () => {
    const documentSnapshots = await getAllSchoolShow(itemsPerPage);
    setSchoolSnap(documentSnapshots);
    setupSchoolRecord(documentSnapshots);
  };
  const setupSchoolRecord = async (documentSnapshots) => {
    const record = [];
    documentSnapshots.forEach((document) => {
      record.push({
        uid: document.id,
        ...document.data(),
      });
    });
    if (record && record.length > 0) {
      setSchool(record);
      setLoading(true);
    } else {
      setLoading(true);
    }
  };
  // get all Staff
  const getStaffData = async () => {
    const myStudent = await getAllStaff();
    if (myStudent && myStudent.length > 0) {
      await setStaffRecord(myStudent);
    }
  };
  //get all class
  const getClass = async () => {
    const myClasses = await getAllClass();
    if (myClasses && myClasses.length > 0) {
      setAllClasses(myClasses);
    }
  };
  // add school modal show
  const addRecord = () => {
    setShow(!show);
    getSchoolData();
  };
  // school in all data delete or school delete start
  const deleteClasses = async () => {
    var allClass = allClasses.filter(
      (item) => item.school_id === selectedSchool?.uid
    );
    for (let index = 0; index < allClass.length; index++) {
      await deleteClassOfTeacher(false, "classId", allClass[index]?.uid);
      await deleteClassOfStudent(false, "classId", allClass[index]?.uid);
      await removeClasses(allClass[index]?.uid);
    }
  };
  const deleteStaffMembers = async () => {
    var allStaff = staffRecord.filter(
      (item) => item.school_id === selectedSchool?.uid
    );
    for (let index = 0; index < allStaff.length; index++) {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ uid: allStaff[index]?.uid }),
      };
      await removeStaffRecord(allStaff[index]?.uid);

      await fetch(
        `${process.env.REACT_APP_PUBLIC_API_URL}/delete-user`,
        requestOptions
      );
      deleteClassOfTeacher(false, "teacher_id", allStaff[index]?.uid);
    }
  };
  const deleteStudents = async () => {
    let allStudentData = await getAllStudentsBySchool(selectedSchool?.uid);
    for (let index = 0; index < allStudentData.length; index++) {
      deleteClassOfStudent(false, "student_id", allStudentData[index]?.uid);
      await removeStudentRecord(
        allStudentData[index]?.uid,
        allStudentData[index]?.class_id
      );
    }
  };
  const deleteRecord = async () => {
    deleteStudents();
    deleteClasses();
    deleteStaffMembers();
    const response = await removeSchool(selectedSchool?.uid);
    if (response) {
      var newAllClassStudents = schoolRecord.filter(
        (item) => item.uid !== selectedSchool.uid
      );
      setSchool(newAllClassStudents);
    }
    setDeletemodal(!deleteModal);
  };
  // school in all data delate or school delate end
  // edit school modal show
  const editRecord = async () => {
    setEditmodal(!editModal);
    getSchoolData();
  };

  const handleShow = () => {
    setShow(!show);
  };
  const editModalshow = (items) => {
    setSelectedSchool(items);
    setEditmodal(!editModal);
  };
  const deleteModalshow = (items) => {
    setSelectedSchool(items);
    setDeletemodal(!deleteModal);
  };
  // navigate schools details
  const handleDetails = (items) => {
    let url = items?.name.replace(/[&\\#,+()$~%.'":*?<>{}]/g, "");
    navigate(`/${url?.replaceAll(" ", "-").toLowerCase()}/${items?.code}`, {
      state: items,
    });
  };
  // search school
  const serchingHandal = async (val) => {
    setSearchMsg(true);
    setSearchMsgVal(val);
    if (val !== "") {
      const record = [];
      const documentSnapshots = await getAllSchoolSearch(itemsPerPage, val);
      setSchoolSnap(documentSnapshots);
      documentSnapshots.forEach((document) => {
        record.push({
          uid: document.id,
          ...document.data(),
        });
      });
      setSchoolRecordLength(record?.length);
      setupSchoolRecord(documentSnapshots);
    } else {
      allSchoolCounts();
      getSchoolData();
      setSearchMsgVal("");
    }
    // try {
    //   const record = searchTemp;
    //   if (val) {
    //     const searchArr = [];
    //     record
    //       .filter((s) => s?.name.toLowerCase().includes(val.toLowerCase()))
    //       .map((val) => {
    //         return searchArr.push(val);
    //       });
    //     setSchool(searchArr);
    //   } else {
    //     setSchool(record);
    //     setSearchMsg(false);
    //   }
    // } catch (error) {
    //   console.log(error);
    // }
  };
  // pagination next handal
  const nextHandal = async () => {
    let documentSnapshots = [];
    if (searchMsgVal !== "") {
      documentSnapshots = await getAllSchoolNextSearch(
        schoolSnap,
        itemsPerPage,
        searchMsgVal
      );
    } else {
      documentSnapshots = await getAllSchoolNext(schoolSnap, itemsPerPage);
    }
    if (documentSnapshots?.docs[documentSnapshots.docs.length - 1]) {
      setSchoolSnap(documentSnapshots);
    }
    setupSchoolRecord(documentSnapshots);
    setPage(page + 1);
  };
  // pagination prev handal
  const prevHandal = async () => {
    let documentSnapshots = [];
    if (searchMsgVal !== "") {
      documentSnapshots = await getAllSchoolPrevSearch(
        schoolSnap,
        itemsPerPage,
        searchMsgVal
      );
    } else {
      documentSnapshots = await getAllSchoolPrev(schoolSnap, itemsPerPage);
    }
    if (documentSnapshots?.docs[documentSnapshots.docs.length - 1]) {
      setSchoolSnap(documentSnapshots);
    }
    setupSchoolRecord(documentSnapshots);
    setPage(page - 1);
  };
  return (
    <>
      <section className="min-h-lg-screen vstack student">
        <Header />
        <Subheader
          school={school}
          serchingHandal={(e) => serchingHandal(e)}
          name={"hello"}
        />
        <Container>
          <Row className="mt-10">
            <Col lg={6} className="mb-7 mt-3">
              <p className="font-bold d-flex">
                {!loading ? (
                  <Skeleton width={100} />
                ) : (
                  `All schools(${schoolRecordLength})`
                )}
              </p>
            </Col>
            <Col lg={6} className="text-lg-end mb-7">
              <Button variant="jade" className="" onClick={handleShow}>
                Add School
              </Button>
            </Col>
            {loading ? (
              <>
                {schoolRecord && schoolRecord?.length > 0 ? (
                  <>
                    <Col sm={12} className="mb-8">
                      <Table responsive className="schooltable">
                        <thead className="text-tangaroa users">
                          <tr>
                            <th className="text-base font-800">#</th>
                            <th className="text-base font-800 text-capitalize">
                              Code
                            </th>
                            <th className="text-base font-800 text-capitalize">
                              Name
                            </th>
                          </tr>
                        </thead>
                        <tbody className="text-tangaroa">
                          {schoolRecord
                            // ?.slice(firstPageIndex, lastPageIndex)
                            .map((user, index) => {
                              return (
                                <tr key={index}>
                                  <td className="text-base font-800">
                                    {(page - 1) * perPage + index + 1}
                                  </td>
                                  <td className="font-regular text-base">
                                    {user?.code}
                                  </td>
                                  <td className="font-regular text-base">
                                    {user.name}
                                  </td>
                                  <td className="text-end d-flex justify-content-end pe-0">
                                    <a
                                      href={() => false}
                                      className="btn btn-transparent"
                                      onClick={() => editModalshow(user)}
                                    >
                                      <img
                                        src={Edit}
                                        className="text-sm"
                                        size={20}
                                        alt="Edit"
                                      />
                                    </a>
                                    <a
                                      href={() => false}
                                      className="btn btn-transparent"
                                      onClick={() => deleteModalshow(user)}
                                    >
                                      <img
                                        src={Delete}
                                        className="text-sm text-danger"
                                        size={20}
                                        alt="Delete"
                                      />
                                    </a>
                                    <a
                                      href={() => false}
                                      className="btn btn-transparent pe-0"
                                      onClick={() => {
                                        handleDetails(user);
                                      }}
                                    >
                                      <BsArrowRight
                                        className="text-sm"
                                        size={20}
                                      />
                                    </a>
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </Table>
                    </Col>
                    <Col className="d-flex justify-content-end mb-10 table-pagination">
                      <Pagination>
                        {page === 1 ? (
                          <Pagination.Item className="me-1 rounded-4" disabled>
                            Prev
                          </Pagination.Item>
                        ) : (
                          <Pagination.Item
                            className="me-1 rounded-4"
                            onClick={() => prevHandal()}
                          >
                            Prev
                          </Pagination.Item>
                        )}
                        {
                          // [...Array(totalPage + 1).keys()]
                          //   .slice(1)
                          pagination?.map((val) => {
                            return (
                              <>
                                {val === page ? (
                                  <Pagination.Item
                                    key={val}
                                    active={val === page}
                                  >
                                    {val === page ? val : ""}
                                  </Pagination.Item>
                                ) : (
                                  ""
                                )}
                              </>
                            );
                          })
                        }
                        {totalPage === page ? (
                          <Pagination.Item disabled>Next</Pagination.Item>
                        ) : (
                          <Pagination.Item onClick={() => nextHandal()}>
                            Next
                          </Pagination.Item>
                        )}
                      </Pagination>
                    </Col>
                  </>
                ) : (
                  <>
                    {searchMsg ? (
                      <p className="d-flex justify-content-center">
                        Hmm... We couldn't find any matches for "{searchMsgVal}"
                      </p>
                    ) : (
                      <p className="d-flex justify-content-center">
                        Nothing to show here yet
                      </p>
                    )}
                  </>
                )}
              </>
            ) : (
              <TableLoadingPage />
            )}
          </Row>
        </Container>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
        <Footer />
      </section>

      {show && (
        <AddModal
          show={show}
          hide={() => handleShow()}
          addRecord={() => {
            addRecord();
          }}
          schoolRecord={schoolRecord}
        />
      )}
      {editModal && (
        <EditModal
          show={editModal}
          hide={() => editModalshow({})}
          submit={() => {
            editRecord();
          }}
          item={selectedSchool}
        />
      )}
      {deleteModal && (
        <DeleteModal
          show={deleteModal}
          hide={() => deleteModalshow({})}
          screenName="school"
          submit={() => {
            deleteRecord();
          }}
        />
      )}
    </>
  );
}

export default School;
