export const emailVlidation = (value) => {
  if (value.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)) {
    return true
  }
  return false
}
export const phoneNumberVlidation = (value) => {
  if (value.match(/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/)) {
    return true
  }
  return false
}