import { React, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { addClassService, ClassesObjectServices } from "../firebase/service";

function Addclass({ hide, show, submit, schoolDetails }) {
  const { currentUserData } = useSelector((state) => state.AuthReducer);
  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});
  const [loadingBtn, setLoadingBtn] = useState(false);
  let allClass = {};
  const setFeild = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });
    if (!!errors[field])
      setErrors({
        ...errors,
        [field]: null,
      });
  };

  const validateForm = () => {
    const { name, code } = form;
    const newErrors = {};
    if (!code || code === "") {
      newErrors.code = "Please enter the class code";
    }
    if (!name || name === "") {
      newErrors.name = "Please enter the class name";
    } else if (!allClass) {
      newErrors.name = "class code or class name already used!";
    }
    return newErrors;
  };
  const addClass = async (e) => {
    e.preventDefault();
    setLoadingBtn(true);

    const formErrors = validateForm();
    if (form?.name && form?.code) {
      allClass = await ClassesObjectServices(form?.name, form?.code);
    }
    if (Object.keys(formErrors).length > 0) {
      const formErrors = validateForm();
      setErrors(formErrors);
      setLoadingBtn(false);
    } else {
      if (allClass) {
        let record = {
          class_name: form?.name,
          group_code: form?.code,
          showInDashboard: true,
          school_id: schoolDetails?.uid,
          admin_id: currentUserData?.uid,
          benchmarkGroups: false,
        };
        var classDoc_id = await addClassService(record);
        if (classDoc_id) {
          submit();
        }
      } else {
        const formErrors = validateForm();
        setErrors(formErrors);
        setLoadingBtn(false);
      }
    }
  };

  return (
    <Modal
      size="lg"
      setModalShow="true"
      show={show}
      onHide={hide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modalschool"
    >
      <Modal.Header closeButton className="border-none pb-0">
        <Modal.Title id="contained-modal-title-vcenter" className="text-xl">
          Add “Class”
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="py-7">
        <Form.Label className="text-tangaroa font-bold text-base">
          Code
        </Form.Label>
        <Form.Control
          type="text"
          placeholder="- Type here -"
          className="control mb-3"
          value={form?.code}
          isInvalid={!!errors?.code}
          onChange={(e) => setFeild("code", e.target.value)}
        />
        <Form.Control.Feedback type="invalid">
          {errors?.code}
        </Form.Control.Feedback>
        <Form.Label className="text-tangaroa font-bold text-base">
          Name
        </Form.Label>
        <Form.Control
          type="text"
          placeholder="- Type here -"
          className="control mb-3"
          value={form?.name}
          isInvalid={!!errors?.name}
          onChange={(e) => setFeild("name", e.target.value)}
        />
        <Form.Control.Feedback type="invalid">
          {errors?.name}
        </Form.Control.Feedback>
      </Modal.Body>
      <Modal.Footer className="border-none pt-0 justify-content-start pb-8">
        <Button
          variant={loadingBtn ? "disabled" : "jade"}
          className=" me-2 my-0 "
          onClick={(e) => addClass(e)}
          disabled={loadingBtn ? true : false}
        >
          Save
        </Button>
        <Button variant="outline-jade" className=" my-0 ms-0" onClick={hide}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
export default Addclass;
