import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import MainRouter from "./routes";
import "./assets/css/bootstrap/bootstrap.css";
import "./assets/scss/helper.scss";
import "./assets/scss/style.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Provider } from "react-redux";
import Store from "./Redux/store";

ReactDOM.render(
  <Provider store={Store}>
    <React.StrictMode>
      <Router>
        <MainRouter />
      </Router>
    </React.StrictMode>
  </Provider>,
  document.getElementById("root")
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
