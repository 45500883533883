import React from "react";
import { Routes, Route } from "react-router-dom";
import AuthenticatedRoute from "./AuthenticatedRoute";
import {
  LogIn,
  ForgotPassword,
  School,
  Student,
  Class,
  SchoolName,
  Splash,
  Info,
} from "../pages";
import { useSelector } from "react-redux";
const MainRouter = () => {
  const { isAuthenticated } = useSelector((state) => state.AuthReducer);

  return (
    <Routes>
      <Route
        exact
        path="/"
        element={isAuthenticated ? <School school={"Schools"} /> : <LogIn />}
      />

      <Route
        path="/schools"
        element={
          <AuthenticatedRoute>
            <School />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/:name/:id"
        element={
          <AuthenticatedRoute>
            <SchoolName />
          </AuthenticatedRoute>
        }
      />

      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route
        path="/class"
        element={
          <AuthenticatedRoute>
            <Class />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/students"
        element={
          <AuthenticatedRoute>
            <Student />
          </AuthenticatedRoute>
        }
      />
      {/* <Route
        path="/SchoolName"
        element={
          <AuthenticatedRoute>
            <SchoolName />
          </AuthenticatedRoute>
        }
      /> */}
      <Route path="/splash" element={<Splash />} />
      <Route path="/info" element={<Info />} />
      {/* <Route path="*" element={<Navigate to="/" />} /> */}
    </Routes>
  );
};

export default MainRouter;
