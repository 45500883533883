import { collection, getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { initializeApp } from "firebase/app";
import {
  getAuth,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  createUserWithEmailAndPassword,
} from "firebase/auth";
const firebaseConfig = {
  apiKey: "AIzaSyB86KjIaHkoharEbRv4ni0X9KQM_kgzeXQ",
  authDomain: "tangibles-1617c.firebaseapp.com",
  projectId: "tangibles-1617c",
  storageBucket: "tangibles-1617c.appspot.com",
  messagingSenderId: "5756989708",
  appId: "1:5756989708:web:1efa3cc3bebb05d450d929",
  measurementId: "G-WXY8T4BR8L",
};
const firebases = initializeApp(firebaseConfig);
const db = getFirestore(firebases);
const auth = getAuth(firebases);
const schoolCollection = collection(db, "schools");
const profileCollection = collection(db, "profile");
const classesCollection = collection(db, "classes");
const studentsCollection = collection(db, "students");
const studentPointsCollection = collection(db, "student_points");
const teacherClassCollection = collection(db, "teacher_class"); // stored all teacehr classes
// const db = firebase.firestore();
const storage = getStorage(firebases);
export {
  firebases,
  db,
  auth,
  profileCollection,
  classesCollection,
  studentsCollection,
  studentPointsCollection,
  schoolCollection,
  storage,
  teacherClassCollection,
};
export const logInWithEmailAndPassword = async (email, password) => {
  try {
    let resp = await signInWithEmailAndPassword(auth, email, password);
    localStorage.setItem("token", resp?._tokenResponse?.idToken);
    return { success: true, response: resp };
  } catch (err) {
    console.error(err);
    var errorCode = err.code;
    console.error(errorCode);
    return { success: false, err: err.message };
  }
};
export const signOutAuth = async (email, password) => {
  try {
    let resp = await signOut(auth);
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};
export const forgotPasswordApi = async (email) => {
  try {
    return sendPasswordResetEmail(auth, email)
      .then(() => {
        return true;
      })
      .catch((error) => console.log(error.message));
  } catch (err) {
    console.error(err);
  }
};

export const signupApi = async (email, password) => {
  try {
    let resp = await createUserWithEmailAndPassword(auth, email, password);
    return { success: true, response: resp };
  } catch (err) {
    return { success: false, err: err.message };
  }
};
