import React, { useState, useEffect } from "react";
import { Row, Col, Card, Form, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { getLogin, setLoginData } from "../Redux/action/action.js";
import { useNavigate } from "react-router-dom";
import { logInWithEmailAndPassword } from "../firebase";
import { Splash } from "./index";
import { getCurrentUser } from "../firebase/service.js";
import { Consts } from "../utils/Constant.js";
import { emailVlidation } from "../utils/validation"

const initialvalue = {
  email: "",
  password: "",
};

function LogIn() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [error, setError] = useState({});
  const [user, setUser] = useState(initialvalue);
  const [loadingBtn, setLoadingBtn] = useState(false);

  const [welComePageShow, setWelComePageShow] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setWelComePageShow(false);
    }, 1500);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setError((user[name] = ""));
    setUser({ ...user, [name]: value });
  };

  const validate = (values) => {
    var errors = {},
      valid = true;
    if (!values.email) {
      errors.email = "Please enter your email";
      valid = false;
    } else if (
      !emailVlidation(values.email)
    ) {
      errors.email = "Please enter a valid e-mail address";
      valid = false;
    }

    // if (!values.password) {
    //   errors.password = "Plese Enter Password";
    // } else if (
    //   !values.password.match(
    //     /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
    //   )
    // ) {
    //   errors.password =
    //     "Please Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character";
    // }
    if (!values.password) {
      errors.password = "Please enter your password";
      valid = false;
    } else if (values.password.length < 6) {
      errors.password = "Enter minimum six character of password";
      valid = false;
    }
    return { errors, valid };
  };
  const navigateHome = async (e) => {
    e.preventDefault();
    var { errors, valid } = await validate(user);
    setError(errors);
    if (valid) {
      setLoadingBtn(true);
      let resUser = await logInWithEmailAndPassword(user.email, user.password);
      if (resUser?.response?.user?.uid) {
        var res = resUser?.response;

        var currentUser = await getCurrentUser(res?.user.uid);
        if (currentUser && currentUser?.role === Consts.Admin) {
          dispatch(getLogin());
          await dispatch(setLoginData(res, currentUser, "login"));
          navigate("/schools");
        } else {
          alert("You are not valid for login");
        }
      } else {
        if (!resUser.success) {
          e.preventDefault();
          var error = {};
          if (resUser.err === "Firebase: Error (auth/user-not-found).") {
            error.email = "User not found";
          } else {
            error.password = "Wrong password";
          }
          setError(error);
          setLoadingBtn(false);
        }
      }
    }
  };

  const forgotPassword = () => {
    navigate("/forgot-password");
  };
  return (
    <>
      {welComePageShow ? (
        <Splash />
      ) : (
        <div className="p-5 p-lg-0 min-h-lg-screen bg-blue-white d-flex flex-column justify-content-center">
          <div className="d-flex justify-content-center">
            <Col
              lg={6}
              md={9}
              className="min-h-lg-screen py-lg-32 px-lg-20 pt-16 pb-8 position-relative"
            >
              <Row>
                <Col lg={10} md={9} className="mx-auto">
                  <h1 className="text-center text-white ff-circular-bold mb-20">
                    Tangibles
                  </h1>
                  <Card className="shadow-a p-10">
                    <Card.Header className="px-0 pt-0 border-bottom">
                      <h3>Hi there!</h3>
                    </Card.Header>
                    <Card.Body className="px-0 pb-0">
                      <Form>
                        <Form.Group className="mb-6">
                          <Form.Label>Email</Form.Label>
                          <Form.Control
                            type="email"
                            name="email"
                            className="text-base"
                            placeholder="- Type here -"
                            value={user.email}
                            onChange={(e) => handleChange(e)}
                            isInvalid={error.email}
                            isValid={user.email}
                          />
                          <Form.Control.Feedback type="invalid">
                            {error.email}
                          </Form.Control.Feedback>
                          <Form.Text className="text-muted d-none">
                            We'll never share your email with anyone else.
                          </Form.Text>
                        </Form.Group>
                        <Form.Group className="mb-3 text-base">
                          <Form.Label>Password</Form.Label>
                          <Form.Control
                            type="password"
                            name="password"
                            className="text-base"
                            placeholder="************"
                            value={user.password}
                            onChange={(e) => handleChange(e)}
                            isInvalid={error.password}
                            isValid={user.password}
                          />
                          <Form.Control.Feedback type="invalid">
                            {error.password}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <div className="d-block mb-8  text-sm hov">
                          <a
                            href={() => false}
                            onClick={forgotPassword}
                            className="border-bottom hov border-dark lh-loose text-sm"
                          >
                            Forgot my password
                          </a>
                        </div>
                        <Button
                          variant={loadingBtn ? "disabled" : "jade"}
                          type="submit"
                          onClick={(e) => navigateHome(e)}
                          disabled={loadingBtn ? true : false}
                        >
                          Login
                        </Button>
                      </Form>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Col>
          </div>
        </div>
      )}
    </>
  );
}
export default LogIn;
