import React from "react";
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Consts } from "../utils/Constant";

function DeleteModal({ hide, show, submit, screenName, selectRecord }) {
  const [loadingBtn, setLoadingBtn] = useState(false);
  return (
    <Modal
      size="lg"
      setModalShow="true"
      show={show}
      onHide={hide}
      screenName={screenName}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modalschool"
    >
      <Modal.Header closeButton className="border-none pb-0">
        <Modal.Title id="contained-modal-title-vcenter" className="text-xl">
          Delete "
          {screenName === Consts.School1
            ? "School"
            : screenName === Consts.student1
            ? "Student"
            : screenName === Consts.class
            ? "Class"
            : "Staff"}
          "
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="pt-1">
        {screenName === "staff" ? (
          <p className="text-tangaroa font-bold">
            Are you sure you want to delete {selectRecord?.name}{" "}
            {selectRecord?.last_name}?
          </p>
        ) : screenName === "Class" ? (
          <p className="text-tangaroa font-bold">
            Are you sure you want to delete {selectRecord?.class_name} (
            {selectRecord?.group_code})?
          </p>
        ) : (
          <p className="text-tangaroa font-bold">
            Are you sure you want to delete {screenName}?
          </p>
        )}
      </Modal.Body>
      <Modal.Footer className="border-none pt-0 pb-8 justify-content-start ">
        <Button
          variant={loadingBtn ? "disabled" : "sunorange"}
          className="me-2 my-0 "
          onClick={() => {
            submit();
            setLoadingBtn(true);
          }}
          disabled={loadingBtn ? true : false}
        >
          Yes, delete
        </Button>
        <Button variant="outline-jade" className=" my-0 mx-0" onClick={hide}>
          No, cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default DeleteModal;
