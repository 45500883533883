import { useState } from "react";
import { Row, Col, Card, Form, Button } from "react-bootstrap";
import { BsArrowLeftShort } from "react-icons/bs";
import { forgotPasswordApi } from "../firebase";
import { Toasts } from "../components";
import { ToastContainer } from "react-toastify";
import { getAdmin } from "../firebase/service";
import { useNavigate } from "react-router-dom";
import { emailVlidation } from "../utils/validation"

function ForgotPassword() {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({});
  const [loadingBtn, setLoadingBtn] = useState(false);

  const validateForm = () => {
    const newErrors = {};
    if (!email) {
      newErrors.email = "Please enter your email";
    } else if (
      !emailVlidation(email)
    ) {
      newErrors.email = "Please enter a valid e-mail address";
    }
    return newErrors;
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setLoadingBtn(false);
      setErrors(formErrors);
      validateForm();
    } else {
      setLoadingBtn(true);
      var currentUser = await getAdmin(email);
      if (currentUser?.role === "admin") {
        let res = await forgotPasswordApi(email);
        if (res) {
          setEmail("");
          Toasts("Look for our message in your inbox.", "success");
        } else {
        }
      } else {
        Toasts("Something went wrong. Please, try again.", "error");
        const newErrors = {};
        newErrors.email =
          "Uh-oh, this email was not found in our records. Please try again.";
        setErrors(newErrors);
        setLoadingBtn(false);
      }
    }
  };
  return (
    <div className="p-5 p-lg-0 min-h-lg-screen bg-blue-white d-flex flex-column justify-content-center text-2xl">
      <div className="d-flex justify-content-center">
        <Col
          lg={6}
          md={9}
          className="min-h-lg-screen py-lg-32 px-lg-20 pt-16 pb-8 position-relative"
        >
          <Row>
            <Col lg={10} md={9} className="mx-auto">
              <h1 className="text-center text-white ff-circular-bold mb-20">
                Tangibles
              </h1>
              <Card className="shadow-a p-10">
                <Card.Header className="px-0 pt-0 border-bottom">
                  <a href={() => false} onClick={() => navigate("/")}>
                    <h3>
                      <BsArrowLeftShort className="me-1 text-2xl" /> Forgot your
                      password
                    </h3>
                  </a>
                </Card.Header>
                <Card.Body className="px-0 pb-0">
                  <p className="text-sm text-dark mb-6">
                    Reset your password in two quick steps
                  </p>
                  <Form>
                    <Form.Group className="mb-8">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="- Type here -"
                        className="text-base"
                        value={email}
                        isInvalid={!!errors.email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                          setErrors({});
                        }}
                      />
                      <Form.Control.Feedback
                        type="invalid"
                        style={{ fontSize: "16px" }}
                      >
                        {errors.email}
                      </Form.Control.Feedback>
                      {/* <Form.Text className="text-muted d-none">
                        We'll never share your email with anyone else.
                      </Form.Text> */}
                    </Form.Group>
                    <Button
                      variant={loadingBtn ? "disabled" : "success"}
                      type="submit"
                      onClick={(e) => onSubmit(e)}
                      disabled={loadingBtn ? true : false}
                    >
                      Reset password
                    </Button>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </div>
  );
}
export default ForgotPassword;
