import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { getLogout } from "../Redux/action/action";

function LogoutModal({ hide, show }) {
  const dispatch = useDispatch();
  const logout = () => {
    dispatch(getLogout());
    localStorage.clear();
  };
  return (
    <Modal
      size="lg"
      setModalShow="true"
      show={show}
      onHide={hide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modalschool"
    >
      <Modal.Header closeButton className="border-none pb-0">
        <Modal.Title id="contained-modal-title-vcenter" className="text-xl">
          Logout
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="pt-1">Are you sure you want to logout?</Modal.Body>
      <Modal.Footer className="border-none pt-0 justify-content-start pb-8">
        <Button
          variant="sunorange"
          className="me-2 my-0 "
          onClick={() => logout()}
        >
          Yes, logout
        </Button>
        <Button variant="outline-jade" className=" my-0 mx-0" onClick={hide}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default LogoutModal;
