import { React, useState } from "react";
import { Button, Form, Modal, Container, Row, Col } from "react-bootstrap";
import { Check2, X } from "react-bootstrap-icons";
import { addSchoolService } from "../firebase/service";
import { addSchoolCol1, provinceOptions } from "../utils";
import { Toasts } from "../components";
import { ToastContainer } from "react-toastify";
import MaskedFormControl from "react-bootstrap-maskedinput";
import { emailVlidation, phoneNumberVlidation } from "../utils/validation";

function AddModal({ hide, show, addRecord, schoolRecord }) {
  const [form, setForm] = useState({
    province: "Alberta",
    type: "Elementary school",
  });
  const [errors, setErrors] = useState({});
  const [errorsCheck, setErrorsCheck] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [addCategoryGive, setAddCategoryGive] = useState(false);
  const [addCategoryTrade, setAddCategoryTrade] = useState(false);
  const [addFileds, setAddFileds] = useState();
  const [give, setGive] = useState([
    {
      label: "Respect",
      name: "respect",
      amount: null,
      error: "",
    },
    {
      label: "Responsibility",
      name: "responsbility",
      amount: null,
      error: "",
    },

    {
      label: "Success",
      name: "success",
      amount: null,
      error: "",
    },
  ]);
  const [trade, setTrade] = useState([
    {
      label: "Listen to music",
      name: "listenToMusic",
      amount: null,
      error: "",
    },
    {
      label: "Eat a snack",
      name: "eatASnack",
      amount: null,
      error: "",
    },
    {
      label: "Wear a cap",
      name: "wearACap",
      amount: null,
      error: "",
    },
  ]);
  let allErr = {};
  const validateForm = () => {
    const {
      address,
      city,
      email,
      name,
      postalCode,
      phoneNumber,
      totalPoint,
      code,
    } = form;
    const newErrors = {};
    if (!code || code === "") newErrors.code = " Please enter your code";
    else if (!code.match(/^[0-9]{6}$/))
      newErrors.code = " Please enter valid code";
    if (!name || name === "") newErrors.name = " Please enter your name";
    if (!email) {
      newErrors.email = "Please enter your email";
    } else if (!emailVlidation(email)) {
      newErrors.email = "Please enter your email";
    }
    if (!phoneNumber) {
      newErrors.phoneNumber = "Please enter your phone number";
    } else if (!phoneNumberVlidation(phoneNumber)) {
      newErrors.phoneNumber = "Please enter a valid Phone Number";
    }
    if (!address || address === "")
      newErrors.address = "Please enter your address";
    if (!city || city === "") newErrors.city = "Please enter your city";
    if (!postalCode || postalCode === "")
      newErrors.postalCode = "Please enter your postal code";
    if (!totalPoint || totalPoint === "")
      newErrors.totalPoint = "Please enter total point";
    return newErrors;
  };
  const setFeild = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });
    if (!!errors[field])
      setErrors({
        ...errors,
        [field]: null,
      });
  };
  const setFeildCategory = (e, idx, val, type) => {
    if (type === "give") {
      let clone = [...val];
      let obj = clone[idx];
      obj.amount = e.target.value < 0 ? (e.target.value = 1) : e.target.value;
      obj.error = "";
      setGive([...clone]);
    } else {
      let clone = [...val];
      let obj = clone[idx];
      obj.amount = e.target.value < 0 ? (e.target.value = 1) : e.target.value;
      obj.error = "";
      setTrade([...clone]);
    }
  };
  const giveError = () => {
    for (let index = 0; index < give.length; index++) {
      let obj = give[index];
      if (!obj.amount || obj.amount === "") {
        setErrorsCheck(false);
        let clone = [...give];
        let temp = clone[index];
        temp.error = `Please enter ${obj.label.toLocaleLowerCase()}`;
        setGive([...clone]);
      }
    }
  };
  const tradeError = () => {
    for (let index = 0; index < trade.length; index++) {
      let obj = trade[index];
      if (!obj.amount || obj.amount === "") {
        setErrorsCheck(false);
        let clone = [...trade];
        let temp = clone[index];
        temp.error = `Please enter ${obj.label.toLocaleLowerCase()}`;
        setTrade([...clone]);
      }
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    giveError();
    tradeError();
    const formErrors = validateForm();
    let giveArray = [];
    let tradeArray = [];

    give.map((val) => {
      return giveArray.push({
        name: val?.label,
        amount: Number(val?.amount) || 0,
        isSelect: false,
        key: val?.name,
      });
    });
    trade.map((val) => {
      return tradeArray.push({
        name: val?.label,
        amount: Number(val?.amount) || 0,
        isSelect: false,
        key: val?.name,
      });
    });
    if (Object.keys(formErrors).length > 0 && !errorsCheck) {
      setErrors(formErrors);
      setLoadingBtn(false);
    } else {
      allErr = schoolRecord?.filter((item) => item?.code === form?.code);
      if (allErr.length === 0) {
        setLoadingBtn(true);
        let params = {
          address: form?.address,
          city: form?.city,
          code: form?.code,
          email: form?.email,
          schoolType: form?.type,
          name: form?.name,
          postalCode: form?.postalCode,
          phoneNumber: form?.phoneNumber,
          province: form?.province || 1,
          give: giveArray,
          trade: tradeArray,
          totalPoint: Number(form?.totalPoint),
        };
        let resId = await addSchoolService(params);
        if (resId) {
          Toasts("New school added successfully", "success");
          setTimeout(() => {
            addRecord();
          }, 4000);
        } else {
          Toasts("We couldn't add this member. Please, try again.", "error");
        }
      } else {
        setLoadingBtn(false);
        Toasts("Oops! This code is already used.", "error");
        setErrors(formErrors);
      }
    }
  };
  const addCategoryShow = (val) => {
    if (val === "give") {
      setAddCategoryGive(true);
    } else {
      setAddCategoryTrade(true);
    }
  };
  const addCategoryHandal = (val) => {
    if (val === "give") {
      let obj = {
        label: addFileds.charAt(0).toUpperCase() + addFileds.slice(1),
        name: addFileds.toLowerCase(),
        amount: null,
        error: "",
      };
      setAddFileds();
      setGive([...give, obj]);
      setAddCategoryGive(false);
    } else {
      let obj = {
        label: addFileds.charAt(0).toUpperCase() + addFileds.slice(1),
        name: addFileds.toLowerCase(),
        amount: null,
        error: "",
      };
      setAddFileds();
      setTrade([...trade, obj]);
      setAddCategoryTrade(false);
    }
  };
  const removeCategory = (val, type) => {
    let data = [];
    if (type === "give") {
      data = give.filter((a) => a.name !== val.name); //trade.splice(index,1)
      setGive(data);
      data = [];
    } else {
      data = trade.filter((a) => a.name !== val.name); //trade.splice(index,1)
      setTrade(data);
      data = [];
    }
  };
  return (
    <Modal
      size="lg"
      setModalShow="true"
      show={show}
      onHide={hide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modalschool modal2"
    >
      <Modal.Header closeButton className="border-none pb-0 hstack flex-wrap">
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="text-xl flex-1"
        >
          Add “School”
        </Modal.Title>
        <div className="hstack">
          <Form.Group controlId="code" className="hstack position-relative">
            <Form.Label className="text-tangaroa font-bold text-base me-3 mb-0 pt-0">
              Code
            </Form.Label>
            <MaskedFormControl
              type="text"
              id="code"
              value={form.code}
              placeholder="-000000-"
              onChange={(e) => setFeild("code", e.target.value)}
              aria-describedby="passwordHelpBlock"
              className="control py-2 w-32 text-base"
              isInvalid={!!errors.code}
              mask="111111"
            />
            <Form.Control.Feedback
              type="invalid"
              className="position-absolute bottom-0 mb-n7"
            >
              {errors.code}
            </Form.Control.Feedback>
          </Form.Group>
        </div>
      </Modal.Header>
      <Modal.Body className="py-7 px-0">
        <Container>
          <Row>
            {addSchoolCol1.map((val, index) => {
              return (
                <>
                  <Col lg={val?.col} key={index}>
                    {val?.label === "Type" ? (
                      <Form.Group controlId={val?.name}>
                        <Form.Label className="text-tangaroa font-bold text-base">
                          {val?.label}
                        </Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          required="1"
                          className="control mb-2"
                          value={form.val?.name}
                          onChange={(e) => setFeild(val?.name, e.target.value)}
                        >
                          <option selected value="Elementary school">
                            Elementary school
                          </option>
                          <option value="High school">High school</option>
                        </Form.Select>
                      </Form.Group>
                    ) : val?.label === "Province" ? (
                      <Form.Group controlId={val?.name}>
                        <Form.Label className="text-tangaroa font-bold text-base">
                          {val?.label}
                        </Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          required="1"
                          className="control mb-2"
                          value={form.val?.name}
                          onChange={(e) => setFeild(val?.name, e.target.value)}
                        >
                          <option selected value="Alberta">
                            Alberta
                          </option>
                          {provinceOptions.map((val) => {
                            return (
                              <option value={val?.name}>{val?.name}</option>
                            );
                          })}
                        </Form.Select>
                      </Form.Group>
                    ) : val?.label === "Phone Number" ||
                      val?.label === "Postal Code" ? (
                      <Form.Group controlId={val?.name}>
                        <Form.Label className="text-tangaroa font-bold text-base">
                          {val?.label}
                        </Form.Label>
                        <MaskedFormControl
                          type={val?.type}
                          placeholder={val?.placeholder}
                          value={form.val?.name}
                          onChange={(e) => setFeild(val?.name, e.target.value)}
                          isInvalid={!!errors[val?.name]}
                          className="control mb-2"
                          mask={
                            val?.label === "Phone Number"
                              ? "(111) 111-1111"
                              : "*** ***"
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors[val?.name]}
                        </Form.Control.Feedback>
                      </Form.Group>
                    ) : (
                      <Form.Group controlId={val?.name}>
                        <Form.Label className="text-tangaroa font-bold text-base">
                          {val?.label}
                        </Form.Label>
                        <Form.Control
                          type={val?.type}
                          placeholder={val?.placeholder}
                          value={form.val?.name}
                          onChange={(e) => setFeild(val?.name, e.target.value)}
                          isInvalid={!!errors[val?.name]}
                          className="control mb-2"
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors[val?.name]}
                        </Form.Control.Feedback>
                      </Form.Group>
                    )}
                  </Col>
                </>
              );
            })}
          </Row>
        </Container>
        <div className="bg-whiteshade px-0 py-5 mt-10">
          <Container>
            <Row>
              <Col lg={6}>
                <div className="hstack mb-7 flex-wrap d-flex justify-content-between me-2">
                  <p className="font-bold text-tangaroa me-2">Give</p>
                  <Button
                    variant="jade"
                    onClick={() => addCategoryShow("give")}
                    className="py-2"
                  >
                    Add
                  </Button>
                </div>
                {addCategoryGive && (
                  <div className="position-relative">
                    <Form.Control
                      type="text"
                      placeholder="- Type here -"
                      value={addFileds}
                      onChange={(e) => setAddFileds(e.target.value)}
                      className="add-control mb-2 rounded-0 pe-10 shadow-none ps-0"
                    />
                    <Check2
                      className="text-xl font-bold position-absolute end-10 bottom-2 text-green"
                      onClick={() => addCategoryHandal("give")}
                    ></Check2>
                    <X
                      className="text-xl font-bold position-absolute end-2 bottom-2 text-danger"
                      onClick={() => setAddCategoryGive(false)}
                    ></X>
                  </div>
                )}
                <div className="catagory-scroll">
                  {give.map((val, index) => {
                    return (
                      <>
                        <Col lg={12} key={index}>
                          <div className="max-h-88 overflow-y-auto">
                            <div className="border-top hstack py-5 flex-wrap position-relative">
                              <p className="text-sm text-tangaroa flex-1 me-2 accordion-card mb-0">
                                {val?.label}
                              </p>
                              <p className="text-sm text-tangaroa me-3 mb-0">
                                Value
                              </p>
                              <Form.Control
                                type="number"
                                placeholder="XX"
                                value={val?.amount}
                                onChange={
                                  (e) =>
                                    setFeildCategory(e, index, give, "give")
                                }
                                isInvalid={!!val?.error}
                                className="control w-16 px-3 me-2 text-center"
                              />
                              <Form.Control.Feedback
                                type="invalid"
                                className="position-absolute top-14 mb-2"
                              >
                                {val?.error}
                              </Form.Control.Feedback>
                              <X
                                className="text-xl font-bold"
                                onClick={() => removeCategory(val, "give")}
                              ></X>
                            </div>
                          </div>
                        </Col>
                      </>
                    );
                  })}
                </div>
              </Col>
              <Col lg={6}>
                <div className="hstack mb-7 flex-wrap d-flex justify-content-between me-2">
                  <p className="font-bold text-tangaroa me-2">Trade</p>
                  <Button
                    variant="jade"
                    onClick={() => addCategoryShow("trade")}
                    className="py-2"
                  >
                    Add
                  </Button>
                </div>
                {addCategoryTrade && (
                  <div className="position-relative">
                    <Form.Control
                      type="text"
                      placeholder="- Type here -"
                      value={addFileds}
                      onChange={(e) => setAddFileds(e.target.value)}
                      className="add-control mb-2 rounded-0 pe-10 shadow-none ps-0"
                    />
                    <Check2
                      className="text-xl font-bold position-absolute end-10 bottom-2 text-green"
                      onClick={() => addCategoryHandal("trade")}
                    ></Check2>
                    <X
                      className="text-xl font-bold position-absolute end-2 bottom-2 text-danger"
                      onClick={() => setAddCategoryTrade(false)}
                    ></X>
                  </div>
                )}
                <div className="catagory-scroll">
                  {trade.map((val, index) => {
                    return (
                      <>
                        <Col lg={12}>
                          <div className="max-h-88 overflow-y-auto">
                            <div className="border-top hstack py-5 flex-wrap position-relative">
                              <p className="text-sm text-tangaroa flex-1 me-2 mb-0">
                                {val?.label}
                              </p>
                              <p className="text-sm text-tangaroa me-3 mb-0">
                                Value
                              </p>
                              <Form.Control
                                type="number"
                                placeholder="XX"
                                value={form.val?.name}
                                onChange={
                                  (e) =>
                                    setFeildCategory(e, index, trade, "trade")
                                }
                                isInvalid={!!val?.error}
                                className="control w-16 px-3 me-2 text-center"
                                max={0}
                              />
                              <Form.Control.Feedback
                                type="invalid"
                                className="position-absolute top-14 mb-2"
                              >
                                {val?.error}
                              </Form.Control.Feedback>
                              <X
                                className="text-xl font-bold"
                                onClick={() => removeCategory(val, "trade")}
                              ></X>
                            </div>
                          </div>
                        </Col>
                      </>
                    );
                  })}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="hstack flex-wrap mt-8 px-5">
          <p className="font-bold text-tangaroa me-4 mb-0">
            Distribution pts goal / Month
          </p>
          <Form.Control
            type="number"
            placeholder="XX"
            value={form.totalPoint}
            onChange={(e) =>
              setFeild(
                "totalPoint",
                e.target.value < 0 ? (e.target.value = 0) : e.target.value
              )
            }
            isInvalid={!!errors.totalPoint}
            className="control w-16 px-3 me-2 text-center"
          />
          <Form.Control.Feedback type="invalid">
            {errors.totalPoint}
          </Form.Control.Feedback>
        </div>
      </Modal.Body>
      <Modal.Footer className="border-none pt-0 justify-content-start pb-8">
        <Button
          variant={loadingBtn ? "disabled" : "jade"}
          onClick={handleSubmit}
          className="me-2 my-0 d-flex"
          disabled={loadingBtn ? true : false}
        >
          Add
        </Button>
        <Button variant="outline-jade" className=" my-0 ms-0" onClick={hide}>
          Cancel
        </Button>
      </Modal.Footer>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </Modal>
  );
}

export default AddModal;
