import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import {
  addStudentClass,
  deleteClassOfStudent,
  updateStudentSerive,
} from "../firebase/service.js";
import { Consts } from "../utils/Constant.js";
import { addStudents, levelOptions } from "../utils";
import { OptionsOutsideSelect, Toasts } from "../components";
import { ToastContainer } from "react-toastify";

function EditStudent({ hide, show, classdata, addRecord, item, schoolRecord }) {
  const { currentUserData } = useSelector((state) => state.AuthReducer);
  const [form, setForm] = useState(item);
  const [classes, setClass] = useState([]);
  const [classesDb, setClassDb] = useState([]);
  const [errors, setErrors] = useState({});
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [schoolWiseClass, setschoolWiseClass] = useState([]);

  useEffect(() => {
    setData();
  }, []);
  const setData = async () => {
    if (item?.class_data && item?.class_data.length > 0) {
      let tmpClass = [];
      for (let i = 0; i < item?.class_data.length; i++) {
        let obj = {
          value: item?.class_data[i].classId,
          label:
            item?.class_data[i].classDetails.class_name +
            `(${item?.class_data[i].classDetails.group_code})`,
        };
        tmpClass.push(obj);
      }
      setClass(tmpClass);
      setClassDb(tmpClass);
    }
    let schoolId = schoolRecord.filter(
      (item) => item?.name === form?.school_name
    );
    let allClass = classdata.filter(
      (val) => val?.school_id === schoolId[0]?.uid
    );
    setschoolWiseClass(allClass);
  };
  const editStudent = async () => {
    setLoadingBtn(true);
    const { name, last_name, individual_code, level, school_name } = form;
    if (name && last_name && individual_code && school_name) {
      let classRecord = [];

      for (let i = 0; i < classes.length; i++) {
        let obj = {
          classId: classes[i].value,
        };
        classRecord.push(obj);
      }
      let uniqeId = schoolRecord?.filter((s) => s?.name === school_name);
      let studentRecord = {
        email: "",
        name: name,
        last_name: last_name,
        level: level || "ADA",
        role: Consts.Role,
        individual_code: individual_code,
        class: classRecord,
        school_name: school_name,
        school_id: uniqeId[0]?.uid,
        school_code: uniqeId[0]?.code,
      };
      await updateStudentSerive(item.uid, studentRecord);
      for (let i = 0; i < classesDb.length; i++) {
        const filteredArray = classes.findIndex(
          (e) => e.value === classesDb[i].value
        );
        if (filteredArray === -1) {
          deleteClassOfStudent(
            true,
            "classId",
            classesDb[i].value,
            "student_id",
            item.uid
          );
        } else {
        }
      }
      for (let j = 0; j < classes.length; j++) {
        const filteredArray2 = classesDb.findIndex(
          (e) => e.value === classes[j].value
        );

        if (filteredArray2 === -1) {
          let teacherParam = {
            classId: classes[j].value,
            student_id: item?.uid,
            school_id: uniqeId[0]?.uid,
            showInDashboard: true,
            admin_id: currentUserData?.uid,
          };
          addStudentClass(teacherParam);
        }
      }
      // if (item.class_id) {
      //   let studentClass = {
      //     class_data: classRecord,
      //   };

      //   let classID = await updateClassByStudent(item.class_id, studentClass);
      // } else {
      //   let studentClass = {
      //     uid: item.uid,
      //     class_data: classRecord,
      //   };

      //   let classID = await addStudentClass(studentClass);
      // }
      Toasts("student's data updated successfully", "success");
      addRecord();
    } else {
      const formErrors = validateForm();
      setErrors(formErrors);
      validateForm();
      setLoadingBtn(false);
    }
  };
  const validateForm = () => {
    const { name, last_name, individual_code, school_name } = form;

    const newErrors = {};
    if (!name || name === "")
      newErrors.fname = "Please enter student's first name";
    if (!last_name || last_name === "")
      newErrors.lname = "Please enter student's last name";
    if (!individual_code || individual_code === "")
      newErrors.individualCode = "Please enter student's individual code";
    if (!classes || classes === "")
      newErrors.classes = " please Select Your Class";
    // if (!level || level === "")
    //   newErrors.level = "Please enter student's level";
    if (!school_name || school_name === "")
      newErrors.school_name = "Please select the school";
    return newErrors;
  };

  const setFeild = (field, value) => {
    if (field === "school_name") {
      setClass([]);
      let schoolId = schoolRecord.filter((item) => item?.name === value);
      let allClass = classdata.filter(
        (val) => val?.school_id === schoolId[0]?.uid
      );
      setschoolWiseClass(allClass);
    }
    setForm({
      ...form,
      [field]: value,
    });
    if (!!errors[field])
      setErrors({
        ...errors,
        [field]: null,
      });
  };

  const handleClassChange = (selected) => {
    setClass(selected);
  };
  return (
    <Modal
      setModalShow="true"
      show={show}
      onHide={hide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modalschool modalstudent"
    >
      <Modal.Header closeButton className="border-none pb-0">
        <Modal.Title id="contained-modal-title-vcenter" className="text-xl">
          Edit Student
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="py-7">
        <Row>
          {addStudents.map((val, index) => {
            return (
              <>
                <Col lg={val?.col} key={index}>
                  {val?.label === "Class" ? (
                    <Form.Group controlId="classes" className="mt-3">
                      <Form.Label className="text-tangaroa font-bold text-base ">
                        Class
                      </Form.Label>
                      <OptionsOutsideSelect
                        onChange={handleClassChange}
                        isMulti
                        // options={classdata}
                        options={schoolWiseClass}
                        value={classes}
                      />
                    </Form.Group>
                  ) : val?.label === "Level" ? (
                    <Form.Group controlId="type" className="mt-3">
                      <Form.Label className="text-tangaroa font-bold text-base">
                        {val?.label}
                      </Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        required="1"
                        className="control mb-2"
                        value={form[val?.name]}
                        onChange={(e) =>
                          setFeild(val?.editName, e.target.value)
                        }
                      >
                        {levelOptions.map((val) => {
                          return <option value={val?.name}>{val?.name}</option>;
                        })}
                      </Form.Select>
                    </Form.Group>
                  ) : val?.label === "School" ? (
                    <Form.Group controlId="type" className="mt-3">
                      <Form.Label className="text-tangaroa font-bold text-base">
                        {val?.label}
                      </Form.Label>
                      <Form.Select
                        aria-label="Default select example "
                        required="1"
                        className="control mb-2"
                        value={form[val?.name]}
                        onChange={(e) => setFeild(val?.name, e.target.value)}
                        isInvalid={!!errors[val?.name]}
                      >
                        <option key="blankChoice" hidden value>
                          - Select school -
                        </option>
                        {schoolRecord.map((val) => {
                          return <option value={val?.name}>{val?.name}</option>;
                        })}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        {errors[val?.name]}
                      </Form.Control.Feedback>
                    </Form.Group>
                  ) : (
                    <Form.Group
                      controlId={val?.name}
                      className="mt-3"
                      key={index}
                    >
                      <Form.Label className="text-tangaroa font-bold text-base">
                        {val?.label}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={val?.placeholder}
                        className="control"
                        value={form[val?.editName]}
                        onChange={(e) =>
                          setFeild(val?.editName, e.target.value)
                        }
                        isInvalid={!!errors[val?.name]}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors[val?.name]}
                      </Form.Control.Feedback>
                    </Form.Group>
                  )}
                </Col>
              </>
            );
          })}
        </Row>
      </Modal.Body>
      <Modal.Footer className="border-none pt-0 pb-8 justify-content-start">
        <Button
          variant={loadingBtn ? "disabled" : "jade"}
          className="me-2 my-0 "
          onClick={editStudent}
          disabled={loadingBtn ? true : false}
        >
          Edit
        </Button>
        <Button variant="outline-jade" className="m-0" onClick={hide}>
          Cancel
        </Button>
      </Modal.Footer>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </Modal>
  );
}
export default EditStudent;
