import React, { useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { Header, Subheader, Footer, Toasts } from "../components";
import { BsFileEarmarkMinus } from "react-icons/bs";
import MaskedFormControl from "react-bootstrap-maskedinput";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentUser, updateStudentSerive } from "../firebase/service";
import { setLoginData } from "../Redux/action/action";
import { ToastContainer } from "react-toastify";
import { phoneNumberVlidation } from "../utils/validation";

const Info = () => {
  const { currentUserData } = useSelector((state) => state.AuthReducer);
  const [user, setUser] = useState({
    name: currentUserData?.name,
    email: currentUserData?.email,
    phoneNumber: currentUserData?.phoneNumber,
    password: "",
    newPassword: "",
    conformPassword: "",
  });
  const [error, setError] = useState({});
  const [personalBtn, setPersonalBtn] = useState(true);
  const [securityBtn, setSecurityBtn] = useState(true);
  const [saveBtn, setSavebtn] = useState(false);
  const dispatch = useDispatch();
  const handleChange = (field, value) => {
    setUser({
      ...user,
      [field]: value,
    });
    if (!!error[field])
      setError({
        ...error,
        [field]: null,
      });
  };
  const validateForm = (type) => {
    const { name, phoneNumber, password, newPassword, conformPassword } = user;
    const newErrors = {};
    if (type === "personal") {
      if (!name || name === "") {
        newErrors.name = " Please enter your name";
      }
      if (!phoneNumber) {
        newErrors.phoneNumber = "Please enter your phone number";
      } else if (!phoneNumberVlidation(phoneNumber)) {
        newErrors.phoneNumber = "Please enter a valid phone number";
      }
      return newErrors;
    } else {
      if (!password || password === "")
        newErrors.password = "Please enter your password";
      if (!newPassword || newPassword === "")
        newErrors.newPassword = "Please enter your new password";
      if (!conformPassword || conformPassword === "")
        newErrors.conformPassword = "Please enter confirm password";
      else if (newPassword !== conformPassword) {
        newErrors.newPassword = "Uh-oh, passwords did not match";
        newErrors.conformPassword = "Uh-oh, passwords did not match";
      }
      return newErrors;
    }
  };
  const EditHandal = async (type) => {
    if (type === "personal") {
      setPersonalBtn(!personalBtn);
    } else {
      setSecurityBtn(!securityBtn);
    }
  };
  const saveInfo = async (type) => {
    setSavebtn(true);
    if (type === "personal") {
      const formErrors = validateForm("personal");
      if (Object.keys(formErrors).length > 0) {
        setError(formErrors);
      } else {
        const userData = {
          email: currentUserData?.email,
          name: user?.name,
          phoneNumber: user?.phoneNumber,
        };
        let res = await updateStudentSerive(currentUserData?.uid, userData);
        if (res) {
          var currentUser = await getCurrentUser(currentUserData?.uid);
          await dispatch(setLoginData(res, currentUser, "info"));
          setPersonalBtn(true);
        }
      }
    } else {
      const formErrors = validateForm("security");
      if (Object.keys(formErrors).length > 0) {
        setError(formErrors);
      } else {
        const newUserInfo = {
          email: user.email,
          password: user.password,
          newPassword: user.newPassword,
          uid: currentUserData?.uid,
        };
        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ newUserInfo }),
        };
        const updatePassword = await fetch(
          `${process.env.REACT_APP_PUBLIC_API_URL}/update-password`,
          requestOptions
        );
        setSecurityBtn(true);
        if (updatePassword?.status === 200) {
          Toasts("Your password was successfully updated!", "success");
        } else {
          Toasts("Something went wrong. Please try again.", "error");
        }
      }
    }
    setSavebtn(false);
  };
  const serchingHandal = () => {};
  return (
    <>
      <Header />
      <Subheader serchingHandal={(e) => serchingHandal(e)} />
      <section className="mt-8">
        <Container>
          <Row>
            <Col lg={10}>
              <p className="text-lg font-bold mb-5">Hi Lawren</p>
              <Row className="mb-5">
                <Col sm={6} className="my-auto">
                  <p className="">Personal information</p>
                </Col>
                <Col sm={6} className="text-sm-end">
                  {!personalBtn ? (
                    <>
                      <Button
                        variant={saveBtn ? "disabled" : "jade"}
                        onClick={() => saveInfo("personal")}
                        className="me-5"
                        disabled={saveBtn ? true : false}>
                        Save
                      </Button>
                      <Button
                        variant="outline-jade"
                        onClick={() => EditHandal("personal")}>
                        Cancel
                      </Button>
                    </>
                  ) : (
                    <Button
                      variant="outline-jade"
                      onClick={() => EditHandal("personal")}>
                      Edit
                    </Button>
                  )}
                </Col>
              </Row>
              <Row className="gy-10">
                <Col lg={4}>
                  <Form.Group>
                    <Form.Label className="text-base font-bold">
                      Name
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="name"
                      placeholder="Lawren Smith"
                      className="text-base"
                      value={user.name}
                      onChange={(e) => handleChange("name", e.target.value)}
                      isInvalid={error.name}
                      isValid={user.name}
                      disabled={personalBtn ? true : false}
                    />
                    <Form.Control.Feedback type="invalid">
                      {error.name}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col lg={4}>
                  <Form.Group>
                    <Form.Label className="text-base font-bold">
                      Email
                    </Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      placeholder="lsmith@email.com"
                      className="text-base"
                      value={user.email}
                      isInvalid={error.email}
                      isValid={user.email}
                      disabled={true}
                    />
                    <Form.Control.Feedback type="invalid">
                      {error.email}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col lg={4}>
                  <Form.Group>
                    <Form.Label className="text-base font-bold">
                      Phone number
                    </Form.Label>
                    <MaskedFormControl
                      type="text"
                      name="phoneNumber"
                      className="text-base"
                      placeholder="+123 123 123 123"
                      value={user?.phoneNumber}
                      onChange={(e) =>
                        handleChange("phoneNumber", e.target.value)
                      }
                      isInvalid={error.phoneNumber}
                      isValid={user.phoneNumber}
                      mask="(111) 111-1111"
                      disabled={personalBtn ? true : false}
                    />
                    <Form.Control.Feedback type="invalid">
                      {error.phoneNumber}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <hr className="my-12"></hr>
              <Row className="mb-5">
                <Col sm={6} className="my-auto">
                  <p className="">Security</p>
                </Col>
                <Col sm={6} className="text-sm-end">
                  {!securityBtn ? (
                    <>
                      <Button
                        variant={saveBtn ? "disabled" : "jade"}
                        onClick={() => saveInfo("security")}
                        className="me-5"
                        disabled={saveBtn ? true : false}>
                        Save
                      </Button>
                      <Button
                        variant="outline-jade"
                        onClick={() => EditHandal("security")}>
                        Cancel
                      </Button>
                    </>
                  ) : (
                    <Button
                      variant="outline-jade"
                      onClick={() => EditHandal("security")}>
                      Edit
                    </Button>
                  )}
                </Col>
              </Row>
              <Row className="gy-10">
                <Col lg={4}>
                  <Form.Group>
                    <Form.Label className="text-base font-bold">
                      Current password
                    </Form.Label>
                    <Form.Control
                      type="password"
                      name="password"
                      className="text-base"
                      placeholder="- Type here -"
                      value={user.password}
                      onChange={(e) => handleChange("password", e.target.value)}
                      isInvalid={error.password}
                      disabled={securityBtn ? true : false}
                    />
                    <Form.Control.Feedback type="invalid">
                      {error.password}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col lg={4}>
                  <Form.Group>
                    <Form.Label className="text-base font-bold">
                      New password
                    </Form.Label>
                    <Form.Control
                      type="password"
                      name="newPassword"
                      placeholder="- Type here -"
                      className="text-base"
                      value={user.newPassword}
                      onChange={(e) =>
                        handleChange("newPassword", e.target.value)
                      }
                      isInvalid={error.newPassword}
                      disabled={securityBtn ? true : false}
                    />
                    <Form.Control.Feedback type="invalid">
                      {error.newPassword}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col lg={4}>
                  <Form.Group>
                    <Form.Label className="text-base font-bold">
                      Password confirmation
                    </Form.Label>
                    <Form.Control
                      type="password"
                      name="conformPassword"
                      placeholder="- Type here -"
                      className="text-base"
                      value={user.conformPassword}
                      onChange={(e) =>
                        handleChange("conformPassword", e.target.value)
                      }
                      isInvalid={error.conformPassword}
                      disabled={securityBtn ? true : false}
                    />
                    <Form.Control.Feedback type="invalid">
                      {error.conformPassword}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <hr className="my-12"></hr>
              <div className="hstack mb-5">
                <BsFileEarmarkMinus className="text-crayolablue text-lg me-3" />
                <a
                  className="text-crayolablue text-underline"
                  href={() => false}>
                  Terms and conditions
                </a>
              </div>
              <div className="hstack mb-10">
                <BsFileEarmarkMinus className="text-crayolablue text-lg me-3" />
                <a
                  className="text-crayolablue text-underline"
                  href={() => false}>
                  Privacy policy
                </a>
              </div>
            </Col>
          </Row>
        </Container>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </section>
      <Footer />
    </>
  );
};

export default Info;
