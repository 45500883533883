// add & edit staff
export const addStff = [
  {
    label: "First Name",
    name: "fname",
    editName: "name",
    type: "text",
    col: 6,
  },
  {
    label: "Last Name",
    name: "lname",
    editName: "last_name",
    type: "text",
    col: 6,
  },
  {
    label: "Email",
    name: "email",
    type: "email",
    editName: "email",
    errorName: "",
    col: 12,
  },
  {
    label: "Class",
    name: "classes",
    type: "",
    col: 12,
  },
  {
    label: "Password",
    name: "password",
    type: "password",
    col: 12,
  },
  {
    label: "Confirm Password",
    name: "confirmpass",
    type: "password",
    col: 12,
  },
];

// add & edit student
export const addStudents = [
  {
    label: "First Name",
    name: "fname",
    editName: "name",
    placeholder: "- Type here -",
    type: "text",
    col: 12,
  },
  {
    label: "Last Name",
    name: "lname",
    editName: "last_name",
    placeholder: "- Type here -",
    type: "text",
    col: 12,
  },
  {
    label: "School",
    name: "school_name",
    editName: "school_name",
    placeholder: "- Select school -",
    type: "text",
    col: 12,
  },
  {
    label: "Class",
    name: "classes",
    type: "",
    col: 12,
  },
  {
    label: "Individual code",
    name: "individualCode",
    editName: "individual_code",
    placeholder: "- Type here -",
    type: "number",
    col: 12,
  },
  {
    label: "Level",
    name: "level",
    editName: "level",
    placeholder: "Level",
    type: "text",
    col: 6,
  },
];

// add school
export const addSchoolCol1 = [
  {
    label: "Name",
    name: "name",
    editName: "name",
    placeholder: "- Type here -",
    type: "text",
    col: 6,
  },
  {
    label: "Address",
    name: "address",
    editName: "last_name",
    placeholder: "- Type here -",
    type: "text",
    col: 6,
  },
  {
    label: "Phone Number",
    name: "phoneNumber",
    editName: "last_name",
    placeholder: "- Type here -",
    type: "text",
    col: 6,
  },
  {
    label: "City",
    name: "city",
    editName: "last_name",
    placeholder: "- Type here -",
    type: "text",
    col: 3,
  },
  {
    label: "Postal Code",
    name: "postalCode",
    editName: "postalCode",
    placeholder: "- Type here -",
    type: "text",
    col: 3,
  },
  {
    label: "Type",
    name: "type",
    type: "",
    col: 6,
  },
  {
    label: "Province",
    name: "province",
    editName: "individual_code",
    placeholder: "- Type here -",
    type: "text",
    col: 6,
  },
  {
    label: "Primary Contact Email",
    name: "email",
    editName: "name",
    placeholder: "- Type here -",
    type: "email",
    col: 6,
  },
];

export const addSchoolCol3 = [
  {
    label: "Respect",
    name: "respect",
    editName: "name",
    col: 12,
  },
  {
    label: "Listen to music",
    name: "listenToMusic",
    editName: "name",
    col: 12,
  },
  {
    label: "Responsbility",
    name: "responsbility",
    editName: "name",
    col: 12,
  },
  {
    label: "Eat a snack",
    name: "eatASnack",
    editName: "name",
    col: 12,
  },
  {
    label: "Success",
    name: "success",
    editName: "name",
    col: 12,
  },
  {
    label: "Wear a cap",
    name: "wearACap",
    editName: "name",
    col: 12,
  },
];
export const provinceOptions = [
  {
    name: "British Columbia",
  },
  {
    name: "Manitoba",
  },
  {
    name: "New Brunswick",
  },
  {
    name: "Newfoundland and Labrador",
  },
  {
    name: "Northwest Territories",
  },
  {
    name: "Nova Scotia",
  },
  {
    name: "Nunavut",
  },
  {
    name: "Ontario",
  },
  {
    name: "Prince Edward Island",
  },
  {
    name: "Quebec",
  },
  {
    name: "Saskatchewan",
  },
  {
    name: "Yukon",
  },
];

export const levelOptions = [
  {
    name: "ADA",
  },
  {
    name: "FTP",
  },
  {
    name: "G1",
  },
  {
    name: "G2",
  },
  {
    name: "G3",
  },
  {
    name: "G4",
  },
  {
    name: "G5",
  },
  {
    name: "LAN",
  },
  {
    name: "PMS",
  },
];
export const importFileTypes = [
  {
    name: "Benchmark groups",
  },
];
export const buttonSlider = [
  {
    name: "All",
    class: "",
  },
  {
    name: "Teachers",
    class: "flex-none",
  },
  {
    name: "Managers",
    class: "flex-none",
  },
  {
    name: "Class",
    class: "flex-none",
  },
];
