import {
  classesCollection,
  profileCollection,
  schoolCollection,
  studentsCollection,
  storage,
  teacherClassCollection,
} from "./index";
import {
  updateDoc,
  getDocs,
  addDoc,
  query,
  where,
  deleteDoc,
  doc,
  setDoc,
  orderBy,
  limit,
  startAfter,
  limitToLast,
  endBefore,
} from "firebase/firestore";

import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";

export const getAllSchoolCount = async () => {
  return getDocs(schoolCollection)
    .then(async (res) => {
      return res?.docs?.length;
    })
    .catch((e) => {
      console.log("error", e);
    });
};
export const getAllClassCount = async () => {
  return getDocs(classesCollection)
    .then(async (res) => {
      return res?.docs?.length;
    })
    .catch((e) => {
      console.log("error", e);
    });
};
export const getAllStudentCount = async () => {
  const q = query(profileCollection, where("role", "==", "student"));
  return getDocs(q)
    .then(async (res) => {
      return res?.docs?.length;
    })
    .catch((e) => {
      console.log("error", e);
    });
};
export const getAllSchool = async (itemsPerPage) => {
  return getDocs(schoolCollection)
    .then(async (res) => {
      var rowsData = [];

      for (let index = 0; index < res.docs.length; index++) {
        const doc = res.docs[index];
        var obj = {
          ...doc.data(),
          uid: doc.id,
        };
        rowsData.push(obj);
      }
      return rowsData;
    })
    .catch((e) => {
      console.log("error", e);
    });
};
export const getAllSchoolShow = async (itemsPerPage) => {
  const usersQuery = query(
    schoolCollection,
    orderBy("code"),
    limit(itemsPerPage)
  );
  const documentSnapshots = await getDocs(usersQuery);
  return documentSnapshots;
};
export const getAllSchoolNext = async (documentSnapshots, itemsPerPage) => {
  const lastVisible = documentSnapshots.docs[documentSnapshots.docs.length - 1];
  const next = query(
    schoolCollection,
    orderBy("code"),
    startAfter(lastVisible.data().code),
    limit(itemsPerPage)
  );
  const documentSnapshotsNext = await getDocs(next);
  return documentSnapshotsNext;
};

export const getAllSchoolPrev = async (documentSnapshots, itemsPerPage) => {
  const firstVisible = documentSnapshots.docs[0];
  const next = query(
    schoolCollection,
    orderBy("code"),
    endBefore(firstVisible.data().code), // Use `endAt()` method and pass the reference
    limitToLast(itemsPerPage)
  );
  const documentSnapshotsNext = await getDocs(next);
  return documentSnapshotsNext;
};
export const getAllStudents = async () => {
  let allClassess = await getAllClass();
  let allClassesByStudent = await getAllStudentClassess();

  const q = query(profileCollection, where("role", "==", "student"));
  return getDocs(q)
    .then(async (res) => {
      var rowsData = [];
      for (let index = 0; index < res.docs.length; index++) {
        const doc = res.docs[index];
        var classData = await getClassByStudentNew(
          doc.id,
          allClassesByStudent,
          allClassess
        );
        var obj = {
          ...doc.data(),
          uid: doc.id,
          class_data: classData ? classData : [],
        };
        rowsData.push(obj);
      }

      return rowsData;
    })
    .catch((e) => {
      console.log("error", e);
    });
};
export const getAllStudentsShow = async (itemsPerPage) => {
  const usersQuery = query(
    profileCollection,
    orderBy("individual_code"),
    limit(itemsPerPage)
  );
  const documentSnapshots = await getDocs(usersQuery);
  return documentSnapshots;
};
export const getAllStaff = async () => {
  let allClassess = await getAllClass();

  const q = query(profileCollection, where("role", "==", "teacher"));
  return getDocs(q)
    .then(async (res) => {
      var rowsData = [];

      for (let index = 0; index < res.docs.length; index++) {
        const doc = res.docs[index];
        const data = await getClassByStaff(allClassess, doc.data().class);
        var obj = {
          ...doc.data(),
          uid: doc.id,
          class_data: data ? data : [],
        };
        rowsData.push(obj);
      }
      return rowsData;
    })
    .catch((e) => {
      console.log("error", e);
    });
};
export const getAllStaffBySchool = async (val) => {
  let allClassess = await getAllClass();

  const q = query(
    profileCollection,
    where("role", "==", "teacher"),
    where("school_id", "==", val)
  );
  return getDocs(q)
    .then(async (res) => {
      var rowsData = [];

      for (let index = 0; index < res.docs.length; index++) {
        const doc = res.docs[index];

        const data = await getClassByStaff(allClassess, doc.data().class);
        var obj = {
          ...doc.data(),
          uid: doc.id,
          class_data: data ? data : [],
        };
        rowsData.push(obj);
      }
      return rowsData;
    })
    .catch((e) => {
      console.log("error", e);
    });
};
export const addStudentService = async (studentdata) => {
  const doc_id = await addDoc(profileCollection, studentdata)
    .then((res) => {
      return res.id;
    })
    .catch((e) => {
      console.log("error", e);
    });
  return doc_id;
};
export const addStudentClass = async (record) => {
  const doc_id = await addDoc(studentsCollection, record)
    .then((res) => {
      return res.id;
    })
    .catch((e) => {
      console.log("error", e);
    });
  return doc_id;
};
export const getAllClass = async (itemsPerPage) => {
  return getDocs(classesCollection)
    .then(async (res) => {
      var rowsData = [];

      for (let index = 0; index < res.docs.length; index++) {
        const doc = res.docs[index];
        var obj = {
          ...doc.data(),
          uid: doc.id,
        };
        rowsData.push(obj);
      }
      return rowsData;
    })
    .catch((e) => {
      console.log("error", e);
    });
};
export const getAllClassShow = async (itemsPerPage) => {
  const usersQuery = query(classesCollection, limit(itemsPerPage));
  const documentSnapshots = await getDocs(usersQuery);
  return documentSnapshots;
};
export const getCurrentUser = async (uid) => {
  const q = query(profileCollection, where("uid", "==", uid));

  return await getDocs(q)
    .then(async (res) => {
      // var profile = await getUserProfile(res.docs[0].data()?.profile);
      return { ...res.docs[0].data(), profile: "" };
    })
    .catch((e) => {
      console.log("error", e);
    });
};
export const getAdmin = async (email) => {
  const q = query(profileCollection, where("email", "==", email));
  return await getDocs(q)
    .then(async (res) => {
      if (res && res?.docs && res?.docs[0]) {
        return { ...res.docs[0].data() };
      } else {
        return {};
      }
    })
    .catch((e) => {
      console.log("error", e);
    });
};

export const getClassByStudent = async (key, sId, allClassess) => {
  const q = query(studentsCollection, where(key, "==", sId));

  return await getDocs(q)
    .then(async (res) => {
      var rowsData = [];
      for (let index = 0; index < res.docs.length; index++) {
        const doc = res.docs[index];
        var studentObj = allClassess.filter(
          (item) => item.uid === doc.data().classId
        );
        var obj = {
          ...doc.data(),
          uid: doc.id,
          classDetails:
            studentObj && studentObj.length > 0 ? studentObj[0] : {},
        };
        rowsData.push(obj);
      }
      return rowsData;
    })
    .catch((e) => {
      console.log("error", e);
    });
};
export const getClassByStudentNew = async (key, allStudents, allClassess) => {
  var newAllClassStudents = allStudents?.filter(
    (item) => item.student_id === key
  );
  var rowsData = [];
  for (let index = 0; index < newAllClassStudents?.length; index++) {
    const doc = newAllClassStudents[index];
    var studentObj = allClassess?.filter((item) => item.uid === doc.classId);
    var obj = {
      ...doc,
      // uid: doc.id,
      classDetails: studentObj && studentObj.length > 0 ? studentObj[0] : {},
    };
    rowsData.push(obj);
  }
  return rowsData;
};

export const getAllStudentClassess = async (sId) => {
  return await getDocs(studentsCollection)
    .then((res) => {
      var rowsData = [];

      for (let index = 0; index < res.docs.length; index++) {
        const doc = res.docs[index];
        // const data = doc.data();
        var obj = {
          ...doc.data(),
          uid: doc.id,
        };
        rowsData.push(obj);
      }
      return rowsData;
    })
    .catch((e) => {
      console.log("error", e);
    });
};
export const getClassByStaff = async (allClassess, staffClasses) => {
  if (staffClasses) {
    var rowsData = [];
    for (let index = 0; index < staffClasses.length; index++) {
      const doc = staffClasses[index];
      var studentObj = allClassess.filter((item) => item.uid === doc.classId);
      if (studentObj && studentObj?.length > 0) {
        rowsData.push(studentObj[0]);
      }
    }
    return rowsData;
  }
};

export const removeStudentRecord = async (studentId) => {
  return await deleteDoc(doc(profileCollection, studentId))
    .then((res) => {
      return true;
    })
    .catch((e) => {
      console.log("error", e);
      return false;
    });
};
export const addClassService = async (params) => {
  const doc_id = await addDoc(classesCollection, params)
    .then((res) => {
      return res.id;
    })
    .catch((e) => {
      console.log("error", e);
    });
  return doc_id;
};

export const removeClasses = async (classId) => {
  return await deleteDoc(doc(classesCollection, classId))
    .then((res) => {
      return true;
    })
    .catch((e) => {
      console.log("error", e);
      return false;
    });
};

export const updateClassService = async (docID, nameClass) => {
  return await updateDoc(doc(classesCollection, docID), {
    class_name: nameClass,
  })
    .then(() => {
      return true;
    })
    .catch((e) => {
      return false;
    });
};
export const updateStudentSerive = async (docID, params) => {
  return await updateDoc(doc(profileCollection, docID), params)
    .then((res) => {
      return true;
    })
    .catch((e) => {
      return false;
    });
};
export const updateClassByStudent = async (docID, params) => {
  return await updateDoc(doc(studentsCollection, docID), params)
    .then(() => {
      return true;
    })
    .catch((e) => {
      return false;
    });
};

// add school
export const addSchoolService = async (params) => {
  const doc_id = await addDoc(schoolCollection, params)
    .then((res) => {
      return res.id;
    })
    .catch((e) => {
      console.log("error", e);
    });
  return doc_id;
};
export const getSchoolInCode = async (code) => {
  const q = query(schoolCollection, where("code", "==", code));
  return await getDocs(q)
    .then(async (res) => {
      if (res && res?.docs && res?.docs[0]) {
        return false;
      } else {
        return true;
      }
    })
    .catch((e) => {
      console.log("error", e);
    });
};
// delete school
export const removeSchool = async (deleteId) => {
  return await deleteDoc(doc(schoolCollection, deleteId))
    .then((res) => {
      return true;
    })
    .catch((e) => {
      console.log("error", e);
      return false;
    });
};

// edit school

export const updateSchoolService = async (docID, params) => {
  return await updateDoc(doc(schoolCollection, docID), params)
    .then(() => {
      return true;
    })
    .catch((e) => {
      return false;
    });
};

export const allStudentQrCode = async (file) => {
  const ext = file?.type.split("/").pop();
  const fileName = `/${new Date().getUTCMilliseconds()}.${ext}`;
  const storageRef = ref(storage, `files/${fileName}`);
  const uploadTask = uploadBytesResumable(storageRef, file);
  uploadTask.on(
    "state_changed",
    (snapshot) => {
      const progress = Math.round(
        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      );
    },
    (error) => {
      alert(error);
    },
    async () => {
      await getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {});
    }
  );
};
//add staff
export const addStaffService = async (params) => {
  const staffRef = doc(profileCollection, params?.uid);
  return await setDoc(staffRef, params)
    .then((res) => {
      return res;
    })
    .catch((e) => {
      console.log("error", e);
    });
};
export const updateStaffSerive = async (docID, params) => {
  return await updateDoc(doc(profileCollection, docID), params)
    .then(() => {
      return true;
    })
    .catch((e) => {
      return false;
    });
};
export const removeStaffRecord = async (selectRecord) => {
  return await deleteDoc(doc(profileCollection, selectRecord))
    .then((res) => {
      return true;
    })
    .catch((e) => {
      console.log("error", e);
      return false;
    });
};

export const addClassOfTeacher = async (params) => {
  const doc_id = await addDoc(teacherClassCollection, params)
    .then((res) => {
      return res.id;
    })
    .catch((e) => {
      console.log("error", e);
    });
  return doc_id;
};

export const deleteClassOfTeacher = async (
  singleClass,
  key,
  params,
  key2,
  params2
) => {
  const q = singleClass
    ? query(
        teacherClassCollection,
        where(key, "==", params),
        where(key2, "==", params2)
      )
    : query(teacherClassCollection, where(key, "==", params));
  return getDocs(q)
    .then(async (res) => {
      for (let index = 0; index < res.docs.length; index++) {
        const doc = res.docs[index];
        await deleteClassOfTeacher2(doc.id);
      }
    })
    .catch((e) => {
      console.log("error", e);
    });
};

export const deleteClassOfTeacher2 = async (classId) => {
  return await deleteDoc(doc(teacherClassCollection, classId))
    .then((res) => {
      return true;
    })
    .catch((e) => {
      console.log("error", e);
      return false;
    });
};

export const deleteClassOfStudent = async (
  singleClass,
  key,
  params,
  key2,
  params2
) => {
  const q = singleClass
    ? query(
        studentsCollection,
        where(key, "==", params),
        where(key2, "==", params2)
      )
    : query(studentsCollection, where(key, "==", params));
  return getDocs(q)
    .then(async (res) => {
      for (let index = 0; index < res.docs.length; index++) {
        const doc = res.docs[index];

        deleteClassOfStudentById(doc.id);
      }
    })
    .catch((e) => {
      console.log("error", e);
    });
};
export const deleteClassOfStudentById = async (classId) => {
  return await deleteDoc(doc(studentsCollection, classId))
    .then((res) => {
      return true;
    })
    .catch((e) => {
      console.log("error", e);
      return false;
    });
};

//import school data
export const importAddSchool = async (params) => {};

//import classes data
export const ClassesObjectServices = async (class_name, group_code) => {
  const q = query(
    classesCollection,
    where("class_name", "==", class_name),
    where("group_code", "==", group_code)
  );
  return await getDocs(q)
    .then(async (res) => {
      if (res.docs.length === 0) {
        return true;
      } else {
        return false;
      }
    })
    .catch((e) => {
      console.log("error", e);
    });
};
export const getAllStudentsBySchool = async (schoolId) => {
  let allClassess = await getAllClass();
  let allClassesByStudent = await getAllStudentClassess();
  const q = query(profileCollection, where("school_id", "==", schoolId));
  return getDocs(q)
    .then(async (res) => {
      var rowsData = [];
      for (let index = 0; index < res.docs.length; index++) {
        const doc = res.docs[index];
        var classData = await getClassByStudentNew(
          doc.id,
          allClassesByStudent,
          allClassess
        );
        var obj = {
          ...doc.data(),
          uid: doc.id,
          class_data: classData ? classData : [],
          // class_id: classData?.uid,
        };
        rowsData.push(obj);
      }
      return rowsData;
    })
    .catch((e) => {
      console.log("error", e);
    });
};
// pagination student
export const getAllStudentNext = async (documentSnapshots, itemsPerPage) => {
  const lastVisible = documentSnapshots.docs[documentSnapshots.docs.length - 1];
  const next = query(
    profileCollection,
    orderBy("individual_code"),
    startAfter(lastVisible.data().individual_code),
    limit(itemsPerPage)
  );
  const documentSnapshotsNext = await getDocs(next);
  return documentSnapshotsNext;
};

export const getAllStudentPrev = async (documentSnapshots, itemsPerPage) => {
  // Get the last visible document
  const firstVisible = documentSnapshots.docs[0];
  const next = query(
    profileCollection,
    orderBy("individual_code"),
    endBefore(firstVisible.data().individual_code), // Use `endAt()` method and pass the reference
    limitToLast(itemsPerPage)
  );
  const documentSnapshotsNext = await getDocs(next);
  return documentSnapshotsNext;
};
// flter school by student
export const getAllSchoolByStudentSearch = async (val, itemsPerPage) => {
  const usersQuery = query(
    profileCollection,
    where("role", "==", "student"),
    where("school_id", "==", val),
    orderBy("individual_code"),
    limit(itemsPerPage)
  );
  const documentSnapshots = await getDocs(usersQuery);
  return documentSnapshots;
};
// school wise student count
export const getAllSchoolByStudentCount = async (val) => {
  const q = query(
    profileCollection,
    where("role", "==", "student"),
    where("school_id", "==", val)
  );
  return getDocs(q)
    .then(async (res) => {
      return res?.docs?.length;
    })
    .catch((e) => {
      console.log("error", e);
    });
};
export const getAllStudentNextSchool = async (
  documentSnapshots,
  itemsPerPage,
  val
) => {
  // Get the last visible document
  const lastVisible = documentSnapshots.docs[documentSnapshots.docs.length - 1];
  const next = query(
    profileCollection,
    where("role", "==", "student"),
    where("school_id", "==", val),
    orderBy("individual_code"),
    startAfter(lastVisible.data().individual_code),
    limit(itemsPerPage)
  );
  const documentSnapshotsNext = await getDocs(next);
  return documentSnapshotsNext;
};
export const getAllStudentPrevSchool = async (
  documentSnapshots,
  itemsPerPage,
  val
) => {
  // Get the last visible document
  const firstVisible = documentSnapshots.docs[0];
  const next = query(
    profileCollection,
    where("role", "==", "student"),
    where("school_id", "==", val),
    orderBy("individual_code"),
    endBefore(firstVisible.data().individual_code),
    limitToLast(itemsPerPage)
  );
  const documentSnapshotsNext = await getDocs(next);
  return documentSnapshotsNext;
};

// school serching
export const getAllSchoolSearch = async (itemsPerPage, val) => {
  const usersQuery = query(
    schoolCollection,
    where("name", "==", val),
    limit(itemsPerPage)
  );
  const documentSnapshots = await getDocs(usersQuery);
  return documentSnapshots;
};

export const getAllSchoolCountSearch = async (val) => {
  const q = query(schoolCollection, where("name", "==", val));
  return getDocs(q)
    .then(async (res) => {
      return res?.docs?.length;
    })
    .catch((e) => {
      console.log("error", e);
    });
};
export const getAllSchoolNextSearch = async (
  documentSnapshots,
  itemsPerPage,
  val
) => {
  // Get the last visible document
  const lastVisible = documentSnapshots.docs[documentSnapshots.docs.length - 1];
  const next = query(
    schoolCollection,
    where("name", "==", val),
    orderBy("name"),
    startAfter(lastVisible.data().name),
    limit(itemsPerPage)
  );
  const documentSnapshotsNext = await getDocs(next);
  return documentSnapshotsNext;
};

export const getAllSchoolPrevSearch = async (
  documentSnapshots,
  itemsPerPage,
  val
) => {
  // Get the last visible document
  const firstVisible = documentSnapshots.docs[0];
  const next = query(
    schoolCollection,
    where("name", "==", val),
    orderBy("name"),
    endBefore(firstVisible.data().name), // Use `endAt()` method and pass the reference
    limitToLast(itemsPerPage)
  );
  const documentSnapshotsNext = await getDocs(next);
  return documentSnapshotsNext;
};
//student serching
export const getAllStudentSearch = async (itemsPerPage, val) => {
  const usersQuery = query(
    profileCollection,
    where("name", "==", val),
    limit(itemsPerPage)
  );
  const documentSnapshots = await getDocs(usersQuery);
  return documentSnapshots;
};

export const getAllStudentCountSearch = async (val) => {
  const q = query(profileCollection, where("name", "==", val));
  return getDocs(q)
    .then(async (res) => {
      return res?.docs?.length;
    })
    .catch((e) => {
      console.log("error", e);
    });
};
export const getAllStudentNextSearch = async (
  documentSnapshots,
  itemsPerPage,
  val
) => {
  // Get the last visible document
  const lastVisible = documentSnapshots.docs[documentSnapshots.docs.length - 1];
  const next = query(
    profileCollection,
    where("name", "==", val),
    orderBy("name"),
    startAfter(lastVisible.data().name),
    limit(itemsPerPage)
  );
  const documentSnapshotsNext = await getDocs(next);
  return documentSnapshotsNext;
};

export const getAllStudentPrevSearch = async (
  documentSnapshots,
  itemsPerPage,
  val
) => {
  // Get the last visible document
  const firstVisible = documentSnapshots.docs[0];
  const next = query(
    profileCollection,
    where("name", "==", val),
    orderBy("name"),
    endBefore(firstVisible.data().name), // Use `endAt()` method and pass the reference
    limitToLast(itemsPerPage)
  );
  const documentSnapshotsNext = await getDocs(next);
  return documentSnapshotsNext;
};
//get all student for export
export const getAllStudentsForQrCodes = async (val) => {
  const q = query(
    profileCollection,
    where("role", "==", "student"),
    where("school_id", "==", val)
  );
  return getDocs(q)
    .then(async (res) => {
      // for (let index = 0; index < res.docs.length; index++) {
      //   const doc = res.docs[index];
      //   var obj = {
      //     ...doc.data(),
      //     uid: doc.id,
      //   };
      //   rowsData.push(obj);
      // }
      return res?.docs;
    })
    .catch((e) => {
      console.log("error", e);
    });
};
// student alredy exsit
export const studentAlreadyExist = async (code, school_code) => {
  const q = query(
    profileCollection,
    where("role", "==", "student"),
    where("individual_code", "==", code),
    where("school_code", "==", school_code)
  );
  return await getDocs(q)
    .then(async (res) => {
      if (res.docs.length === 0) {
        return true;
      } else {
        return false;
      }
    })
    .catch((e) => {
      console.log("error", e);
    });
};
