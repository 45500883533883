import {
  CURRENT_USER,
  LOGIN_DATA,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
} from "../actionTypes";

export const getLogin = () => async (dispatch) => {
  try {
    await dispatch({ type: LOGIN_SUCCESS });
  } catch (error) {
    console.log(error);
  }
};
export const getLogout = () => async (dispatch) => {
  try {
    await dispatch({ type: LOGOUT_SUCCESS });
  } catch (error) {
    console.log(error);
  }
};
export const setLoginData = (res, currentUser, type) => {
  return async (dispatch) => {
    try {
      if (res) {
        if (type === "login") {
          dispatch({ type: LOGIN_DATA, data: res });
          dispatch({ type: CURRENT_USER, currentUser: currentUser });
        } else {
          dispatch({ type: CURRENT_USER, currentUser: currentUser });
        }
      }
    } catch (error) {
      console.warn(error);
    }
  };
};
