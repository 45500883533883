import React from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import { BsArrowLeftShort } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

const Subheader = (props) => {
  const navigate = useNavigate();
  let url = window.location.href;
  let PageUrl = url.substring(url.lastIndexOf("/") + 1);
  const Title =
    PageUrl === "schools" ? (
      "Schools"
    ) : PageUrl === "class" ? (
      <div>
        <a
          className="text-white"
          href={() => false}
          onClick={() => navigate("/")}
        >
          <BsArrowLeftShort />
        </a>
        Start School - Classes
      </div>
    ) : PageUrl === props.name?.toLowerCase() ? (
      <div>
        <a
          className="text-white"
          href={() => false}
          onClick={() => navigate("/schools")}
        >
          <BsArrowLeftShort />
        </a>
        {props.name}
      </div>
    ) : PageUrl === "students" ? (
      "Students"
    ) : PageUrl === "info" ? (
      "Your information"
    ) : (
      props.school
    );
  const onSearch = (value) => {
    if (value) {
      props.serchingHandal(value);
    } else {
      props.serchingHandal("");
    }
  };

  return (
    <div>
      <section className="bg-blue py-10">
        <Container>
          <Row>
            <Col xl={9}>
              {PageUrl === props.name?.toLowerCase() ? (
                <a
                  className="text-white"
                  href={() => false}
                  onClick={() => navigate("/schools")}
                >
                  <h1 className="text-light text-2xl">{Title}</h1>
                </a>
              ) : props?.screenName === "schoolName" ? (
                <>
                  <a
                    href={() => false}
                    className="text-white d-flex"
                    onClick={() => navigate("/schools")}
                  >
                    <BsArrowLeftShort size={40} />
                    <h1 className="text-light text-2xl ">{props.name}</h1>
                  </a>
                </>
              ) : (
                <h1 className="text-light text-2xl">{Title}</h1>
              )}
            </Col>
            <Col xl={3} className="d-flex">
              <Form.Control
                type="search"
                placeholder="Search"
                className="me-2"
                aria-label="Search"
                onChange={(e) => onSearch(e.target.value)}
              />
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};
export default Subheader;
