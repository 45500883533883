import React from "react";
import { Container, Row, Col } from "react-bootstrap";

class Footer extends React.Component {
  render() {
    return (
      <div className="mt-auto ">
        <section className="bg-paleblue py-5 ">
          <Container>
            <Row>
              <Col md={6}>
                <p className="text-sm font-bold">Tangibles</p>
              </Col>
              <Col md={6}>
                <p className="text-sm font-bold text-md-end">
                  2022 - All rights reserved
                </p>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    );
  }
}
export default Footer;
