import { React, useState } from "react";
import {
  Row,
  Col,
  Container,
  Table,
  Pagination,
  Button,
} from "react-bootstrap";
import {
  Header,
  Subheader,
  Footer,
  AddModal,
  EditModal,
  DeleteModal,
  ImportModal,
} from "../components";
import { student } from "./constant";
import { BsPencilSquare, BsTrash } from "react-icons/bs";

function Class() {
  const [show, setShow] = useState(false);
  const [importModal, setImportmodal] = useState(false);
  const [editModal, setEditmodal] = useState(false);
  const [deleteModal, setDeletemodal] = useState(false);

  const handleShow = () => {
    setShow(!show);
  };
  const importModalshow = () => {
    setImportmodal(!importModal);
  };
  const editModalshow = () => {
    setEditmodal(!editModal);
  };
  const deleteModalshow = () => {
    setDeletemodal(!deleteModal);
  };

  return (
    <>
      <section className="min-h-lg-screen vstack student">
        <Header />
        <Subheader />
        <Container>
          <Row className="mt-10">
            <Col lg={6} className="mb-7 mt-3">
              <p className="font-bold ">All schools (5)</p>
            </Col>
            <Col lg={6} className="text-lg-end mb-7">
              <Button
                variant="jade"
                className=" me-4"
                onClick={importModalshow}
              >
                Import .XLSX
              </Button>
              <Button variant="jade" className=" me-4" onClick={handleShow}>
                Add School
              </Button>
            </Col>
            <Col sm={12} className="mb-8">
              <Table responsive className="schooltable">
                <thead className="text-tangaroa">
                  <tr>
                    <th className="text-base font-800">#</th>
                    <th className="text-base font-800 text-capitalize">Code</th>
                    <th className="text-base font-800 text-capitalize">
                      First
                    </th>
                    <th className="text-base font-800 text-capitalize">Last</th>
                    <th className="text-base font-800 text-capitalize">
                      Classes
                    </th>
                  </tr>
                </thead>
                <tbody className="text-tangaroa">
                  {student.map((student) => {
                    return (
                      <tr>
                        <td className="text-base font-800">{student.id}</td>
                        <td className="font-regular text-base">
                          {student.code}
                        </td>
                        <td className="font-regular text-base">
                          {student.fname}
                        </td>
                        <td className="font-regular text-base">
                          {student.lname}
                        </td>
                        <td className="font-regular text-base">
                          {student.classes}
                        </td>
                        <td className="text-end">
                          <a
                            href={() => false}
                            className="btn btn-transparent"
                            onClick={editModalshow}
                          >
                            <BsPencilSquare className="text-sm" />
                          </a>
                          <a
                            href={() => false}
                            className="btn btn-transparent"
                            onClick={deleteModalshow}
                          >
                            <BsTrash className="text-sm text-danger" />
                          </a>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Col>
            <Col className="d-flex justify-content-end mb-10 table-pagination">
              <Pagination>
                <Pagination.Item className="me-1 rounded-4">
                  Prev
                </Pagination.Item>
                <Pagination.Item className="me-1" active>
                  {1}
                </Pagination.Item>
                <Pagination.Item className="me-1">{2}</Pagination.Item>
                <Pagination.Item className="me-1">{3}</Pagination.Item>
                <Pagination.Item>Next</Pagination.Item>
              </Pagination>
            </Col>
          </Row>
        </Container>

        <Footer />
      </section>

      {show && <AddModal show={show} hide={() => handleShow()} />}
      {importModal && (
        <ImportModal show={importModal} hide={() => importModalshow()} />
      )}
      {editModal && <EditModal show={editModal} hide={() => editModalshow()} />}
      {deleteModal && (
        <DeleteModal show={deleteModal} hide={() => deleteModalshow()} />
      )}
    </>
  );
}

export default Class;
