import { React, useEffect, useState } from "react";
import { Edit, show1 } from "../assets/images";
import { Delete } from "../assets/images";
import LetteredAvatar from "react-lettered-avatar";
import {
  Row,
  Col,
  Container,
  Table,
  Button,
  Pagination,
  Card,
} from "react-bootstrap";
import { useLocation } from "react-router-dom";
import {
  Header,
  Subheader,
  Footer,
  Addclass,
  Editclass,
  DeleteModal,
  ImportModal,
  EditStaffModal,
  StaffModal,
  AddStudent,
  ClassificationAlertModal,
  QrModal,
} from "../components";
import {
  addClassService,
  addStaffService,
  addStudentClass,
  addStudentService,
  deleteClassOfStudent,
  deleteClassOfTeacher,
  getAllClass,
  getAllSchool,
  getAllStaffBySchool,
  getAllStudentsForQrCodes,
  removeClasses,
  removeStaffRecord,
  studentAlreadyExist,
  updateClassService,
  updateStudentSerive,
} from "../firebase/service";
import * as XLSX from "xlsx/xlsx.mjs";
import { signupApi } from "../firebase";
import { useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { TableLoadingPage, StaffLoading, Toasts } from "../components";
import { ToastContainer } from "react-toastify";
import QRCode from "qrcode";

function School() {
  const location = useLocation();
  const schoolDetails = location ? location.state : {};
  const [show, setShow] = useState(false);
  const [importModal, setImportmodal] = useState(false);
  const [editClass, setEditclass] = useState(false);
  const [deleteModal, setDeletemodal] = useState(false);
  const [staffModal, setStaffModal] = useState(false);
  const [editstaffModal, setEditStaffModal] = useState(false);
  const [selectRecord, setSelectedRecord] = useState([]);
  const [Titlevariable, setTitlevariable] = useState("");
  const [searchTemp, setSearchTemp] = useState([]);
  const [allClasses, setAllClasses] = useState([]);
  const [allClassesSelect, setAllClassesSelect] = useState([]);
  const [staffRecord, setStaffRecord] = useState([]);
  const [importDataType, setImportDataType] = useState("");
  const [importMessges, setImportMessages] = useState("UPLOADING...");
  const [importMessgesType, setImportMessagesType] = useState("upload");
  const [loading, setLoading] = useState(false);
  const [staffLoading, setSatffLoading] = useState(false);
  const [searchMsg, setSearchMsg] = useState(false);
  const [showStudent, setStudentshow] = useState(false);
  const [allStudent, setAllStudent] = useState([]);
  const [schoolRecord, setSchool] = useState([]);
  const [searchMsgVal, setSearchMsgVal] = useState("");
  const [page, setPage] = useState(1);
  const perPage = 10;
  const [classificationStudentData, setClassificationStudentData] =
    useState("");
  const [classificationStudentDatatype, setClassificationStudentDatatype] =
    useState("");
  const [classificationAlertModalShow, setClassificationAlertModalShow] =
    useState(false);
  const [allQrDatatype, setAllQrDataType] = useState("qrCode");
  const [qrModal, setQrmodal] = useState(false);
  const [allQrCodes, setAllQrCodes] = useState([]);
  //pagination
  let totalPage = Math.ceil(allClasses?.length / perPage);
  const firstPageIndex = (page - 1) * perPage;
  const lastPageIndex = firstPageIndex + perPage;
  let pagination = [],
    i = 1;
  while (i <= totalPage) {
    if (i <= 1 || i >= totalPage - 2 || (i >= page - 1 && i <= page + 1)) {
      pagination.push(i);
      i++;
    } else {
      pagination.push("...");
      //jump to the next page to be linked in the navigation
      i = i < page ? page - 1 : totalPage - 1;
    }
  }
  const { currentUserData } = useSelector((state) => state.AuthReducer);

  useEffect(() => {
    getStaffData();
    getClassData();
    getSchoolData();
    getStudentData();
  }, []);
  const getStaffData = async () => {
    const myStaff = await getAllStaffBySchool(schoolDetails?.uid);
    if (myStaff && myStaff.length > 0) {
      setStaffRecord(myStaff);
      setSatffLoading(true);
    } else {
      setSatffLoading(true);
    }
  };
  const getStudentData = async () => {
    // const allStudents = await getAllStudents();
    const documentSnapshots = await getAllStudentsForQrCodes(
      schoolDetails?.uid
    );
    let allStudents = [];
    documentSnapshots.forEach((document) => {
      allStudents.push({
        uid: document.id,
        ...document.data(),
      });
    });
    if (allStudents && allStudents.length > 0) {
      setAllStudent(allStudents);
      let qrCodes = [];
      allStudents?.map((val) => {
        return QRCode.toDataURL(
          `tangibles://s/${
            val?.school_code === undefined ? "" : val?.school_code
          }/${val?.individual_code}`,
          {
            width: 500,
          }
        )
          .then((url) => {
            qrCodes.push({
              name: val?.name + val?.individual_code,
              qrCode: url,
            });
          })
          .catch((err) => {
            console.error(err);
          });
      });
      setAllQrCodes(qrCodes);
    }
  };
  const getClassData = async () => {
    const myClasses = await getAllClass();
    if (myClasses && myClasses.length > 0) {
      var allClass = myClasses.filter(
        (item) => item.school_id === schoolDetails?.uid
      );
      setAllClasses(allClass);
      setSearchTemp(allClass);
      setLoading(true);
      let tmpClass = [];
      for (let i = 0; i < myClasses.length; i++) {
        let obj = {
          value: myClasses[i].uid,
          label: myClasses[i].group_code
            ? myClasses[i].class_name + `(${myClasses[i].group_code})`
            : myClasses[i].class_name,
          school_id: myClasses[i].school_id,
        };
        tmpClass.push(obj);
      }
      setAllClassesSelect(tmpClass);
    } else {
      setLoading(true);
    }
  };

  const getSchoolData = async () => {
    const record = await getAllSchool();
    if (record && record.length > 0) {
      setSchool(record);
    }
  };
  const classAdded = () => {
    setShow(!show);
    getClassData();
  };
  const deleteRecord = async () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ uid: selectRecord?.uid }),
    };
    await removeStaffRecord(selectRecord?.uid);

    await fetch(
      `${process.env.REACT_APP_PUBLIC_API_URL}/delete-user`,
      requestOptions
    );
    deleteClassOfTeacher(false, "teacher_id", selectRecord?.uid);

    getStaffData();
    setDeletemodal(!deleteModal);
  };

  const deleteRecordClass = async () => {
    deleteClassOfTeacher(false, "classId", selectRecord?.uid);
    deleteClassOfStudent(false, "classId", selectRecord?.uid);

    const response = await removeClasses(selectRecord?.uid);
    if (response) {
      var newAllClassStudents = allClasses.filter(
        (item) => item.uid !== selectRecord.uid
      );
      setDeletemodal(!deleteModal);
      getClassData();
      setAllClasses(newAllClassStudents);
      setSearchTemp(newAllClassStudents);
    }
  };
  const editClassName = async (names, index) => {
    if (names) {
      var classDoc_id = await updateClassService(selectRecord.uid, names);
      if (classDoc_id) {
        selectRecord.class_name = names;
      }
    } else {
      alert("Please enter valid record");
    }
    setEditclass(!editClass);
  };

  const handleShow = () => {
    setShow(!show);
  };
  const importModalshow = (val) => {
    setImportDataType(val);
    setImportmodal(!importModal);
  };
  const EditStaffModalShow = async (items) => {
    await setSelectedRecord(items);
    setEditStaffModal(!editstaffModal);
  };
  const EditClassModalShow = async (items) => {
    await setSelectedRecord(items);
    setEditclass(!editClass);
  };
  const deletStaffModalshow = (item) => {
    setTitlevariable("staff");
    setSelectedRecord(item);
    setDeletemodal(!deleteModal);
  };
  const deleteModalshow = (items, Class) => {
    setTitlevariable("Class");
    setSelectedRecord(items);
    setDeletemodal(!deleteModal);
  };
  const StaffModalshow = () => {
    setStaffModal(!staffModal);
  };
  const serchingHandal = async (val) => {
    setSearchMsg(true);
    setSearchMsgVal(val);
    try {
      const myClasses = searchTemp;
      if (val) {
        const searchArr = [];
        myClasses
          .filter((s) =>
            s?.class_name.toLowerCase().includes(val.toLowerCase())
          )
          .map((val) => {
            return searchArr.push(val);
          });
        myClasses
          .filter((s) =>
            s?.group_code.toLowerCase().includes(val.toLowerCase())
          )
          .map((val) => {
            return searchArr.push(val);
          });
        setAllClasses(searchArr);
      } else {
        setAllClasses(myClasses);
        setSearchMsg(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const importData = async (
    filepath,
    fileType,
    importFileSchool,
    importPoint
  ) => {
    setImportMessages(
      `"${filepath?.name}" Name file was uploaded with success.`
    );
    setImportMessagesType("success");
    if (typeof FileReader !== "undefined") {
      const reader = new FileReader();
      if (reader.readAsBinaryString) {
        reader.onload = (e) => {
          processExcel(reader.result, fileType, importPoint);
        };
        reader.readAsBinaryString(filepath);
      }
    } else {
      setImportMessagesType("failed");
      console.log("This browser does not support HTML5.");
    }
  };
  const processExcel = async (data, fileType, importPoint) => {
    const workbook = XLSX.read(data, { type: "binary" });
    const firstSheet = workbook.SheetNames[0];
    const oldExcelRows = XLSX.utils.sheet_to_json(workbook.Sheets[firstSheet], {
      raw: false,
      header: 1,
    });

    const excelRows = oldExcelRows.splice(1).filter((el) => el.length > 0);
    // class import
    if (importDataType === "class") {
      // import all student import start
      if (fileType === "Benchmark groups") {
        let excelObj = [];
        let excelObjExcludedFromImport = [];

        for (let index = 0; index < excelRows.length; index++) {
          if (
            excelRows[index][3] !== undefined &&
            excelRows[index][3] !== " " &&
            excelRows[index][4] !== undefined &&
            excelRows[index][4] !== " "
          ) {
            let lName = excelRows[index][2].split(",").slice(0, 1)[0].trim();
            let fName = excelRows[index][2].split(",").slice(-1)[0].trim();
            excelObj.push({
              email: "",
              individual_code: excelRows[index][1],
              name: fName,
              last_name: lName,
              group_code: excelRows[index][3].slice(-2), // keep only last 2 characters
              benchmarkGroup: excelRows[index][3],
              level: excelRows[index][4],
            });
          } else {
            let lName = excelRows[index][2].split(",").slice(0, 1);
            let fName = excelRows[index][2].split(", ").slice(-1);
            excelObjExcludedFromImport.push({
              fName: fName[0],
              lName: lName[0],
            });
          }
        }
        if (excelObjExcludedFromImport?.length > 0) {
          setClassificationStudentData(excelObjExcludedFromImport);
          setClassificationStudentDatatype(fileType);
          setClassificationAlertModalShow(true);
        }
        let schoolId = schoolRecord?.filter(
          (s) => s?.name === schoolDetails?.name
        );
        var classIdObj;
        for (let i = 0; i < excelObj.length; i++) {
          let classArray = [];
          // add benchmarkGroups caless
          let myClasses = await getAllClass();
          classIdObj = myClasses?.find(
            (item) =>
              item?.group_code === excelObj[i]?.group_code &&
              item?.class_name === "Groupe-repère" &&
              item?.school_id === schoolId[0]?.uid
          );
          var classDoc_id = "";
          if (!classIdObj) {
            let record = {
              class_name: "Groupe-repère",
              group_code: excelObj[i]?.group_code,
              showInDashboard: true,
              school_id: schoolId[0]?.uid,
              admin_id: currentUserData?.uid,
              benchmarkGroups: true,
            };
            classDoc_id = await addClassService(record);
          }
          // student already exist

          var allStud = await studentAlreadyExist(
            excelObj[i]?.individual_code,
            schoolId[0]?.code
          );

          if (allStud) {
            let classOb = {
              classId: classDoc_id || classIdObj?.uid, //classIdObj?.uid,
            };
            classArray.push(classOb);
            if (excelObj[i]?.name && excelObj[i]?.last_name) {
              let studentRecord = {
                //admin_id: currentUserData?.uid, je crois qu'il est undefined à ce moment-là
                email: excelObj[i]?.email,
                name: excelObj[i]?.name,
                last_name: excelObj[i]?.last_name,
                role: "student",
                individual_code: excelObj[i]?.individual_code,
                admin_id: currentUserData?.uid,
                level: excelObj[i]?.level,
                benchmarkGroup: excelObj[i]?.benchmarkGroup,
                school_name: schoolDetails?.name,
                school_id: schoolId[0]?.uid,
                school_code: schoolId[0]?.code,
                totalpoints: Number(importPoint),
                class: classArray,
              };
              let resId = await addStudentService(studentRecord);
              let allClass = studentRecord?.class;
              for (let i = 0; i < allClass?.length; i++) {
                let obj = {
                  classId: allClass[i]?.classId,
                  student_id: resId,
                  school_id: schoolId[0]?.uid,
                  showInDashboard: true,
                  admin_id: currentUserData?.uid,
                };
                let classID = await addStudentClass(obj);
              }
            }
          } else {
            let lName = excelRows[i][2].split(",").slice(0, 1);
            let fName = excelRows[i][2].split(", ").slice(-1);
            excelObjExcludedFromImport.push({
              fName: fName[0],
              lName: lName[0],
            });
            // let studentRecord = {
            //   // email: excelObj[i]?.email,
            //   name: excelObj[i]?.name,
            //   last_name: excelObj[i]?.last_name,
            //   role: "student",
            //   individual_code: excelObj[i]?.individual_code,
            //   admin_id: currentUserData?.uid,
            //   level: excelObj[i]?.level,
            //   schoolName: schoolDetails?.name,
            //   totalpoints: Number(importPoint),
            //   // class: excelObj[i]?.class,
            // };
            // await updateStudentSerive(allStud[0]?.uid, studentRecord);
          }
        }
        if (excelObjExcludedFromImport?.length > 0) {
          setClassificationStudentData(excelObjExcludedFromImport);
          setClassificationStudentDatatype("regular");
          setClassificationAlertModalShow(true);
        } else {
          Toasts(`New students added successfully`, "success");
        }
      } else {
        let excelObj = [];
        let schoolId = schoolRecord?.filter(
          (s) => s?.name === schoolDetails?.name
        );

        // pour chaque ligne du fichier d'importation, on insère un objet dans l'array excelObj
        for (let index = 0; index < excelRows.length; index++) {
          let lName = excelRows[index][2].split(",").slice(0, 1);
          let fName = excelRows[index][2].split(", ").slice(-1);

          if (
            excelRows[index][2] !== undefined &&
            excelRows[index][2] !== " " &&
            excelRows[index][3] !== undefined &&
            excelRows[index][3] !== " " &&
            excelRows[index][4] !== undefined &&
            excelRows[index][4] !== " " &&
            excelRows[index][5] !== undefined &&
            excelRows[index][5] !== " "
          ) {
            excelObj.push({
              email: "",
              individual_code: excelRows[index][1],
              name: fName[0],
              last_name: lName[0],
              group_code: excelRows[index][5],
              class: excelRows[index][4],
              subject_code: excelRows[index][3],
            });
          }
        }
        let newExalData = [];
        let myClasses = await getAllClass();

        //maltiple class add in 1 row
        var classIdObj;
        for (let i = 0; i < excelObj.length; i++) {
          // val == ligne actuelle du fichier à importer
          let val = excelObj[i];
          let classArray = [];
          // on va voir si l'élève de la ligne actuelle existe déjà dans notre array newExalData
          var studentObjIdx = newExalData.findIndex(
            (item) => item.individual_code === val?.individual_code
          );
          // classIdObj = l'id firestore du groupe de la ligne actuelle, s'il existe déjà
          classIdObj = myClasses?.find(
            (item) =>
              item?.group_code === val?.group_code &&
              item?.subject_code === val?.subject_code &&
              item?.school_id === schoolId[0]?.uid
          );
          var classDoc_id = "";
          // si le groupe de la ligne actuelle n'existe pas encore, on le crée
          if (!classIdObj) {
            let record = {
              class_name: val?.class.replace(/[\u202F\u00A0\u00a0]/, " "), // remplacer les non-breakable spaces par des espaces réguliers (pour faire fonctionner la recherche)
              group_code: val?.group_code,
              subject_code: val?.subject_code,
              showInDashboard: true, // à supprimer car devrait être évalué dans le contexte du prof qui veut l'afficher?
              // teacher_id: currentUserData?.uid,
              // teacher_uid: currentUserData?.uid,
              school_id: schoolId[0]?.uid,
              admin_id: currentUserData?.uid,
              benchmarkGroups: false,
            };
            // on entrepose dans classDoc_id l'id firestore du groupe nouvellement créé
            classDoc_id = await addClassService(record);
            // on refresh notre liste des groupes
            myClasses = await getAllClass();
          }
          // si l'élève de la ligne actuelle n'existe pas encore dans notre array newExalData, on l'ajoute à newExalData
          // si l'élève de la ligne actuelle n'existe pas encore dans notre array newExalData, on entrepose l'id du groupe (existant ou nouvellement créé) dans classArray (qui était initialement vide)
          if (studentObjIdx === -1) {
            let classOb = {
              classId: classDoc_id || classIdObj?.uid, //classIdObj?.uid,
            };
            classArray.push(classOb);
            newExalData.push({
              email: "",
              name: val?.name,
              last_name: val?.last_name,
              role: "student",
              individual_code: val?.individual_code,
              group_code: val?.group_code,
              subject_code: val?.subject_code,
              class: classArray,
            });
          } else {
            // si l'élève de la ligne actuelle existe déjà dans notre array newExalData, on ajoute l'id du groupe (existant ou nouvellement créé) dans le field "class" de l'objet de l'élève à l'intérieur de newExalData
            let classOb = {
              classId: classDoc_id || classIdObj?.uid, //classIdObj?.uid,
            };
            let newClass = newExalData[studentObjIdx].class;
            newExalData[studentObjIdx].class = [...newClass, classOb];
          }
        }

        // filter & store a data in database
        let excelObjExcludedFromImport = [];
        // pour chaque objet étudiant créé juste avant
        for (let i = 0; i < newExalData.length; i++) {
          // var allStuds = allStudent?.filter(
          //   (item) => item?.individual_code === newExalData[i]?.individual_code
          // );
          let schoolId = schoolRecord?.filter(
            (s) => s?.name === schoolDetails?.name
          );
          // on vérifie si l'étudiant existe déjà dans la base de données
          var allStud = await studentAlreadyExist(
            newExalData[i]?.individual_code,
            schoolId[0]?.code
          );

          if (allStud) {
            // si l'étudiant n'existe pas dans la db, on l'ajoute
            let studentRecord = {
              email: newExalData[i]?.email,
              name: newExalData[i]?.name,
              last_name: newExalData[i]?.last_name,
              role: "student",
              individual_code: newExalData[i]?.individual_code,
              admin_id: currentUserData?.uid,
              class: newExalData[i]?.class,
              school_name: schoolDetails?.name,
              school_id: schoolId[0]?.uid,
              school_code: schoolId[0]?.code,
              totalpoints: Number(importPoint),
            };
            let resId = await addStudentService(studentRecord);
            // pour chaque groupe auquel cet étudiant-là fait partie d'après notre import
            let allClass = newExalData[i]?.class;
            for (let i = 0; i < allClass.length; i++) {
              // on crée un objet dans la collection "students", qui représente en lien entre l'étudiant et le groupe
              let obj = {
                classId: allClass[i]?.classId,
                student_id: resId,
                school_id: schoolId[0]?.uid,
                showInDashboard: true,
                admin_id: currentUserData?.uid,
              };
              let classID = await addStudentClass(obj);
            }
          } else {
            // si l'édudiant existe déjà dans la db, on le mentionne dans notre modal d'erreur

            const studentToEdit = allStudent?.find(
              (item) =>
                item?.individual_code === newExalData[i]?.individual_code &&
                item?.school_id === schoolId[0]?.uid
            );
            const classesToAddToTheStudent = newExalData[i]?.class;
            const classesToAddToTheStudentThatAreNew =
              classesToAddToTheStudent.filter(
                (el) => !studentToEdit?.class?.includes(el)
              ); // je ne pense pas que cette ligne la fonctionne ou change quoi que ce soit
            //const oldClassesAndNewClasses = [...studentToEdit?.class, ...classesToAddToTheStudentThatAreNew];
            const oldClassesAndNewClasses = [
              ...studentToEdit?.class,
              ...classesToAddToTheStudent,
            ].filter(
              (v, i, a) => a.findIndex((v2) => v2.classId === v.classId) === i
            );

            const updatedStudent = await updateStudentSerive(
              studentToEdit?.uid,
              {
                class: oldClassesAndNewClasses,
              }
            );

            // make a for loop for classesToAddToTheStudentThatAreNew

            for (
              let i = 0;
              i < classesToAddToTheStudentThatAreNew.length;
              i++
            ) {
              const objForRelationBetweenClassAndStudent = {
                classId: classesToAddToTheStudentThatAreNew[i].classId,
                student_id: studentToEdit?.uid,
                school_id: schoolId[0]?.uid,
                showInDashboard: true,
                admin_id: currentUserData?.uid,
              };
              let relationId = await addStudentClass(
                objForRelationBetweenClassAndStudent
              );
            }

            //excelObjExcludedFromImport.push({
            //  fName: newExalData[i]?.name,
            //  lName: newExalData[i]?.last_name,
            //});
          }
        }
        if (excelObjExcludedFromImport?.length > 0) {
          setClassificationStudentData(excelObjExcludedFromImport);
          setClassificationStudentDatatype(fileType);
          setClassificationAlertModalShow(true);
        } else {
          Toasts("New students added successfully", "success");
        }
      }
      // import all student import end
      //create object on import data
      // let excelObj = [];
      // for (let index = 0; index < excelRows.length; index++) {
      //   excelObj.push({
      //     subject_code: excelRows[index][3],
      //     group_code: excelRows[index][5],
      //     class: excelRows[index][4],
      //   });
      // }
      // // filter import data class & group code
      // let filterExcelRow = excelObj.filter(
      //   (ele, ind) =>
      //     ind ===
      //     excelObj.findIndex(
      //       (elem) =>
      //         elem.group_code === ele.group_code && elem.class === ele.class
      //     )
      // );
      // // store a data in database
      // filterExcelRow?.map(async (val) => {
      //   let allClasses = {
      //     class_name: val?.class,
      //     group_code: val?.group_code,
      //     subject_code: val?.subject_code,
      //     showInDashboard: true,
      //     school_id: schoolDetails?.uid,
      //     admin_id: currentUserData?.uid,
      //   };
      //   var classDoc_id = await ClassesObjectServices(
      //     allClasses?.class_name,
      //     allClasses?.group_code
      //   );
      //   if (classDoc_id) {
      //     await addClassService(allClasses);
      //     getClassData();
      //   }
      // });
    }
    // staff import
    if (importDataType === "staff") {
      //create object on import data
      let excelObj = [];
      for (let index = 0; index < excelRows.length; index++) {
        excelObj.push({
          name: excelRows[index][1],
          last_name: excelRows[index][0],
          email: excelRows[index][2],
        });
      }
      // signup & store a data in database

      for (let i = 0; i < excelObj.length; i++) {
        // const count = excelObj.length > 7 ? 7 : excelObj.length;
        // for (let i = 0; i < count; i++) {
        let val = excelObj[i];

        const generatedPassword = new Array(16)
          .fill(
            "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz!$%&*()!$%&*()"
          )
          .map((x) =>
            (function (chars) {
              let umax = Math.pow(2, 32),
                r = new Uint32Array(1),
                max = umax - (umax % chars.length);
              do {
                crypto.getRandomValues(r);
              } while (r[0] > max);
              return chars[r[0] % chars.length];
            })(x)
          )
          .join("");

        let resUser = await signupApi(val?.email, generatedPassword);

        if (resUser && resUser?.response?.user?.uid) {
          let params = {
            name: val?.name,
            last_name: val?.last_name,
            email: val?.email,
            // class: classRecord,
            admin_id: currentUserData?.uid,
            school_id: schoolDetails?.uid,
            role: "teacher",
            uid: resUser?.response?.user?.uid,
            //status: schoolDetails?.name,
            school_name: schoolDetails?.name,
            profile: "",
          };

          await addStaffService(params);
        } else {
          console.log(resUser?.err);
        }
      }
      Toasts("New staff added successfully", "success");
    }
    getClassData();
    getStaffData();
    getStudentData();
    importModalshow();
    setImportMessages();
    setImportMessagesType();
  };
  const nextNumberHandal = (number) => {
    setPage(number);
  };
  const nextHandal = () => {
    setPage(page + 1);
  };
  const prevHandal = () => {
    setPage(page - 1);
  };
  const handleStudentshow = () => {
    setStudentshow(!showStudent);
  };
  const addRecord = () => {
    setStudentshow(!showStudent);
    getStudentData();
  };
  const classificationAlertModalHandal = () => {
    setClassificationAlertModalShow(!classificationAlertModalShow);
  };
  const allQrModalshow = (value) => {
    setAllQrDataType("Exporting all QR Codes");
    setQrmodal(!qrModal);
  };
  return (
    <>
      <section className="min-h-lg-screen vstack student">
        <Header />
        <Subheader
          serchingHandal={(e) => serchingHandal(e)}
          name={schoolDetails?.name}
          screenName={"schoolName"}
        />
        <Container>
          <Row className="mt-10 gx-xl-14 ">
            <Col xl={8}>
              <Card className="shadow-none border-paleblue border mb-8">
                <Card.Body className="text-tangaroa">
                  <Row className="gy-8">
                    <Col lg={4} md={6}>
                      <p className="font-bold mb-2">{schoolDetails?.name}</p>
                      <p>{schoolDetails?.email}</p>
                    </Col>
                    <Col lg={3} md={6}>
                      <p className="font-mb-2">{schoolDetails?.phone_number}</p>
                      <p>{schoolDetails?.code}</p>
                    </Col>
                    <Col lg={5} md={6}>
                      <div className="d-flex">
                        <p className="mb-2">{schoolDetails?.address},</p>
                        <p className="ms-2">{schoolDetails?.city}</p>
                      </div>
                      <div className="d-flex">
                        Postal Code:{" "}
                        {schoolDetails?.postalCode ||
                          schoolDetails?.postal_code}
                        ,
                        <div className="ms-2">
                          <p>
                            {schoolDetails?.povience || schoolDetails?.province}
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
              <Row>
                <Col md={3} className="mb-7 mt-3">
                  <p className="font-bold d-flex">
                    {!loading ? (
                      <Skeleton width={100} />
                    ) : (
                      `Classes(${allClasses?.length})`
                    )}
                  </p>
                </Col>
                <Col md={9} className="text-md-end mb-7">
                  <Button
                    variant="jade"
                    className="me-4"
                    onClick={() => importModalshow("class")}
                  >
                    Import .XLSX
                  </Button>
                  <Button
                    variant="disabled" // Disabled until the "Starting balance" field is added
                    className="me-4"
                    //onClick={handleStudentshow}>
                    onClick={() => {
                      return false;
                    }}
                  >
                    Add student
                  </Button>
                  <Button variant="jade" className="me-4" onClick={handleShow}>
                    + Class
                  </Button>
                  <Button
                    variant={allStudent?.length > 0 ? "jade" : "disabled"}
                    onClick={() =>
                      allStudent?.length > 0 ? allQrModalshow(allStudent) : ""
                    }
                  >
                    Export all QR Codes
                  </Button>
                </Col>
                {loading ? (
                  <>
                    {allClasses.length > 0 ? (
                      <>
                        <Col sm={12} className="mb-8">
                          <Table responsive className="schooltable table-h">
                            <thead className="text-tangaroa users">
                              <tr>
                                <th className="text-base font-800">#</th>
                                <th className="text-base font-800 text-capitalize">
                                  Code
                                </th>
                                <th className="text-base font-800 text-capitalize">
                                  Name
                                </th>
                              </tr>
                            </thead>

                            <tbody className="text-tangaroa">
                              {allClasses
                                .slice(firstPageIndex, lastPageIndex)
                                ?.map((user, index) => {
                                  return (
                                    <tr>
                                      <td className="text-base font-800">
                                        {(page - 1) * perPage + index + 1}
                                      </td>
                                      <td className="font-regular text-base">
                                        {user.group_code}
                                      </td>
                                      <td className="font-regular text-base">
                                        {user.class_name}
                                      </td>
                                      <td className="text-end pe-0">
                                        <a
                                          href={() => false}
                                          className="btn btn-transparent px-1"
                                          onClick={() =>
                                            EditClassModalShow(user)
                                          }
                                        >
                                          <img
                                            src={Edit}
                                            className="text-sm"
                                            size={20}
                                            alt="Edit"
                                          />
                                        </a>
                                        <a
                                          href={() => false}
                                          className="btn btn-transparent px-1"
                                          onClick={() =>
                                            deleteModalshow(user, "this class")
                                          }
                                        >
                                          <img
                                            src={Delete}
                                            className="text-sm text-danger"
                                            size={20}
                                            alt="Delete"
                                          />
                                        </a>
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </Table>
                        </Col>
                        <Col className="d-flex justify-content-end mb-10 table-pagination">
                          <Pagination>
                            {page === 1 ? (
                              <Pagination.Item
                                className="me-1 rounded-4"
                                disabled
                              >
                                Prev
                              </Pagination.Item>
                            ) : (
                              <Pagination.Item
                                className="me-1 rounded-4"
                                onClick={() => prevHandal()}
                              >
                                Prev
                              </Pagination.Item>
                            )}
                            {
                              // [...Array(totalPage + 1).keys()]
                              //   .slice(1)
                              pagination?.map((val) => {
                                return (
                                  <Pagination.Item
                                    key={val}
                                    active={val === page}
                                    onClick={() =>
                                      val === "..." ? "" : nextNumberHandal(val)
                                    }
                                  >
                                    {val}
                                  </Pagination.Item>
                                );
                              })
                            }
                            {totalPage === page ? (
                              <Pagination.Item disabled>Next</Pagination.Item>
                            ) : (
                              <Pagination.Item onClick={() => nextHandal()}>
                                Next
                              </Pagination.Item>
                            )}
                          </Pagination>
                        </Col>
                      </>
                    ) : (
                      <>
                        {searchMsg ? (
                          <p className="d-flex justify-content-center">
                            Hmm... We couldn't find any matches for "
                            {searchMsgVal}"
                          </p>
                        ) : (
                          <p className="d-flex justify-content-center">
                            Nothing to show here yet
                          </p>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  // <h3 className="d-flex justify-content-center">Loading...</h3>
                  <TableLoadingPage />
                )}
              </Row>
            </Col>
            <Col xl={4}>
              {/* ------------Staff Modal Start --------*/}
              <Row>
                <Col md={2} className=" mt-3 mb-2">
                  <p className="font-bold ">Staff</p>
                </Col>
                <Col md={10} className="text-md-end mb-2">
                  <Button
                    variant="jade"
                    className="me-4"
                    onClick={() => importModalshow("staff")}
                  >
                    Import .XLSX
                  </Button>
                  <Button
                    variant="jade"
                    className=""
                    onClick={() => StaffModalshow()}
                  >
                    + Staff
                  </Button>
                </Col>
                {/* <ButtonSlider /> Temp time comment this line .*/}

                {/* {staffData.map((item) => {
                  let letter = item.name.charAt(0);
                  return (
                    <Card className="shadow-none border-paleblue border mb-4 px-0">
                      <Card.Body className="text-tangaroa hstack py-5">
                        <div className="round mb-auto me-3">
                          <LetteredAvatar
                            className="lettered"
                            backgroundColor="#00C376"
                            radius={30}
                            size={30}
                            name={letter}
                          />
                        </div>
                        <div className="flex-1 me-3">
                          <p className="font-bold mb-1">{item.name}</p>
                          <p>{item.designation}</p>
                        </div>
                        <div className="mt-auto">
                          <a className="btn btn-transparent px-1 py-0">
                            <img
                              src={show1}
                              className="text-md text-dark"
                              size={20}
                              alt="Delete" */}

                {staffLoading ? (
                  <>
                    {staffRecord?.length > 0 ? (
                      <>
                        {staffRecord
                          // .sort((a, b) => a.name.localeCompare(b.name))
                          // .slice(0, 7)
                          .map((item) => {
                            return (
                              <Card className="shadow-none border-paleblue border mb-4 px-0">
                                <Card.Body className="text-tangaroa hstack py-5">
                                  <div className="round mb-auto me-3">
                                    {/* <p className="text-dark text-sm font-bold">1</p> */}
                                    <LetteredAvatar
                                      backgroundColor="#00C376"
                                      radius={30}
                                      size={30}
                                      name={item?.name.charAt(0)}
                                    />
                                  </div>
                                  <div className="flex-1 me-3">
                                    <p className="font-bold mb-1">
                                      {item?.name}&nbsp;
                                      {item?.last_name}
                                    </p>
                                    <p>{item?.role}</p>
                                  </div>
                                  <div className="mt-auto">
                                    <a
                                      className="btn btn-transparent px-1 py-0"
                                      href={() => false}
                                    >
                                      <img
                                        src={show1}
                                        className="text-md text-dark"
                                        size={20}
                                        alt="Delete"
                                      />
                                    </a>
                                    <a
                                      href={() => false}
                                      className="btn btn-transparent px-1 py-0"
                                      onClick={() => EditStaffModalShow(item)}
                                    >
                                      <img
                                        src={Edit}
                                        className="text-sm text-dark"
                                        size={20}
                                        alt="Edit"
                                      />
                                    </a>
                                    <a
                                      href={() => false}
                                      className="btn btn-transparent px-1 py-0"
                                      onClick={() => deletStaffModalshow(item)}
                                    >
                                      <img
                                        src={Delete}
                                        className="text-sm text-danger"
                                        size={20}
                                        alt="Delete"
                                      />
                                    </a>
                                  </div>
                                </Card.Body>
                              </Card>
                            );
                          })}
                      </>
                    ) : (
                      <p className="d-flex justify-content-center mt-7">
                        No members added to its staff yet
                      </p>
                    )}
                  </>
                ) : (
                  <StaffLoading />
                  // <h3 className="d-flex justify-content-center mt-7">
                  //   Loading...
                  // </h3>
                )}
              </Row>
            </Col>
          </Row>
        </Container>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
        <Footer />
      </section>

      {show && (
        <Addclass
          show={show}
          hide={() => handleShow()}
          schoolDetails={schoolDetails}
          submit={() => {
            classAdded();
          }}
        />
      )}
      {importModal && (
        <ImportModal
          show={importModal}
          hide={() => importModalshow()}
          submit={(file, fileType, importFileSchool, importPoint) =>
            importData(file, fileType, importFileSchool, importPoint)
          }
          type={importDataType}
          importMessges={importMessges}
          importMessgesType={importMessgesType}
        />
      )}
      {editClass && (
        <Editclass
          show={() => editClass({})}
          hide={() => EditClassModalShow({})}
          submit={(names) => editClassName(names)}
          selectRecord={selectRecord}
        />
      )}
      {deleteModal && (
        <DeleteModal
          show={deleteModal}
          hide={() => deleteModalshow()}
          screenName={Titlevariable}
          submit={() => {
            if (Titlevariable === "Class") {
              deleteRecordClass();
            } else {
              deleteRecord();
            }
          }}
          selectRecord={selectRecord}
        />
      )}
      {staffModal && (
        <StaffModal
          show={staffModal}
          hide={() => StaffModalshow()}
          classdata={allClasses}
          schoolDetails={schoolDetails}
          getStaffData={() => getStaffData()}
        />
      )}
      {editstaffModal && (
        <EditStaffModal
          show={editstaffModal}
          hide={() => EditStaffModalShow()}
          classdata={allClasses}
          selectRecord={selectRecord}
          getStaffData={() => getStaffData()}
          schoolDetails={schoolDetails}
        />
      )}
      {showStudent && (
        <AddStudent
          show={showStudent}
          hide={() => handleStudentshow()}
          addRecord={() => {
            addRecord();
          }}
          allStudent={allStudent}
          classdata={allClassesSelect}
          schoolRecord={schoolRecord}
          schoolDetails={schoolDetails}
          screenType={"schoolName"}
        />
      )}
      {classificationAlertModalShow && (
        <ClassificationAlertModal
          show={classificationAlertModalShow}
          hide={() => classificationAlertModalHandal()}
          studentData={classificationStudentData}
          type={classificationStudentDatatype}
        />
      )}
      {qrModal && (
        <QrModal
          show={qrModal}
          hide={() => allQrModalshow()}
          data={""}
          allQrData={allQrCodes}
          allQrDatatype={allQrDatatype}
        />
      )}
    </>
  );
}

export default School;
