import { React, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { ClassesObjectServices } from "../firebase/service";

function Editclass({ hide, show, submit, selectRecord }) {
  const [name, setName] = useState(selectRecord?.class_name);
  const [code] = useState(selectRecord?.group_code);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [errors, setErrors] = useState("");

  const handalSubmit = async () => {
    let allClass = await ClassesObjectServices(name, code);
    if (name && allClass) {
      submit(name);
      setLoadingBtn(true);
    } else {
      setErrors("Please enter a valid name for the class");
    }
  };
  return (
    <Modal
      size="lg"
      setModalShow="true"
      show={show}
      onHide={hide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modalschool"
    >
      <Modal.Header closeButton className="border-none pb-0">
        <Modal.Title id="contained-modal-title-vcenter" className="text-xl">
          Edit “Class”
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="py-7">
        <Form.Label className="text-tangaroa font-bold text-base">
          Code
        </Form.Label>
        <Form.Control
          type="text"
          placeholder="- Type here -"
          className="control mb-3"
          value={code}
          disabled
        />
        <Form.Group controlId="name">
          <Form.Label className="text-tangaroa font-bold text-base">
            Name
          </Form.Label>
          <Form.Control
            type="text"
            placeholder="- Type here -"
            className="control mb-3"
            value={name}
            isInvalid={!!errors}
            onChange={(e) => {
              setName(e.target.value);
              setErrors("");
            }}
          />
          <Form.Control.Feedback type="invalid">{errors}</Form.Control.Feedback>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer className="border-none pt-0 justify-content-start pb-8">
        <Button
          variant={loadingBtn ? "disabled" : "jade"}
          className=" me-2 my-0 "
          onClick={() => handalSubmit()}
        >
          Save
        </Button>
        <Button variant="outline-jade" className=" my-0 ms-0" onClick={hide}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default Editclass;
