import { React, useState, useEffect } from "react";
import { Button, Form, Modal, Container, Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import { signupApi } from "../firebase";
import { addClassOfTeacher, addStaffService } from "../firebase/service";
import { addStff } from "../utils";
import { OptionsOutsideSelect, Toasts } from "../components";
import { ToastContainer } from "react-toastify";
import { emailVlidation } from "../utils/validation";

function StaffModal({ hide, show, classdata, schoolDetails, getStaffData }) {
  const { currentUserData } = useSelector((state) => state.AuthReducer);
  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});
  const [classes, setClass] = useState([]);
  const [allClasses, setAllClasses] = useState([]);
  const [loadingBtn, setLoadingBtn] = useState(false);

  useEffect(() => {
    getMyData();
  }, []);
  const getMyData = async () => {
    if (classdata && classdata.length > 0) {
      let tmpClass = [];
      for (let i = 0; i < classdata.length; i++) {
        let obj = {
          value: classdata[i].uid,
          label: classdata[i].group_code
            ? classdata[i].class_name + `(${classdata[i].group_code})`
            : classdata[i].class_name,
          classdata: classdata[i],
        };
        tmpClass.push(obj);
      }
      setAllClasses(tmpClass);
    }
  };

  const handleSubmit = async (e) => {
    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      setLoadingBtn(false);
    } else {
      setLoadingBtn(true);
      try {
        let resUser = await signupApi(form.email, form.password);

        let classRecord = [];
        if (resUser && resUser?.response?.user?.uid) {
          for (let i = 0; i < classes.length; i++) {
            let obj = {
              classId: classes[i].value,
              // class_name: classes[i].label,
            };
            classRecord.push(obj);
          }
          let params = {
            name: form.fname,
            last_name: form.lname,
            email: form.email,
            class: classRecord,
            admin_id: currentUserData?.uid,
            school_id: schoolDetails?.uid,
            role: "teacher",
            uid: resUser?.response?.user?.uid,
            school_name: schoolDetails?.name,
            profile: "",
          };
          if (params) {
            await addStaffService(params);
            for (let i = 0; i < classes.length; i++) {
              let teacherParam = {
                school_id: schoolDetails?.uid,
                teacher_id: resUser?.response?.user?.uid,
                showInDashboard: true,
                classId: classes[i].value,
              };
              let teacherData = await classes[i]?.classdata?.teacher?.filter(
                (item) => item.teacher_id === teacherParam.teacher_id
              );
              if (teacherData && teacherData.length > 0) {
              } else {
                await addClassOfTeacher(teacherParam);
              }
            }
            Toasts("New member added successfully", "success");
            setTimeout(() => {
              getStaffData();
              hide();
            }, 4000);
          }
        } else {
          if (!resUser.success) {
            e.preventDefault();
            Toasts("We couldn't add this member. Please, try again.", "error");
            setLoadingBtn(false);
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const setFeild = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });
    if (!!errors[field])
      setErrors({
        ...errors,
        [field]: null,
      });
  };

  const validateForm = () => {
    const { fname, lname, email, password, confirmpass } = form;
    const newErrors = {};
    if (!fname || fname === "") newErrors.fname = "Please enter the first name";
    if (!lname || lname === "") newErrors.lname = "Please enter the last name";
    if (!email) {
      newErrors.email = "Please enter the email";
    } else if (!emailVlidation(email)) {
      newErrors.email = "Please enter a valid e-mail address";
    }
    if (!password || password === "")
      newErrors.password = "Please Enter Your Password";
    if (!confirmpass || confirmpass === "") {
      newErrors.confirmpass = "Please confirm your password";
    } else if (password !== confirmpass) {
      newErrors.confirmpass = "Uh-oh, passwords did not match";
    }
    return newErrors;
  };

  const handleSelectChange = (values) => {
    setClass(values);
  };

  return (
    <Modal
      size="lg"
      setModalShow="true"
      show={show}
      onHide={hide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modalschool modalstudent"
    >
      <Modal.Header closeButton className="border-none pb-0">
        <Modal.Title id="contained-modal-title-vcenter" className="text-xl">
          Add “Staff”
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="py-7 px-0">
        <Container>
          <Row>
            {addStff.map((val, index) => {
              return (
                <>
                  <Col lg={val?.col} key={index}>
                    {val?.label === "Class" ? (
                      <Form.Group controlId="classes" className="mt-7">
                        <Form.Label className="text-tangaroa font-bold text-base ">
                          Class
                        </Form.Label>
                        <OptionsOutsideSelect
                          onChange={handleSelectChange}
                          isMulti
                          options={allClasses}
                          value={classes}
                        />
                      </Form.Group>
                    ) : (
                      <Form.Group
                        controlId={val?.name}
                        className="mt-5"
                        key={index}
                      >
                        <Form.Label className="text-tangaroa font-bold text-base">
                          {val?.label}
                        </Form.Label>
                        <Form.Control
                          type={val?.type}
                          placeholder="- Type here -"
                          className="control"
                          value={form.val?.name}
                          onChange={(e) => setFeild(val?.name, e.target.value)}
                          isInvalid={!!errors[val?.name]}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors[val?.name]}
                        </Form.Control.Feedback>
                      </Form.Group>
                    )}
                  </Col>
                </>
              );
            })}
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer className="border-none pt-0 justify-content-start pb-8">
        <Button
          variant={loadingBtn ? "disabled" : "jade"}
          onClick={handleSubmit}
          className=" me-2 my-0 "
          disabled={loadingBtn ? true : false}
        >
          Save
        </Button>
        <Button variant="outline-jade" className=" my-0 ms-0" onClick={hide}>
          Cancel
        </Button>
      </Modal.Footer>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </Modal>
  );
}

export default StaffModal;
