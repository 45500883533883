import React from "react";
import { Card } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "./style.scss";

const staffLoading = () => {
  const n = 5;
  return (
    <>
      {[...Array(n)].map((val, i) => {
        return (
          <Card className="shadow-none border-paleblue border mb-4 px-0 post" key={i}>
            <Card.Body className="text-tangaroa hstack py-5 left-col">
              <div className="round mb-auto me-3 avatar">
                <Skeleton
                  circle
                  height="100%"
                  containerClassName="avatar-skeleton"
                />
              </div>
              <div className="flex-1 me-3">
                <p className="font-bold mb-1">
                  <Skeleton width={100} />
                </p>
                <p>
                  <Skeleton width={100} />
                </p>
              </div>
              <div className="mt-auto">
                <a className="btn btn-transparent px-1 py-0" href={() => false}>
                  <Skeleton width={100} />
                </a>
              </div>
            </Card.Body>
          </Card>
        );
      })}
    </>
  );
};

export default staffLoading;
